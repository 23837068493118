/*
 * @Descripttion: 过滤
 * @version:
 * @Author: bbq
 * @Date: 2020-08-03 10:17:12
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-14 14:49:53
 */

import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { isFunction, filterColumns, uuidv4 } from '../../utils/index';

import { getSysFieldid } from '@platform/api';

import FilterPanel from './panel';

require('./index.less');

/**
 * @description: 创建列上面的属性
 * @param {type} 
 * @return {type} 
 */
const createFilterTitle = (columns, nonFilterable = [], onTitleClick) => {
    // 过滤不可见的列
    // columns = filterColumns(columns, 'visible', false);
    //  返回渲染列
    return columns.map(item => {

        if (item.children) {
            return {
                ...item,
                children: createFilterTitle(item.children, nonFilterable, onTitleClick),
            };
        }
        let {
            className,
            filterStatus,
        } = item;
        // label 是为了兼容旧版
        // headerClick 是为了兼容旧版 TODO  props 属性的传递

        return {
            ...item,
            className: classNames(className, 'filterable-column'),
            titleIcon: () => {
                return <Fragment>
                    {/* 是否筛选状态 */}
                    {!nonFilterable.includes(item.attrcode) && filterStatus !== 'filtered' && <i
                        className="iconfont th-filter icon-lieguolv"
                        onClick={e => {
                            onTitleClick && onTitleClick(item);
                        }}
                    />}
                    {/* 筛选出结果的状态 */}
                    {!nonFilterable.includes(item.attrcode) && filterStatus === 'filtered' && (
                        <i
                            className="iconfont th-filter icon-lieshaixuanx filtered"
                            onClick={e => {
                                /**
                                 * 点击筛选图标, 只有当前被筛选时,直接重置,
                                 * 还有其他列同时被筛选时, 去除当前列筛选条件重新筛选
                                 */
                                e.stopPropagation();
                                onTitleClick && onTitleClick(item, true);
                                // this.FilterPanelCallBack.current.handleHeaderOpen({ selectedAttr: attrcode });
                            }}
                        />
                    )}
                </Fragment>
            },
        };
    });
}

/**
 * 创建过滤行
 * @param {*} Table 
 */
export default function filterRow(Table) {
    if (!Table) {
        console.error('缺少Table组件');
    }
    // TODO
    return class MetaTable extends Component {
        constructor(props) {
            super(props);
            this.onTitleClick = this.onTitleClick.bind(this);

            this.state = {
                open: false,
                filterColumns: [],
            };

            this.uuid = uuidv4();
        }

        /**
         * @description: 获取panel中要使用的  模板数据
         * @param {type} 
         * @return {type} 
         */
        getFilterPanelItems() {
            let {
                columns,
                // 不允许过滤的列
                nonFilterable = ['numberindex', 'opr', 'checkbox'],
            } = this.props;

            return filterColumns(columns, column => {
                const { attrcode, visible } = column;
                return !nonFilterable.includes(attrcode) && visible;
            });
        }

        /**
         * @description: 头部点击事件
         * @param {type} 
         * @return {type} 
         */
        onTitleClick(column, icon) {
            let {
                arrColumns,
                // 不允许过滤的列
                nonFilterable = ['numberindex', 'opr', 'checkbox'],
            } = this.props;
            // console.log(column);
            let { attrcode, filterStatus } = column;
            !nonFilterable.includes(attrcode) && this.setState({
                open: icon ? false : true,
                selectedAttr: attrcode,
                ...(icon ? {} : { filterStatus }),
            });
            // { selectedAttr: attrcode, filterStatus }
        }

        render() {

            let {
                columns,
                high_filter = {},
                ...others
            } = this.props;

            let {
                langJson = {},
                alloweFilter = true,
                nonFilterable = ['numberindex', 'opr', 'checkbox'],
                handleResetCallBack,
                handleFilterCallBack,
                onHide,
                // 是否重置
                isReset = false,
                panelTitle,
                filterData = [],
                customColData,
                rowKey,
            } = high_filter;

            // console.log(alloweFilter);

            let { open, selectedAttr, filterStatus } = this.state;

            if (alloweFilter) {
                // 处理筛选时的表头
                columns = createFilterTitle(columns, nonFilterable, this.onTitleClick);
            }
            // console.log('this column is', this.getFilterPanelItems());
            // console.log('langJson', langJson);
            // TODO 快捷键功能 CTRL + F
            return <Fragment>
                {alloweFilter &&
                    <FilterPanel
                        tableColumns={this.getFilterPanelItems()}
                        filterData={filterData}
                        customColData={customColData}
                        rowKey={rowKey}
                        open={open}
                        isReset={isReset}
                        selectedAttr={selectedAttr}
                        filterStatus={filterStatus}
                        onHide={params => {
                            let { arrColumns } = params;
                            let columnStatus = {};
                            // 设置表格筛选图标, 在正常关闭时, 只将filtering状态的图标清空
                            arrColumns.forEach(column => {
                                const { attrcode, filterStatus } = column;
                                if (filterStatus === 'filtering') {
                                    column.filterStatus = '';
                                    columnStatus[attrcode] = { filterStatus: '' };
                                }
                            });
                            onHide && onHide({ ...params, ...{ columnStatus } });

                            // 清掉当前的选择项
                            this.setState({ open: false, selectedAttr: '', filterStatus: '' });
                        }}
                        tableScope={this}
                        tableName={panelTitle}
                        // 筛选后的回调
                        handleFilterCallBack={params => {
                            let {
                                filterItemList,
                                afterFilterItemList,
                                arrColumns,
                            } = params;

                            // 记录要变话状态的列
                            let columnStatus = {};
                            const filteringList = Object.values(filterItemList).map(
                                filterItem => filterItem.selectedAttr,
                            );
                            const filteredList = Object.values(afterFilterItemList).map(
                                afterFilterItem => afterFilterItem.selectedAttr,
                            );
                            // 改引用不太稳定
                            arrColumns.forEach(column => {
                                const { attrcode } = column;
                                if (filteredList.includes(attrcode)) {
                                    columnStatus[attrcode] = { filterStatus: 'filtered' };
                                } else if (!filteringList.includes(attrcode)) {
                                    columnStatus[attrcode] = { filterStatus: '' };
                                }
                            });
                            // console.log(arrColumns, columnStatus);
                            handleFilterCallBack && handleFilterCallBack({ ...params, ...{ columnStatus } });
                        }}
                        // 筛选重置后的回调
                        handleResetCallBack={params => {
                            let { arrColumns } = params;
                            let columnStatus = {};
                            // 设置表格筛选图标, 重置时将筛选图标全部清空
                            arrColumns.forEach(column => {
                                columnStatus[column.attrcode] = { filterStatus: '' };
                            });
                            // console.log(columnStatus);
                            handleResetCallBack && handleResetCallBack({ ...params, ...{ columnStatus } });
                        }}
                        langJson={langJson}
                    />
                }
                <Table
                    columns={columns}
                    {...others}
                />

            </Fragment>
        }
    }
}
