import React, { Component } from "react";
import { Icon } from '@platform/base';
import sort from "bee-table/build/lib/sort";
import { handleMeta } from './sort';
import './index.less';
export default function columnSort(Table) {
    let ComplexTable = sort(Table, Icon);
    return class extends Component {
        constructor(props) {
            super(props);
        }
        render() {
            let { columns, backSort, sortChange, isSort = true } = this.props;
            if (!isSort) {
                return (<Table {...this.props} />)
            }
            // 后端排序和前端排序, 不传config.sort就走前端排序的sort逻辑
            const sort = backSort || {
                mode: "single",
                backSource: false,
                sortFun: (sortParam, sortData) => {
                    // console.log(sortParam); // 对象{[field: "contact",order: "ascend",orderNum: 1]}
                    // console.log(sortData); // 对象{[每一行参与排序的数据]}  没有values的
                    if (Array.isArray(sortParam) && sortParam[0] && sortParam[0].order === 'flatscend') {
                        sortParam[0].order = 'ascend';
                        let item = columnsFind(columns, (e => e.attrcode === sortParam[0].field))
                        let sorterFunction = item.sorter;
                        sortData = sortData.sort((a, b) => {
                            return sorterFunction(a, b);
                        })
                    }
                    sortChange(sortParam, sortData);
                }
            };
            columns.forEach(item => { handleMeta(item) });
            return (<ComplexTable {...this.props} sort={sort} />)
        }

    }

}


function columnsFind(columns, func) {
    for (let data of columns) {
        if (func(data)) return data
        if (data.children) {
            const res = columnsFind(data.children, func)
            if (res) return res
        }
    }
    return null
}