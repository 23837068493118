/*
 * @Descripttion: 
 * @version: 
 * @Author: pub
 * @Date: 2020-06-22 09:46:52
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-27 14:46:28
 */
import warning from 'warning';
import { moment, getSafeRandom } from '@platform/api';
//import {DongbaToLocalTime} from './timer.js';
import { formatNumber, formatDatetime, DongbaToLocalTime, isDongba, LocalToDongbaTime, getLangCode, toast, setGlobalStorage, getGlobalStorage, pageTo } from '@platform/api';
import CONFIG from '../config'

const warned = {};

/**
 * 错误警告，只警告一次
 * @param {*} condition 
 * @param {*} format 
 * @param {*} arg 
 */
export function warningOnce(condition, format, arg) {
    if (!warned[format]) {
        warning(condition, format, arg);
        warned[format] = true;
    }
}

const getVarType = function (v) {
    return Object.prototype.toString.call(v).slice(8, -1);
};

/**
 * 判断是否为对象
 * @param {*object} obj 
 */
export function isObject(obj) {
    return getVarType(obj) === 'Object';
}

/* 检测类型是否为数组 */
export function isArray(param) {
    return getVarType(param) === 'Array' || Array.isArray(param);
}

/* 检测类型是否为字符串 */
export function isString(param) {
    return getVarType(param) === 'String';
}

/* 检测类型是否为方法 */
export function isFunction(param) {
    return getVarType(param) === 'Function';
}

/* 检测类型是否为Promise对象 */
export function isPromise(param) {
    return getVarType(param) === 'Promise';
}

/* 检测类型是否为数字 */
export function isNumber(param) {
    return getVarType(param) === 'Number' && !Number.isNaN(param);
}

/*
 * @method   检测是否是需要是boolean类型
 */
export function isBoolean(param) {
    return getVarType(param) === 'Boolean';
}

/*
 * @method   是否是undefined
 */
export function isUndefined(origin) {
    return typeof origin === 'undefined' || origin === void 0;
}

/*
 * @method   if条件下为false   除去NaN、0、-0、false   剩余undefined、null、""
 */
export function isWrong(param) {
    return typeof param === 'undefined' || param === null || param === '';
}

export function isNullOrVoid(param) {
    return typeof param === 'undefined' || param === null;
}

/*
 * @method   if条件下为false   除去NaN、0、-0、false   剩余undefined、null、""
 */
export function isWrongFalse(param) {
    return typeof param === 'undefined' || param === null || param === '' || param === false;
}

// 判断各类型blur的时候value是否是空值
export function isEmptyValue(value, itemtype) {
    if (itemtype === 'residtxt') {
        return !Object.keys(value).some(attr => {
            return !isWrong(value[attr].value);
        });
    } else if (itemtype === 'refer') {
        if (Array.isArray(value)) {
            if (value.length > 1) {
                return false;
            } else if (value.length === 0) {
                return true;
            } else {
                return isWrong(value[0].refpk);
            }
        } else {
            return isWrong(value.refpk);
        }
    } else {
        return isWrong(value);
    }
}

/*
 * @method   防抖方法
 * @author   add by xuyangt @19/03/08
 * @params
 *     callback     {fun}      回调函数
 *     interval     {number}   触发间隔时间
 * @return   {fun}
 * @demo
 */

export function debounds(callback, delay = 0) {
    let timeout = null;
    return function () {
        let that = this;
        let param = arguments;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function () {
            typeof callback === 'function' && callback.apply(that, param);
        }, delay);
    }
}
/*
 * @method   节流方法
 * @author   add by xuyangt @19/03/08
 * @params
 *     callback     {fun}      回调函数
 *     delay     {number}   延时执行时间
 * @return   {fun}
 * @demo
 */
export function throttle(callback, interval = 0) {
    let last = 0;
    return function () {
        let param = arguments;
        let that = this;
        let now = +new Date();
        if (now - last >= interval) {
            last = now;
            typeof callback === 'function' && callback.apply(that, param);
        }
    }
}

/*==============================快捷键相关===============================*/
/**
 * issue: https://stackoverflow.com/questions/2600186/focus-doesnt-work-in-ie, For IE you need to use a setTimeout function due to it being lazy
 */
export function setElementFocusInIE(dom) {
    let timer = setTimeout(() => {
        dom && dom.focus();
        clearTimeout(timer);
    }, 100);
}

/**
 * @desc 判断是否按下了方向键
 * @param e {obj} 事件源
 * @param wantedKeys {array} 想要监听的按钮
 */
export function isArrowKeyDown(e, wantedKeys) {
    if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) return false;
    let keys = wantedKeys || [37, 38, 39, 40];
    return keys.includes(e.keyCode);
}

/**
 * @desc 判断 control + 上下 方向键
 * @param e {object}
 */
export function ctrlKeyWithUpDown(e) {
    return (e.ctrlKey || e.metaKey) && [38, 40].includes(e.keyCode);
}

/**
 * @desc 判断 enter 事件
 * @param e {object} 事件源
 * @return {BooleanConstructor}
 */
export function isEnterKeyDown(e) {
    //中文输入法下按的enter keyCode 都返回 229
    return e.key === 'Enter' && e.keyCode !== 229 && e.ctrlKey === false && e.altKey === false && e.metaKey === false;
}

/**
 * 生成唯一标识
 */
export function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/**
 * @description: 高阶组件的聚合，可在本方法里判断组件是否存在
 * @param {type} 
 * @return: 
 */
export function polymerizeComponents(base, components = [], params) {
    let component = base;
    components.forEach(item => { component = item(component, params) });
    return component;
}

// 产生随机key值
export function getRandom() {
    return String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12);
}

// 转换东八区为当地时间
/**
 * 
 * @param {*string} time 
 * @param {*string} format 
 */
export function changeTime(time, format = 'YYYY-MM-DD') {
    if (time) {
        let newTime = DongbaToLocalTime(moment(time));
        return newTime.format(format);
    }
}

export function getRandomPassword() {
    let min = 6;
    let max = 12;
    min = Math.ceil(min);
    max = Math.floor(max);
    let a = Math.floor(getSafeRandom() * (max - min + 1)) + min;
    return Array(a).fill('*').join('');
}

//精度 + 补0 + 千分位综合处理
export function formatAcuracy(value, len = 0) {
    if (value === null || value === undefined || String(value).endsWith('必输项')) {
        return value;
    }
    // 将科学计数法转成数字字符串
    value = convertNum(value);
    return commafy(addZero(formatDot(value, len), len));
}

// 四舍五入 by wangyang
export function ncRounding(value, scale) {
    // 如果没有精度，不需要处理四舍五入
    if (!scale) return value;

    let [_value, _scale] = [value, scale];

    if (
        !Object.prototype.toString.call(scale) !== "[object Number]" &&
        !isNaN(Number(scale))
    )
        _scale = Number(scale);

    // 校验参数
    if (Object.prototype.toString.call(value) !== "[object String]")
        _value = String(value);

    const re = /^(\-|\+)?(\d+)?\.?(\d+)?$/;

    if (_value.length < 500 && !re.test(_value)) {
        //console.warn("处理参数异常");
        return value;
    }

    // 分割value
    let [, afterPoint] = _value.split(".");

    // 有小数位数
    if (afterPoint && afterPoint !== "") {
        // 判断小数位数与精度的关系
        if (afterPoint.length > _scale) {
            _value = Number(_value);
            // 进行四舍五入操作
            _value = Number(_value.toFixed(_scale + 1));

            _value = _value * Math.pow(10, _scale);

            _value = Math.round(_value);

            _value = _value / Math.pow(10, _scale);
        }
    }

    return _value;
}

function convertNum(num_str) {
    //参数必须为 字符串
    //科学计数法字符 转换 为数字字符， 突破正数21位和负数7位的Number自动转换
    // 兼容 小数点左边有多位数的情况，即 a×10^b（aEb），a非标准范围（1≤|a|<10）下的情况。如 3453.54E-6 or 3453.54E6
    let resValue = '',
        power = '',
        result = null,
        dotIndex = 0,
        resArr = [],
        sym = '';
    let numStr = String(num_str);
    if (!/e/i.test(numStr)) {
        return numStr;
    }
    if (numStr[0] == '-') {
        // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
        numStr = numStr.substr(1);
        sym = '-';
    }
    let regExp = new RegExp('^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$', 'ig');
    if (numStr.length < 500) {
        result = regExp.exec(numStr);
    }
    if (result != null) {
        resValue = result[2];
        power = result[5];
        result = null;
    }
    if (!resValue && !power) {
        return false;
    }
    dotIndex = resValue.indexOf('.');
    resValue = resValue.replace('.', '');
    resArr = resValue.split('');
    if (Number(power) >= 0) {
        let subres = resValue.substr(dotIndex);
        power = Number(power);
        //幂数大于小数点后面的数字位数时，后面加0
        for (let i = 0; i < power - subres.length; i++) {
            resArr.push('0');
        }
        if (power - subres.length < 0) {
            resArr.splice(dotIndex + power, 0, '.');
        }
    } else {
        power = power.replace('-', '');
        power = Number(power);
        //幂数大于等于 小数点的index位置, 前面加0
        for (let i = 0; i <= power - 1; i++) {
            resArr.unshift('0');
        }
        let n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, '.');
    }
    resValue = resArr.join('');
    return sym + resValue;
}

//数字转换成千分位 格式
function commafy(num) {
    let pointIndex, intPart, pointPart;
    if (num === '-') {
        return '-';
    }

    if (Number.isNaN(+(num + '').split(',').join(''))) {
        //这里暂时就处理一下千分位的逗号
        return '';
    }

    num = num + '';
    if (num.length < 500 && /^.*\..*$/.test(num)) {
        pointIndex = num.lastIndexOf('.');
        intPart = num.substring(0, pointIndex);
        pointPart = num.substring(pointIndex + 1, num.length);
        intPart = intPart + '';
        let re = /(-?\d+)(\d{3})/;
        while (intPart.length < 500 && re.test(intPart)) {
            intPart = intPart.replace(re, '$1,$2');
        }
        num = intPart + '.' + pointPart;
    } else {
        num = num + '';
        let re = /(-?\d+)(\d{3})/;
        while (num.length < 500 && re.test(num)) {
            num = num.replace(re, '$1,$2');
        }
    }
    return num;
}

// 精度处理
function formatDot(value, len = 8) {
    let formatVal, dotSplit, val;

    val = (value || 0).toString();

    dotSplit = val.split('.');

    if (dotSplit.length > 2 || !value) {
        return value;
    }

    if (val.indexOf('.') > -1) {
        if (len == 0) {
            formatVal = dotSplit[0];
        } else {
            formatVal = val.substring(0, val.indexOf('.') + len + 1);
        }
    } else {
        formatVal = val;
    }

    return formatVal;
}

function addZero(num, scale) {
    if (num === '' || num === undefined || num === null) {
        return '';
    }

    if (scale > 0) {
        let start = String(num).split('.')[0];
        let end = String(num).split('.')[1];
        if (!end) {
            end = '';
        }
        let len = end.length;
        if (len < scale) {
            end = end.padEnd(scale, '0');
        }
        return start + '.' + end;
    } else {
        return num;
    }
}

/**
 * @description: 过滤掉符合某个属性值的数据
 * @param {type} 
 * @return: 
 */
export function filterColumns(columns = [], filterCondition) {
    if (!filterCondition) {
        return columns;
    }
    return columns.filter(item => {
        let result = filterCondition(item);
        if (item.children && result) {
            item.children = filterColumns(item.children, filterCondition);
            return item.children.length;
        }
        return result;
    });
}

//判断模板是否显示合计行
export function getMetaIsTotal(columns = []) {
    let isTotal = false; //模版是否配置可合计列
    Array.isArray(columns) && columns.forEach(item => {
        //便利判断模版中是否设置可合计列
        if (item.istotal) {
            isTotal = true;
        }
    });
    return isTotal;
}

//用于excel 粘贴，批改整理数据
export const excelPackageData = param => {
    let { pasteContent, activeCell, langJson, rows, getRowOldValue, tableId, store, viewData = [] } = param
    let { rowIndex, attrcode, column, direction = 'down', rowKeyValue } = activeCell
    let { itemtype, options, languageMeta, maxlength, scale } = column

    let rowKey = (store && store.getTableProps && store.getTableProps('rowKey')) || 'rowid';

    let pasteLength = pasteContent.length
    let noDeleteData = [];
    let deleteData = [];
    let trueIndex = rowIndex;

    // 向上批改的时候，取一下初始位置
    trueIndex = direction === 'up' ? (trueIndex - pasteLength + 1) : trueIndex;

    // 规整数据 其实这个rows 应该用getAllRows来处理 所有数据
    rows.forEach(item => {
        if (item.status == CONFIG.ROW_STATUS.delete) {
            deleteData.push(item);
        } else {
            noDeleteData.push(item)
        }
    });
    rows = noDeleteData.concat(deleteData);

    // 当前视图的数据
    viewData.filter((item, index) => {
        if (item.status == CONFIG.ROW_STATUS.delete) {
            // 如果在这些行之间有已经删除的行移除一个索引
            if (rowIndex >= index) {
                trueIndex--;
            }
            return false
        }
        return true;
    });

    let changedrows = []
    let newValue = []
    let pasteData = []

    // 重置初始位置
    rowIndex = trueIndex > 0 ? trueIndex : 0;

    for (let cellIndex = 0; cellIndex < pasteLength; cellIndex++) {
        let cell = pasteContent[cellIndex]

        if (isString(cell)) {
            cell = cell.trim();
        }

        if (isUndefined(cell)) {
            cell = '';
        }

        if (maxlength && cell.length > maxlength) {
            toast({ color: 'danger', title: langJson['table_tips'], content: `${langJson['table_tips_content']}${maxlength}` });
            return
        }

        let currentRow = viewData[rowIndex + cellIndex]
        if (!currentRow) {
            currentRow = {
                [rowKey]: String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                status: 2,
                values: {},
            }
        }
        let row = JSON.parse(JSON.stringify(currentRow))
        // 解决下初始位置rowKeyValue 不对的问题
        cellIndex === 0 && (rowKeyValue = row[rowKey])

        let val = cell;
        if (!row.values[attrcode]) {
            row.values[attrcode] = {};
        }
        //当前单元格各项属性 value display...
        let cellprops = row.values[attrcode];
        const checkTimeTypes = [
            'datepicker',
            'NCTZDatePickerStart',
            'NCTZDatePickerEnd',
        ];

        //  值处理
        switch (true) {
            //switch switch_browser checkbox_switch
            case (CONFIG.boolean.includes(itemtype)):
                if (cell === 'Y' || cell === true) {
                    val = true;
                    cellprops.value = true;
                } else {
                    val = false;
                    cellprops.value = false;
                }
                break
            case (itemtype === 'timepicker'):
                if (val && val.format) {
                    val = val.format('hh:mm:ss');
                    cellprops.value = val
                }
                break
            case (itemtype === 'number'):
                //todo校验字符串
                if (isString(cell)) {
                    cell = cell.replace(/,/g, '');
                }
                // 当数值类型只输入一个'-'的时候就将它赋值成0
                if (cell === '-') {
                    val = 0;
                    cell = 0;
                }
                cellprops.value = cell
                let reg = /^(-?\d+)(\.\d+)?$/ //数字，正数，负数，0，小数
                if (isString(cell) && isString(cell).length < 500 && !reg.test(cell)) {
                    toast({ color: 'danger', title: langJson['table_tips'], content: `格式错误，请输入正确数字` });
                    return
                }
                break
            case (checkTimeTypes.includes(itemtype)):
                // ctrl value  合法性校验
                if (moment(val, 'YYYY-MM-DD').isValid() || moment(val, 'YYYY-MM-DD HH:mm:ss').isValid()) {
                    let temp = null;
                    switch (itemtype) {
                        case 'datepicker':
                            // 普通日期 添加 当前时分秒
                            temp = moment(val, 'YYYY-MM-DD HH:mm:ss', true).isValid() ? val : `${val} ${moment().format('HH:mm:ss')}`;
                            break;
                        case 'NCTZDatePickerStart':
                            // start add 00:00:00
                            temp = moment(val, 'YYYY-MM-DD HH:mm:ss', true).isValid() ? val : `${val} 00:00:00`;
                            break;
                        case 'NCTZDatePickerEnd':
                            // end add 23:59:59
                            temp = moment(val, 'YYYY-MM-DD HH:mm:ss', true).isValid() ? val : `${val} 23:59:59`;
                            break;
                    }
                    if (isDongba()) {
                        cell = val = temp;

                    } else {
                        cell = val = LocalToDongbaTime(temp);
                    }
                    cellprops.value = cell
                } else {
                    // ctrl  日期不合法 
                    toast({ color: 'danger', title: langJson['table_tips'], content: ` 粘贴值 ${val} 日期格式不合法` });
                    return
                }
                break
            case (itemtype === 'select'):
                options.map(option => {
                    if (option.display == cell) {
                        cellprops.value = option.value;
                        cellprops.display = cell;
                        val = option.value;
                    }
                });
                break
            case (itemtype === 'residtxt'):

                const LangCode = getLangCode();
                const loginLang = languageMeta.filter(i => i.languageCode == LangCode);
                let mul = loginLang[0].index === '1' ? '' : loginLang[0].index;
                if (!row.values[attrcode + mul]) {
                    row.values[attrcode + mul] = {};
                }
                row.values[attrcode + mul].value = cell;
                row.values[attrcode + mul].index = mul;
                break
            case (itemtype === 'refer'):

                cellprops.value = val;
                cellprops.display = '';
                break
            default:
                cellprops.value = cell

        }
        //把行状态改为修改
        if (row.status == '0') {
            row.status = '1';
        }
        pasteData.push(val)
        const oldValue = getRowOldValue ? getRowOldValue(row[rowKey], attrcode) : null;
        let scaleData = cellprops.scale || scale || -1;
        changedrows.push({
            rowid: row[rowKey],
            newvalue: { value: val },
            oldvalue: { value: oldValue ? (itemtype === 'number' ? formatAcuracy(oldValue, scaleData) : oldValue) : '' },
        });
        newValue.push(row)
    }


    console.log('excelPackageData-rowKeyValue', rowKeyValue);

    // 保证过滤后的current index也正确  , 'normal', true  这个先不加  这里比较特殊
    rowIndex = store && store.getTrueRowIndex ? store.getTrueRowIndex({ rowIndex, rowKeyValue }, 'normal', 'filter') : rowIndex;

    return {
        areaCode: tableId,
        column,
        changedrows,
        newValue,
        pasteData,
        currentIndex: rowIndex,
        allData: rows,
    }
}

//用于缓存分页数据
export function setPageSizeStorage(meta, moduleId, num) {
    let pageSizeStore = getGlobalStorage('localStorage', '_ncc_pagesize_store');
    if (pageSizeStore) {
        pageSizeStore = JSON.parse(pageSizeStore);
    } else {
        pageSizeStore = {};
    }
    let currentKey = getPageSizeStoreKey(meta, moduleId);
    if (currentKey) {
        pageSizeStore[currentKey] = num;
        setGlobalStorage('localStorage', '_ncc_pagesize_store', JSON.stringify(pageSizeStore));

    }
}

export function getPageSizeStorage(meta, moduleId, defaultPageSize) {
    let currentKey = getPageSizeStoreKey(meta, moduleId);
    if (currentKey) {
        let pageSizeStore = getGlobalStorage('localStorage', '_ncc_pagesize_store');
        if (pageSizeStore) {
            pageSizeStore = JSON.parse(pageSizeStore);
            return pageSizeStore[currentKey] || defaultPageSize || '100';
        }
    }
    return defaultPageSize || '100';
}

function getPageSizeStoreKey(meta, moduleId) {
    let key = '';
    if (meta && meta[moduleId]) {
        let appcode = meta[moduleId].appcode || pageTo.getAppCode();
        let pagecode = meta[moduleId].pagecode || pageTo.getPageCode();
        let pageid = meta[moduleId].pageid || meta.pageid;
        let userDataJSON = getGlobalStorage('localStorage', "STOREFORINFODATA");
        let userData = userDataJSON && JSON.parse(userDataJSON);
        let userid = userData && userData.userId;

        if (appcode && pagecode && pageid && userid) {
            key = appcode + '-' + pagecode + '-' + pageid + '-' + userid;
        }
    }

    return key;

}

/**
 * 数据处理
 * @param {*} IType 
 * @param {*} display 
 * @param {*} value 
 * @param {*} scale 
 * @param {*} attrcode 
 * @param {*} languageMeta 
 * @param {*} values 
 * @param {*} datatype 
 * @param {*} options 
 * @param {*} multiple 
 * @param {*} langJson 
 */
export function handleBrowseData(IType, display, value, scale, attrcode, languageMeta, values, datatype, options, multiple, langJson) {
    // TODO 数据格式的转换
    // 特殊处理下逻辑型  枚举类型
    if (
        datatype === "32" &&
        ["select", "checkbox", "radio"].includes(IType) &&
        isArray(options) &&
        !display &&
        value !== null &&
        value !== undefined
    ) {
        if (IType === "checkbox" || multiple) {
            let displayList = options
                .filter(item => value.includes(item.value))
                .map(option => {
                    return option.display;
                });
            return displayList.join(",");
        } else {
            let { display } =
                options.filter(item => item.value == value)[0] || {};
            return display;
        }
    }

    switch (true) {
        case CONFIG.displayTypes.includes(IType):
            return display || value;
        case CONFIG.boolean.includes(IType):
            return value == "Y" || value == true
                ? langJson["table_yes"]
                : langJson["table_no"];
        case CONFIG.timeTypes.includes(IType):
            if (IType === "datetimepicker") {
                return formatDatetime(changeTime(value, "YYYY-MM-DD HH:mm:ss"), 'datetime');
            } else if (IType === "timepicker") {
                return formatDatetime(value, 'time');
            } else if (IType === "datePickerNoTimeZone") {
                return formatDatetime(value, 'date');
            }
            return formatDatetime(changeTime(value), 'date');
        case IType === "label":
            return isNullOrVoid(display) ? value : display || value;
        case IType === "number":
            // 表格浏览态加了四舍五入
            return formatNumber(formatAcuracy(value, scale), true);
        case IType === "password":
            // 密码框浏览态显示***
            return value && getRandomPassword();
        case IType === "residtxt": // 对多语的处理
            let LangCode = getLangCode();
            let loginLang = languageMeta.filter(i => i.languageCode == LangCode);
            if (loginLang.length > 0) {
                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                if (
                    values[attrcode + loginLang[0].index] &&
                    values[attrcode + loginLang[0].index].value
                ) {
                    value = values[attrcode + loginLang[0].index].value;
                    return value;
                }
                return value;
            }
            return value;
        default:
            return isNullOrVoid(display) ? value : display || value;
    }
}

/*
 * @method   检测浏览器是否是IE浏览器
 */
export function isIE() {
    if (!!window.ActiveXObject || "ActiveXObject" in window)
        return true;
    else
        return false;
}


export function getOs() {
    if (navigator.userAgent.indexOf("MSIE") > 0) {
        return "MSIE";
    }
    if (navigator.userAgent.indexOf("Firefox") > 0) {
        return "Firefox";
    }
    if (navigator.userAgent.indexOf("Safari") > 0) {
        return "Safari";
    }
    if (navigator.userAgent.indexOf("Camino") > 0) {
        return "Camino";
    }
    if (navigator.userAgent.indexOf("Gecko/") > 0) {
        return "Gecko";
    }
}