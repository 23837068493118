import React, { Component } from "react";
import { getMultiLang } from '@platform/api';
import './index.less';
import { isObject, isArray } from "../../utils";

export default function indexCol(Table) {
    return class ModalTable extends Component {
        constructor(props) {
            super(props);
        }
        render() {
            let { columns, indexColConfig = {}, checkColConfig = {}, data = [] } = this.props
            let { showIndex, langJson } = indexColConfig

            data.length && data.forEach((row, index) => { //解决业务组 会这样取值 :record.values['numberindex']
                if (isObject(row.values)) {
                    row.values['numberindex'] = { value: `${index + 1}` }
                } else {
                    row['numberindex'] = { value: `${index + 1}` }//兼容simpletable
                }
            });
            const numberColum = {
                label: langJson['table001'],
                attrcode: 'numberindex',
                dataIndex: 'numberindex',
                className: 'table-index',
                visible: true,
                fixed: 'left',
                itemtype: 'customer',
                width: checkColConfig.showCheck ? '60px' : '70px',
                render: (text, record, index) => {
                    return <div>{index + 1}</div>
                },
            }
            if (showIndex && isArray(columns)) {
                columns = [numberColum, ...columns]
            }
            return (<Table {...this.props} columns={columns} data={data} />)
        }

    }

}