/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-09-15 15:14:02
 * @LastEditors: bbq
 * @LastEditTime: 2020-11-19 14:12:26
 */
import React, { Component } from "react";
import { isFunction } from "@platform/api";
import { ErrorPop } from "@platform/base";

export default function errorCol(Table) {
    return class ModalTable extends Component {
        constructor(props) {
            super(props);
        }

        //修改saga行checkbox/numberindex 列
        changeColumn = () => {
            let {
                columns,
                indexColConfig = {},
                checkColConfig = {},
                name,
            } = this.props;
            let { showIndex = true } = indexColConfig;
            let { showCheck = true } = checkColConfig;
            // 有checkbox 修改checkbox列 ; 无checkbox列，修改numberindex列
            let errorCode = showCheck ? 'checkbox' : showIndex ? 'numberindex' : '';
            if (Array.isArray(columns) && columns.length > 0 && errorCode) {
                let errorCol = columns.find(e => e.attrcode === errorCode);
                if (errorCol) {
                    let oldRender = errorCol.render;
                    let oldCellClick = errorCol.onCellClick;
                    errorCol.onCellClick = (record, e) => {
                        let { saga_status = {} } = record.values || record;
                        if (saga_status.value !== "1") {
                            oldCellClick && oldCellClick(record, e);
                        }
                    }
                    errorCol.render = (text, record = {}, index) => {
                        let { saga_status = {} } = record.values || record;
                        return saga_status.value === "1" ? (
                            <ErrorPop record={record.values || record} name={name} />
                        ) : (oldRender(text, record, index));
                    };
                }
            }
            return columns;
        };

        //禁用saga行点击事件
        rowClick = (record, index, e) => {
            if (!record) return;
            let { onRowClick } = this.props;
            let { saga_status = {} } = record.values || record;
            // 错误行禁用
            if (saga_status.value === "1") return;
            isFunction(onRowClick) && onRowClick(record, index, e);
        };

        //禁用saga行快捷键
        rowRef = record => {
            if (!record) return;
            let { saga_status = {} } = record.values || record;
            if (saga_status.value === "1") {
                record.element && record.element.setAttribute("tabindex", "-1");
            }
        };

        render() {
            let { columns, onRowClick, ...others } = this.props;

            return (
                <Table
                    columns={this.changeColumn()}
                    onRowClick={this.rowClick}
                    rowRef={this.rowRef}
                    {...others}
                />
            );
        }
    };
}
