/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-08-23 16:13:06
 * @LastEditors: bbq
 * @LastEditTime: 2020-08-24 15:30:46
 */
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { isFunction, excelPackageData } from '../../utils'
import { getMultiLang, toast } from '@platform/api';
import './index.less';

export default function excel(Table) {
    return class Excel extends Component {
        constructor(props) {
            super(props);
            this.state = { json: {} }
        }

        componentDidMount() {
            const { excelConfig } = this.props;
            const { onBatchChange } = excelConfig
            //配置 禁用excel粘贴功能
            if (onBatchChange) {
                // 监听crtl v
                this.listenCrtlV();
            }
        }

        getPasteData = () => {
            return new Promise(resolve => {
                // 异步处理
                //IE浏览器
                if (window.clipboardData) {
                    let pasteContent = window.clipboardData.getData('Text');
                    resolve(pasteContent);
                    //其他浏览器
                } else if (navigator.clipboard) {
                    navigator.clipboard.readText().then(res => {
                        let pasteContent = res;
                        resolve(pasteContent);
                    })
                }
            });
        }

        formatPasteData = pasteContent => {
            const { excelConfig } = this.props
            const { langJson } = excelConfig
            if (pasteContent.length > 2) {
                var endStr = pasteContent.substr(pasteContent.length - 1, pasteContent.length);
                if (endStr === '\n') {
                    pasteContent = pasteContent.substr(0, pasteContent.length - 1);
                }
            }
            //判断是否多列
            let reg = /\t/
            let text = []
            if (reg.test(pasteContent)) {
                let rows = pasteContent.split('\n') || [];
                toast({ color: 'danger', title: langJson['table_tips'], content: `只能粘贴一列数据` });
                rows.forEach(row => {
                    let cells = row.split('\t') || []
                    let firstCell = cells[0]
                    text.push(firstCell)
                })
            } else {
                text = pasteContent.split('\n');
            }
            return text
        }

        listenCrtlV = () => {
            const { store, excelConfig } = this.props
            const { onBatchChange, langJson } = excelConfig
            let pasteRange = this.tableDom.querySelector('.u-table-body table')
            pasteRange.addEventListener('paste', e => {
                let { getActiveCell } = store
                const { tableId } = excelConfig;
                let activeCell = getActiveCell()
                let text = this.formatPasteData(e.clipboardData.getData('text/plain'));
                console.log(text.length, text.length <= 1)
                let flag = pasteRange.contains(e.target) && activeCell && text.length > 1
                if (flag) {
                    document.body.focus()
                    let rows = store.getData();
                    let viewData = store.getViewData();
                    let param = {
                        pasteContent: text,
                        activeCell,
                        langJson,
                        rows,
                        viewData,
                        getRowOldValue: store.getRowOldValue,
                        tableId,
                        store: store,
                    }
                    let data = excelPackageData(param)
                    if (!data) { return }
                    isFunction(onBatchChange) && onBatchChange(data)
                    e.preventDefault();
                    // 表格数据  是否包含删除的数据 这里要斟酌下
                    // let rows = store.getViewData('filter');
                }

            })
            document.addEventListener('keydown', e => {
                if ((e.metaKey || e.ctrlKey) && e.keyCode === 86) {
                    this.getPasteData().then(pasteContent => {
                        let { getActiveCell } = store
                        let activeCell = getActiveCell()
                        if (pasteContent && activeCell) {
                            const { tableId } = excelConfig;
                            let { column } = activeCell
                            let { itemtype } = column
                            let booleanType = ['switch', 'checkbox_switch']
                            let text = this.formatPasteData(pasteContent);
                            let flag = activeCell && text.length > 1
                            if (flag && booleanType.includes(itemtype)) {
                                document.body.focus()
                                let rows = store.getData();
                                let viewData = store.getViewData();
                                let param = {
                                    pasteContent: text,
                                    activeCell,
                                    langJson,
                                    rows,
                                    viewData,
                                    getRowOldValue: store.getRowOldValue,
                                    tableId,
                                    store: store,
                                }
                                let data = excelPackageData(param)
                                if (!data) { return }
                                isFunction(onBatchChange) && onBatchChange(data)
                                e.preventDefault();
                            }
                        }
                    })
                }
            })
        }

        render() {
            return (
                <Table
                    {...this.props}
                    ref={dom => {
                        this.tableDom = ReactDOM.findDOMNode(dom);
                    }}
                />)
        }

    }
}