/*
 * @Descripttion: 框选
 * @version:
 * @Author: bbq
 * @Date: 2020-08-03 10:16:50
 * @LastEditors: bbq
 * @LastEditTime: 2021-03-08 15:54:04
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import { isFunction, uuidv4, isIE, getOs } from '../../utils/index';
import { getSysFieldid } from '@platform/api';
// import { Selectable as Selector } from '@platform/base';
import Selector from './Selector';

require('./index.less');

// 导出
selectable.Selector = Selector;

const SPECIAL_KEY = '__tableHeader__';

// 是否是ie浏览器
const inIEClassName = isIE() ? ' table-wrapper-ie ' : '';

// 是否是火狐浏览器

const inFireFoxName = getOs() === 'Firefox' ? ' table-wrapper-firefox ' : '';
const inSafariFoxName = getOs() === 'Firefox' ? ' table-wrapper-safari ' : '';

/**
 * 解析选择项
 * @param {*} item 
 */
const parseSelectItem = function (item = '') {
    const keys = item.split('@');
    if (keys.length < 2) {
        console.warn('缺少一部分key')
        return {};
    }
    return {
        rowKeyValue: keys[0],
        attrcode: keys[1],
    };
}

/**
 * 获取列集合
 * @param {*} arrColumns 
 * @param {*} startAttrcode 
 * @param {*} endAttrcode 
 * @param {*} disabledColumn 
 */
const getColumnSection = function (arrColumns, startAttrcode, endAttrcode, disabledColumn, disabledColType) {
    // console.log(arrColumns, 'arrColumns'); // 包含了父级
    let columnObjSection = [];
    let columnCodeSection = [];
    if (startAttrcode === endAttrcode) {
        arrColumns.some(column => {
            if (column['attrcode'] === startAttrcode) {
                columnObjSection = [{ attrcode: startAttrcode, label: column['label'] }];
                !column.children && (columnCodeSection = [startAttrcode]);
                return true;
            }
        });
    } else {
        arrColumns.some(column => {
            if (column['attrcode'] === startAttrcode || column['attrcode'] === endAttrcode) {
                // 第二次进入这个逻辑
                if (columnObjSection.length > 0) {
                    columnObjSection.push({ attrcode: column['attrcode'], label: column['label'] });
                    !column.children && (columnCodeSection.push(column['attrcode']));
                    return true;
                }
                // 第一次
                !column.children && (columnCodeSection.push(column['attrcode']));
                columnObjSection.push({ attrcode: column['attrcode'], label: column['label'] });
            } else {
                // 第一次之后
                !column.children && columnCodeSection.length > 0 && columnCodeSection.push(column['attrcode']);
                columnCodeSection.length > 0 && columnObjSection.push({ attrcode: column['attrcode'], label: column['label'] });
            }
        });
    }
    // 去除禁止的列  为啥不在上面做
    columnCodeSection = columnCodeSection.filter(item => !disabledColumn.includes(item));
    columnObjSection = columnObjSection.filter(item => !disabledColumn.includes(item.attrcode));
    return { columnObjSection, columnCodeSection };
}

/**
 * 特殊类型
 * @description: 
 * @param {type} 
 * @return {type} 
 */
const handleSpecialItem = function (item = {}, data = [], rowKey) {
    let { rowKeyValue, attrcode } = item;
    let normalItem = item;
    // 表头上的数据
    if (rowKeyValue === SPECIAL_KEY) {
        const row = data[0];

        if (!row) {
            return {
                normalItem: { attrcode },
                specialItem: item,
            }
        }

        return {
            normalItem: {
                rowKeyValue: row[rowKey],
                attrcode,
            },
            specialItem: item,
        }
    }

    return {
        normalItem,
        specialItem: null,
    }
}

/**
 * 创建标准化表格
 * @param {*} Table 
 */
export default function selectable(Table) {
    if (!Table) {
        console.error('缺少Table组件');
    }
    // TODO
    return class MetaTable extends Component {
        constructor(props) {
            super(props);
            this.uuid = uuidv4();
        }

        // 处理选中的数据 返回一个二维数组
        getSelectorData(selectItems, hotkey, { currentKey, startKey, dragEnd, direction }) {
            let { high_selectable = {} } = this.props;
            let activeCells = {};
            let activeSpecialCells = [];
            let {
                data, arrColumns, rowKey,
                onBatchChange,
                disabledColType = ['customer'],
                disabledColumn = ['numberindex', 'opr', 'checkbox'],
                disabledBatchColumn = ['numberindex', 'opr', 'checkbox'],
            } = high_selectable;

            // 区间型
            if (hotkey === 'shift' || hotkey === 'ctrl&shift' || hotkey === 'slide' || hotkey === 'drag') {
                // let items = Object.keys(selectItems);
                if (!startKey || !currentKey) {
                    console.warn(hotkey + ' invalid select section must start and end');
                    return {};
                }

                // 开始项
                const startItem = parseSelectItem(startKey);
                // 区分表头
                let { normalItem: startNormalItem, specialItem: startSpecialItem } = handleSpecialItem(startItem, data, rowKey);
                const { rowKeyValue: startRowKeyValue, attrcode: startAttrcode } = startNormalItem;

                // 结束项
                const endItem = parseSelectItem(currentKey);
                // 区分表头
                let { normalItem: endNormalItem, specialItem: endSpecialItem } = handleSpecialItem(endItem, data, rowKey);
                const { rowKeyValue: endRowKeyValue, attrcode: endAttrcode } = endNormalItem;

                // 判断rowKeyValue 同列的才能批改
                if (startAttrcode !== endAttrcode && hotkey === 'drag') {
                    console.warn('column patch must single !');
                    return {};
                }

                // 计算列区间
                const { columnCodeSection, columnObjSection } = getColumnSection(arrColumns, startAttrcode, endAttrcode, disabledColumn, disabledColType);
                console.log(columnCodeSection, columnObjSection, '已经选中的单元格');

                // 区分表头
                if (startSpecialItem && endSpecialItem) {
                    activeSpecialCells = columnObjSection;

                    console.log(startSpecialItem, endSpecialItem, activeSpecialCells);
                    return { activeSpecialCells, activeCells };
                }

                if (startSpecialItem || endSpecialItem) {
                    activeSpecialCells = columnObjSection;
                    console.log(startSpecialItem, endSpecialItem, activeSpecialCells);
                }

                // 后置 让头选择可以进行
                if (!startRowKeyValue || !endRowKeyValue) { return {}; }

                // 行区间开始和结束的标识
                let inSection = false;
                // 行区间
                data.some(row => {
                    const rowKeyValue = row[rowKey];
                    // 不知道开始行 先匹配，先记录
                    if (rowKeyValue === startRowKeyValue || rowKeyValue === endRowKeyValue) {
                        activeCells[rowKeyValue] = columnCodeSection;
                        // 最后一次匹配 退出
                        if (inSection || startRowKeyValue === endRowKeyValue) {
                            return true;
                        }
                        // 第一次匹配 记录
                        inSection = true;
                    } else {
                        // 在第一次和最后一次之间时 匹配
                        if (inSection) {
                            activeCells[rowKeyValue] = columnCodeSection;
                        }
                    }
                });
            }

            // 分散型
            if (hotkey === 'ctrl') {
                for (let key in selectItems) {
                    const { rowKeyValue, attrcode } = parseSelectItem(key);
                    if (!rowKeyValue) { return {}; }
                    // 去除禁用的
                    if (!disabledColumn.includes(attrcode) && rowKeyValue !== SPECIAL_KEY) {
                        activeCells[rowKeyValue] = activeCells[rowKeyValue] || [];
                        activeCells[rowKeyValue].push(attrcode);
                    }
                }
            }

            // 单点
            if (hotkey === 'down' && currentKey) {
                const { rowKeyValue, attrcode } = parseSelectItem(currentKey);
                if (!rowKeyValue) { return {}; }

                if (!disabledColumn.includes(attrcode) && !disabledBatchColumn.includes(attrcode) && rowKeyValue !== SPECIAL_KEY) {
                    activeCells[rowKeyValue] = activeCells[rowKeyValue] || [];
                    activeCells[rowKeyValue].push(attrcode);
                }
            }

            // 批改触发
            if (hotkey === 'drag' && dragEnd) {
                const activeCell = parseSelectItem(startKey);
                if (!activeCell.rowKeyValue) { return {}; }
                activeCell.direction = direction;
                !disabledBatchColumn.includes(activeCell.attrcode) && isFunction(onBatchChange) && onBatchChange(activeCell, activeCells, direction);
            }

            return { activeSpecialCells, activeCells };
        }

        render() {

            let {
                high_selectable = {},
                ...others
            } = this.props;

            let uniqueSelector = `selectable-${this.uuid}`;

            let {
                langJson,
                selector = " tbody tr .table-cell-wrapper, thead tr .column-title-wrapper, thead tr .column-title-wrapper-bg, tbody tr .table-cell-wrapper-bg, tbody tr .table-cell-drag-icon",
                canvas = '.' + uniqueSelector,
                disabled = false, // 可选值 true false 'shift' 'ctrl' 'slide' 'drag'
                // 启用用户文字选中
                userSelect = false,
                enable = false,
                // 启用单选 
                single = true,
                // 是否选中单元格
                selectCell = true,
                // 开始项  主要配合外部的 单选事件处理
                startItem = '',
                onSelect,
                onDeSelect,
                onClearData,
                getPrevSelector,
                store,
                disabledBatchColumn = ['numberindex', 'opr', 'checkbox'],
            } = high_selectable;

            return <div className={classNames(uniqueSelector, 'selectable-canvas', {
                'selectable-canvas-enabled': enable,
                'table-wrapper-ie': inIEClassName,
                'table-wrapper-firefox': inFireFoxName,
                'table-wrapper-safari': inSafariFoxName,
                // 'user-unselect': enable,
            })}>
                {enable &&
                    <Selector
                        canvas={canvas}
                        hasSelectorItem={(hotkey, key) => {
                            if (hotkey === 'ctrl') {
                                let prevSelector = getPrevSelector && getPrevSelector(key, hotkey);
                                let { rowKeyValue: currentRowKeyValue, attrcode } = parseSelectItem(key);
                                // 选中获取反选
                                if (!selectCell && prevSelector) {
                                    let isSelect = false;
                                    for (const item in prevSelector) {
                                        let { rowKeyValue } = parseSelectItem(item);
                                        // 清除已选
                                        if (currentRowKeyValue === rowKeyValue && prevSelector[item]) {
                                            isSelect = true;
                                            onDeSelect && onDeSelect({ [currentRowKeyValue]: [attrcode] }, hotkey);
                                        }
                                    }
                                    return isSelect;
                                } else if (prevSelector[key]) {
                                    // 移除
                                    onDeSelect && onDeSelect({ [currentRowKeyValue]: [attrcode] }, hotkey);
                                    return true;
                                } else {
                                    prevSelector[key] = true;
                                    return false;
                                }
                            }
                        }}
                        single={single}
                        selector={selector}
                        disabled={disabled} // 可选值 true false 'shift' 'ctrl' 'slide'
                        userSelect={userSelect} // 启用用户文字选中
                        // 开始项  主要配合外部的 单选事件处理
                        startItem={startItem}
                        onSelect={(selectItems, hotkey, { currentKey, startKey, dragEnd, direction }) => {
                            let { activeCells, activeSpecialCells } = this.getSelectorData(selectItems, hotkey, { currentKey, startKey, dragEnd, direction });
                            let isCheckbox = false;
                            // shift 的逻辑处理
                            if (hotkey === 'shift' || hotkey === 'ctrl&shift') {
                                if (this.prevActiveCells && this.prevStartKey &&
                                    startKey === this.prevStartKey &&
                                    currentKey !== this.prevCurrentKey) {
                                    onDeSelect && onDeSelect(this.prevActiveCells, hotkey);
                                }
                                // 记录数据  为了清除数据
                                this.prevActiveCells = activeCells;
                                this.prevStartKey = startKey;
                                this.prevCurrentKey = currentKey;
                            }

                            // console.log(activeCells);
                            if (!activeCells) { return; }
                            let current = {};
                            if (currentKey) {
                                current = parseSelectItem(currentKey);
                                if (!current.rowKeyValue) { return; }
                                current.attrcode === 'checkbox' && (isCheckbox = true);
                                // 放到后面  onSelect代码逻辑还是要走的
                                if (current.rowKeyValue === SPECIAL_KEY || disabledBatchColumn.includes(current.attrcode)) {
                                    current = {};
                                }
                            }
                            // console.log(selectItems, activeCells, activeSpecialCells);
                            let isBatch = hotkey === 'ctrl' || hotkey === 'shift' || hotkey === 'ctrl&shift' || hotkey === 'slide' || hotkey === 'drag';
                            onSelect && onSelect(activeCells, activeSpecialCells, hotkey, { current, isCheckbox, isBatch });
                        }}
                        clearData={(info, hotkey) => {
                            // 这种方式会出一个bug  导致框选的单元格样式不能正确渲染
                            // 清理dom  这里写死了class 其实应该在上层传递  或者在上层处理  由于都是内部处理  所以这里可以写死
                            // let $wrapper = document.querySelector('.' + uniqueSelector);
                            // if ($wrapper) {
                            //     let $activeTitle = $wrapper.querySelectorAll('.column-title-actived');
                            //     if ($activeTitle) {
                            //         $activeTitle.forEach(ele => {
                            //             ele.setAttribute('class', ele.className.replace(/column-title-actived/gi, ''));
                            //         })
                            //     }
                            //     let $activeCell = $wrapper.querySelectorAll('.table-cell-actived');
                            //     if ($activeCell) {
                            //         $activeCell.forEach(ele => {
                            //             ele.setAttribute('class', ele.className.replace(/table-cell-actived|table-cell-line-left|table-cell-line-right|table-cell-line-top|table-cell-line-bottom/gi, ''));
                            //         })
                            //     }
                            // }
                            onClearData && onClearData(info.isSelect, hotkey);
                        }}
                    />
                }
                <Table
                    headerEventNoStop={true}
                    {...others}
                />

            </div>
        }
    }
}