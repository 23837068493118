
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Select, Pagination, Tooltip, Input } from '@platform/base';
import { getSysFieldid, getMultiLang } from '@platform/api';
const MAX_BUTTONS = 5;
const Option = Select.Option;
import './index.less';
import PropTypes from "prop-types";

export default function pagination(Table) {
    return class PaginationWrapper extends Component {
        static defaultProps = {
            pageInfo: {},
        };

        static propTypes = {
            pageInfo: PropTypes.object,
            pageInfoChange: PropTypes.function,
        };

        constructor(props) {
            super(props);
            this.state = {
                hideSelectDropdown: false,
                //pageInfo: this.props.pageInfo,
                json: {},
                pageSizeVal: '',
            };
            this.options = ['10', '20', '50', '100'];

            this.selectDom = null;

        }

        UNSAFE_componentWillMount() {
            //加载多语
            let callback = json => {
                let pageSizeVal = this.props.pageInfo && this.props.pageInfo.pageSize;
                pageSizeVal = pageSizeVal + json['pagination003'] + '/' + json['pagination001'];
                this.setState({ 
                    json,
                    pageSizeVal, 
                });
            };
            getMultiLang({ moduleId: 'table_lib_pagination', callback });
        }

        UNSAFE_componentWillReceiveProps(nextProps){
            if(nextProps.pageInfo && nextProps.pageInfo.pageSize){
                if(!this.props.pageInfo || nextProps.pageInfo.pageSize != this.props.pageInfo.pageSize){
                    let json = this.state.json;
                    this.setState({
                        pageSizeVal: nextProps.pageInfo.pageSize + json['pagination003'] + '/' + json['pagination001']
                    })
                }
            }

        }

        //pageIndex事件
        pageIndexChange = val => {
            let { pageInfoChange, pageInfo } = this.props;
            let { total, pageSize } = pageInfo;
            //更新 pageInfo
            let newPageInfo = {
                ...pageInfo,
                pageIndex: val,
                totalPage: Math.ceil(total / pageSize),
            }

            if (typeof pageInfoChange === 'function') {
                pageInfoChange(newPageInfo);
            }
        };

        // pageSize事件
        pageSizeChange = val => {
            let { pageInfoChange, pageInfo } = this.props;
            let { total } = pageInfo;
            let newPageInfo = {
                ...pageInfo,
                pageIndex: 1,
                pageSize: +val,
                totalPage: Math.ceil(total / val),
            }

            if (typeof pageInfoChange === 'function') {
                pageInfoChange(newPageInfo);
            }
        };

        render() {
            let { hideSelectDropdown, json } = this.state;
            let { pageInfo, checkNum, showPagination = false, showPageSizeOption = true, maxPageSize = 5000 } = this.props;
            let { pageSize, total, totalPage, pageIndex } = pageInfo;
            //用户自定义录入得分页条数也显示 '条/页'
            if(!this.options.includes(String(pageSize))){
                pageSize = pageSize + json['pagination003'] + '/' + json['pagination001'];
            }
            let dropdownStyle = hideSelectDropdown ? { dropdownStyle: { display: "none" } } : {};
            
            return (
                <React.Fragment>
                    <Table {...this.props} />
                    {showPagination && <div
                        className="pagination-wrapper"
                        fieldid={getSysFieldid("pagination-div")}
                    >
                        <div className="pagination-size">
                        { !showPageSizeOption ?
                            <Input 
                                value={this.state.pageSizeVal}
                                className="pagination-input" 
                                onFocus={(e, val)=>{
                                    if(val && val.length < 500){
                                        let value = String(val).match(/\d{1,}/g);
                                        if(Array.isArray(value) && value.length){
                                            value = value[0];
                                        }
                                        this.setState({
                                            pageSizeVal: value
                                        })
                                    } 
                                }}
                                onChange={(val)=>{
                                    if(val){
                                        if(val.length < 500 && /^[1-9]*[1-9][0-9]*$/.test(val)){
                                            this.setState({
                                                pageSizeVal: val
                                            })
                                        }
                                    }else{
                                        this.setState({
                                            pageSizeVal: ''
                                        })
                                    }
                                    
                                }}
                                onBlur={(val)=>{
                                    // 自定义录入每页条数完成后的回调
                                    if (val.length < 500 && /^[1-9]*[1-9][0-9]*$/.test(val)) {
                                        if(val > maxPageSize){
                                            val = maxPageSize;
                                        }
                                        this.setState({
                                            pageSizeVal: val + json['pagination003'] + '/' + json['pagination001']
                                        })
                                        this.pageSizeChange(val);
                                    }
                                }}
                                onEnter={({e}=param )=> {
                                    // 自定义录入每页条数完成后的回调
                                    if(e && e.target){
                                        e.target.blur();
                                    }
                                }}
                            > 
                            </Input> 
                            : 
                            <Select
                                ref={(dom) => {
                                    this.selectDom = dom;
                                }}
                                className="pagination-select"
                                dropdownClassName="pagination-select-dropdown"
                                fieldid="page-size"
                                supportSearch={true}
                                showSearch={true}
                                supportWrite={true}
                                showClear={false}
                                value={String(pageSize || '')}
                                onSelect={this.pageSizeChange}
                                onChange={val => {
                                    // 自定义录入每页条数完成后的回调
                                    if (Array.isArray(val)) {
                                        if (val[0].length < 500 && /^[1-9]*[1-9][0-9]*$/.test(val[0])) {
                                            this.pageSizeChange( val[0] > maxPageSize ? maxPageSize : val[0]);
                                        }
                                    }
                                }}
                                onFocus={() => {
                                    //获取焦点后显示下拉框
                                    if (hideSelectDropdown) {
                                        this.setState({ hideSelectDropdown: false });
                                    }
                                }}
                                onSearch={() => {
                                    // 在input录入过程中不显示下拉
                                    if (!hideSelectDropdown) {
                                        this.setState({ hideSelectDropdown: true });
                                    }
                                }}
                                onKeyDown={e => {
                                    // 回车键应用自定义值
                                    if (e.keyCode == 13) {
                                        this.selectDom && ReactDOM.findDOMNode(this.selectDom)
                                            .querySelector(".pagination-select .u-select-selection--single .u-select-search-field")
                                            .blur();
                                    }
                                }}
                                // 给select下拉框设置显示隐藏
                                {...dropdownStyle}
                            >
                                {this.options.map((item, index) => {
                                    return (
                                        <Option
                                            key={index}
                                            value={item}
                                            className="pagination-option"
                                        >
                                            {/* <Tooltip
                                                className="pagination-tip"
                                                placement="bottom"
                                                overlay={`${item}${json['pagination003']}/${json['pagination001']}`}
                                            > */}
                                            <div className="pagination-size">
                                                {`${item}${json['pagination003']}/${json['pagination001']}`}
                                            </div>
                                            {/* </Tooltip> */}
                                        </Option>
                                    )
                                })}
                            </Select> 
                        } 
                        {!!+total && (
                            <span className="pagination-total">
                                {json['pagination002']}<span>{total}</span>{json['pagination003']}
                                {checkNum && <span style={{ marginLeft: '1px' }}>&nbsp;{json['pagination004']}{checkNum()}{json['pagination003']}</span>}
                            </span>
                        )}
                        </div>
                        <div className="pagination">
                            <Pagination
                                prev
                                next
                                boundaryLinks
                                items={Number(totalPage || 1)}
                                /*分页器按钮：最多显示7个按钮, 当页数>7时 左侧最多显示5个按钮 等于7时 左侧最多显示6个按钮*/
                                maxButtons={Number(totalPage || 1) === 7 ? 6 : MAX_BUTTONS}
                                activePage={Number(pageIndex)}
                                onSelect={this.pageIndexChange}
                            />
                        </div>
                    </div>}
                </React.Fragment>
            )
        }
    }
}