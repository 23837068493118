/*
 * @Descripttion: 列创建
 * @version: 
 * @Author: bbq
 * @Date: 2020-06-02 16:29:54
 * @LastEditors: bbq
 * @LastEditTime: 2021-03-08 10:41:54
 */

import React, { Component, Fragment } from 'react';

import { isFunction, filterColumns, isWrong, isObject } from '../../utils/index';

import { ItemProvider } from '@platform/template';
import { getSysFieldid, getLangCode } from "@platform/api";

import Title from './Title';
import Cell from './Cell';

import CONFIG from '../../config';

class Test1 extends Component {
    render() {
        return <div>111</div>;
    }
}

class Test extends Component {
    render() {
        return <Test1></Test1>;
    }
}

/**
 * @name: 
 * @test: test font
 * @msg: 创建列属性
 * @param {type} 
 * @return: 
 */
export function createColumns(columns, others) {
    // TODO 对columns 数据要进行格式化  可以在放入Store时执行

    // 过滤不可见的列
    columns = filterColumns(columns, item => {
        !item && console.warn('columns include empty element, please check columns data')
        return item.visible;
    });

    // 预处理一些状态和属性，避免单元格渲染时处理耗费大量时间
    let loginLanguage = getLangCode();

    //  返回渲染列
    return columns.map(item => {
        if (item.children) {
            return {
                // ...item,
                visible: item.visible,
                required: false,
                className: (item.className || '') + ' parent-column',
                key: item.attrcode,
                dataIndex: item.dataIndex || item.attrcode,
                title: <Title {...item} title={item.title || item.label} {...others} attrcode={item.attrcode || item.key} isParent />,
                children: createColumns(item.children, others),
            };
        }
        if (isWrong(item.width)) {
            if (item.itemtype === 'datetimepicker') {
                item.width = '240px';
            } else if (item.itemtype === 'rangepicker') {
                item.width = '390px';
            } else if (CONFIG.timeTypes.includes(item.itemtype)) {
                item.width = '160px';
            } else {
                item.width = '120px';
            }
        }

        item.key = item.key || item.attrcode;
        let cellIcon = others.cellIcon || item.cellIcon;
        let renderStatus = others.renderStatus || item.renderStatus;
        let __col = { ...item };
        let attrcode = item.attrcode || item.key;
        let title = item.title || item.label;
        let className = (item.className ? item.className : ' ') + (item.unitActive ? ' column-header-active ' : ' ') + (item.itemtype === 'number' ? ' column-type-number ' : ' ');
        let config = { ...item, ...others, loginLanguage, renderStatus, __col, cellIcon, renderItem: item.renderItem || (others.renderItems || {})[attrcode], cellFieldId: getSysFieldid(attrcode) };
        return {
            ...item,
            required: false,
            // 列的className
            className: className,
            key: attrcode,
            redstar: item.required, //给列设置用
            dataIndex: item.dataIndex || item.attrcode,
            title: <Title {...item} title={title} {...others} attrcode={attrcode} />,
            render: (text, record, index) => {
                return !others.mergeCell ? <Cell
                    config={config}
                    record={record}
                    rowIndex={index}
                    text={text}
                /> : {
                    children: <Cell
                        config={config}
                        record={record}
                        rowIndex={index}
                        text={text}
                    />,
                    props: others.mergeCell ? others.mergeCell({ record, index, attrcode: item.attrcode }) : {}, // 合并单元格
                };
            },
        };
    });
}

/**
 * 创建标准化表格
 * @param {*} Table 
 */
export default function formatColumn(Table) {
    if (!Table) {
        console.error('缺少Table组件');
    }
    // TODO
    Table = ItemProvider(Table);
    return class MetaTable extends Component {
        constructor(props) {
            super(props);
        }

        render() {
            let {
                columns,
                langJson,
                getCellProps, setCellProps, onTableCellClick,
                getTrueRowIndex,
                onBeforeEvent, onAfterEvent,
                setCellValue, getCellValue,
                onCellStatusChange,
                rowKey,
                // name 和 tableId 可以合并成一个
                name,
                tableId,
                status,
                bodyDisplayInRow,
                bodyExpandAllInRow,
                cellClassName,
                mergeCell,
                store,
                onCellHyperlinkTo,
                cellIcon,
                renderItems,
                renderStatus,
                onCellKeyDown,
                onCellMouseDown,
                onCellFocus,
                disableEditType,
                fieldid,
                hotKeyWithArrow,
                onEnter,
                ...others
            } = this.props;
            return <div className="use-column-component" fieldid={getSysFieldid(`${fieldid || name}_table`)}>
                <Table
                    rowKey={record => {
                        return isObject(record[rowKey]) ? record[rowKey].value : record[rowKey]
                    }}
                    bodyDisplayInRow={bodyDisplayInRow}
                    bodyExpandAllInRow={bodyExpandAllInRow}
                    columns={createColumns(columns, {
                        getCellProps,
                        setCellProps,
                        onTableCellClick,
                        onBeforeEvent,
                        onAfterEvent,
                        onCellStatusChange,
                        setCellValue,
                        getCellValue,
                        getTrueRowIndex,
                        rowKey,
                        status,
                        bodyDisplayInRow,
                        bodyExpandAllInRow,
                        cellClassName,
                        mergeCell,
                        store,
                        onCellHyperlinkTo,
                        cellIcon,
                        renderItems,
                        renderStatus,
                        onCellKeyDown,
                        onCellMouseDown,
                        onCellFocus,
                        tableId,
                        langJson,
                        disableEditType,
                        hotKeyWithArrow,
                        onEnter,
                    })}
                    store={store}
                    tableId={tableId}
                    name={name}
                    langJson={langJson}
                    hotKeyWithArrow={hotKeyWithArrow}
                    onEnter={onEnter}
                    // 设置单元格样式
                    // getCellClassName={(record, index, column) => {
                    //     let { attrcode } = column;
                    //     // console.log(record, index, column);
                    //     return `-td-${attrcode}-${index}`;
                    // }}
                    {...others}
                />
            </div>
        }
    }
}
