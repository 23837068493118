/*
 * @Descripttion: 过滤
 * @version:
 * @Author: bbq
 * @Date: 2020-08-03 10:17:12
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-15 18:05:08
 */

import React, { Component, useEffect, useCallback, useState, useRef, useMemo, Fragment } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { getRandom, isUndefined, isFunction, isWrong, isArray } from '../../utils/index';
import { formatDatetime, changeTime, isNullOrVoid, formatNumber, formatAcuracy, getRandomPassword, getLangCode } from '@platform/api';
import CONFIG from '../../config';

const { ROW_STATUS } = CONFIG;

console.log(CONFIG);

import {
    Button,
    Modal,
    Select,
    Input,
    ButtonGroup,
} from '@platform/base';

import { getSysFieldid } from '@platform/api';

require('./panel.less');

const { Header, Title, Body, Footer } = Modal;
const { Option } = Select;

/**
 * 数据处理
 * @param {*} IType 
 * @param {*} display 
 * @param {*} value 
 * @param {*} scale 
 * @param {*} attrcode 
 * @param {*} languageMeta 
 * @param {*} values 
 * @param {*} datatype 
 * @param {*} options 
 * @param {*} multiple 
 * @param {*} langJson 
 */
const handleBrowse = function (IType, display, value, scale, attrcode, languageMeta, values, datatype, options, multiple, langJson, numFlag = false) {
    // TODO 数据格式的转换
    // 特殊处理下逻辑型  枚举类型
    if (
        datatype === "32" &&
        ["select", "checkbox", "radio"].includes(IType) &&
        isArray(options) &&
        !display &&
        value !== null &&
        value !== undefined
    ) {
        if (IType === "checkbox" || multiple) {
            let displayList = options
                .filter(item => value.includes(item.value))
                .map(option => {
                    return option.display;
                });
            return displayList.join(",");
        } else {
            let { display } =
                options.filter(item => item.value == value)[0] || {};
            return display;
        }
    }

    switch (true) {
        case CONFIG.displayTypes.includes(IType):
            return display || value;
        case CONFIG.boolean.includes(IType):
            return value == "Y" || value == true
                ? langJson["table_yes"]
                : langJson["table_no"];
        case CONFIG.timeTypes.includes(IType):
            if (IType === "datetimepicker") {
                return formatDatetime(changeTime(value, "YYYY-MM-DD HH:mm:ss"), 'datetime');
            } else if (IType === "timepicker") {
                return formatDatetime(value, 'time');
            } else if (IType === "datePickerNoTimeZone") {
                return formatDatetime(value, 'date');
            }
            return formatDatetime(changeTime(value), 'date');
        case IType === "label":
            return isNullOrVoid(display) ? value : display || value;
        case IType === "number":
            // 表格浏览态加了四舍五入
            if (numFlag) {
                return formatAcuracy(value, scale, true);
            }
            return formatNumber(formatAcuracy(value, scale), true);
        case IType === "password":
            // 密码框浏览态显示***
            return value && getRandomPassword();
        case IType === "residtxt": // 对多语的处理
            let LangCode = getLangCode();
            let loginLang = languageMeta.filter(i => i.languageCode == LangCode);
            if (loginLang.length > 0) {
                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                if (
                    values[attrcode + loginLang[0].index] &&
                    values[attrcode + loginLang[0].index].value
                ) {
                    value = values[attrcode + loginLang[0].index].value;
                    return value;
                }
                return value;
            }
            return value;
        default:
            return isNullOrVoid(display) ? value : display || value;
    }
}

/**
 * @description: 获取过滤后的数据
 * @param {type} 
 * @return {type} 
 */
const getFilterData = ({ allRows, filterFactor, customColData, rowKey, langJson }) => {
    return filterFactor.reduce(
        (currentFactor, factor) => {
            let {
                // 以下是模版字段和筛选条件
                attrcode,
                itemtype,
                factorValue,
                scale: metaScale,
                languageMeta = [],
                itemKey, // 生成的随机数，创建赋值
                datatype,
                options,
                multiple,
            } = factor;
            if (factorValue) {
                const { filteredRow, filteredRowKeyValues, filteredColumnKey } = currentFactor;
                allRows.forEach((row, index) => {
                    const { values, status } = row;
                    let rowKeyValue = row[rowKey];
                    // console.log(rowKey);
                    if (status !== ROW_STATUS.delete) {
                        let tableItemValue = '';
                        // 自定义列穿进来的数据判断
                        if (customColData && customColData[attrcode]) {
                            tableItemValue = customColData[attrcode][rowKeyValue];
                        } else {
                            // 不是自定义列的情况
                            if (values[attrcode]) {
                                // 精度判断数据和模版的情况
                                // tableItemValue = String(values[attrcode].display || values[attrcode].value);
                                tableItemValue = handleBrowse.call(
                                    this,
                                    itemtype,
                                    values[attrcode].display,
                                    values[attrcode].value,
                                    metaScale,
                                    attrcode,
                                    languageMeta,
                                    values,
                                    datatype,
                                    options,
                                    multiple,
                                    langJson,
                                    true
                                );
                                // console.log(tableItemValue, 'sssss', itemtype,
                                //     values[attrcode].display,
                                //     values[attrcode].value,
                                //     metaScale,
                                //     attrcode,
                                //     languageMeta,
                                //     values,
                                //     datatype,
                                //     options,
                                //     multiple, langJson);
                            }
                        }
                        // 为了不区分大小写统一将字符串转为小写
                        isWrong(tableItemValue) && (tableItemValue = '');
                        const LowerTableItemValue = String(tableItemValue).toLowerCase();
                        const LowerFactorValue = String(factorValue).toLowerCase();
                        if (
                            tableItemValue &&
                            LowerTableItemValue.includes(LowerFactorValue)
                        ) {
                            !filteredColumnKey.includes(itemKey) &&
                                filteredColumnKey.push(itemKey);
                            !filteredRowKeyValues.includes(rowKeyValue) && filteredRow.push(row);
                            !filteredRowKeyValues.includes(rowKeyValue) && filteredRowKeyValues.push(rowKeyValue);
                        }
                    }
                });
            }
            return currentFactor;
        },
        { filteredRow: [], filteredRowKeyValues: [], filteredColumnKey: [] },
    );
}

/**
 * 获取列模版的一维结构
 * @param columns 模版结构
 * @param finalFactorList 结果容器
 */
const getFactorList = ({ columns, finalFactorList }) => {
    return columns.reduce((currentValue, column) => {
        // 获取表格列配置
        const { children, visible } = column;
        if (!isUndefined(children)) {
            // 判断和并列的情况
            getFactorList({ columns: children, finalFactorList: currentValue });
            return currentValue;
        } else {
            visible && currentValue.push(column);
            return currentValue;
        }
    }, finalFactorList);
};

/**
 * 筛选条件录入项组件
 * @param {*} filterFactors 整体的筛选条件
 * @param {*} itemKey 筛选条件录入项组件标识
 * @param {*} filterItemList 筛选条件录入项组件配置参数列表
 * @param {*} setFilterItemList 筛选条件录入项组件标识列表更新方法
 * @param {*} afterFilterItemList 有效的筛选项列表
 * @param {*} factorList 所有项的筛选条件数据集合
 * @param {*} columns 可以被选择的模版项集合
 * @param {*} currentTable 表格数据实例
 * @param {*} langJson 多语
 */
const FilterItem = ({
    filterFactors: { current },
    itemKey,
    filterItemList,
    setFilterItemList,
    afterFilterItemList,
    factorList,
    columns,
    langJson,
}) => {
    /**
     * 当前筛选项的信息
     */
    const { selectedAttr, selectFcous, inputFocus } = filterItemList[itemKey];
    /**
     * 当前项的筛选条件
     */
    let echoInputValue = '';
    const currentFactor = current[itemKey];
    if (currentFactor) {
        const { factorValue } = currentFactor;
        factorValue && (echoInputValue = factorValue);
    }
    const [inputValue, setInputValue] = useState(echoInputValue);
    const [selectedValue, setSelectedValue] = useState(selectedAttr);

    /**
     * select列字段选择回调
     * @param {*} attr 列的字段
     */
    const handleSelectChange = attr => {
        const { current: afterFilterItemListCurrent } = afterFilterItemList;
        /**
         * 更新select的选项
         */
        filterItemList[itemKey].selectedAttr = attr;
        setSelectedValue(attr);
        /**
         * 设置列字段标题的筛选状态
         */
        const filteringList = Object.values(filterItemList).map(
            filterItem => filterItem.selectedAttr,
        );
        const filteredList = Object.values(afterFilterItemListCurrent).map(
            afterFilterItem => afterFilterItem.selectedAttr,
        );
        columns.forEach(column => {
            const { attrcode } = column;
            if (filteringList.includes(attrcode)) {
                column.filterStatus = 'filtering';
            } else {
                column.filterStatus = '';
            }
            if (filteredList.includes(attrcode)) {
                column.filterStatus = 'filtered';
            }
        });
        /**
         * 更改表格, 更新筛选标识
         */
        // tableScope.setState({ table: currentTable });
        /**
         * 当选择‘请选择’的时候,将当前筛选条件清空,pselect时请选择的意思
         */
        if (attr === 'pselect') {
            Reflect.deleteProperty(current, itemKey);
            return;
        }
        /**
         * 1.如果当前筛选项已经选择了筛选条件, 此时就将当前筛选条件和之前的筛选条件合并
         */
        if (currentFactor) {
            current[itemKey] = { ...currentFactor, ...factorList[attr] };
        } else {
            /**
             * 1.如果当前筛选项没有筛选条件, 此时就将当前筛选条件当作筛选条件
             */
            current[itemKey] = {
                ...factorList[attr],
                factorValue: inputValue,
                itemKey,
            };
        }
    };

    /**
     * input输入条件内容回调
     * @param {*} value 输入的内容
     */
    const handleInputChange = value => {
        /**
         * 1.如果当前筛选项已经选择了筛选条件, 才给条件添加筛选内容
         */
        if (currentFactor) {
            current[itemKey] = { ...currentFactor, factorValue: value, itemKey };
            /**
             * 更新input的值
             * 更新筛选项的渲染配置的值
             * pselect时请选择的意思
             */
            setInputValue(value);
        } else if (selectedValue !== 'pselect') {
            /**
             * 1.当前选择的条件不是‘请选择’时, 添加当前项的筛选条件
             */
            current[itemKey] = {
                ...factorList[selectedValue],
                factorValue: value,
                itemKey,
            };
            /**
             * 更新input的值
             * 更新筛选项的渲染配置的值
             */
            setInputValue(value);
        }
    };
    /**
     * 删除按钮回调
     */
    const handleDel = () => {
        /**
         * 删除当前项
         */
        Reflect.deleteProperty(filterItemList, itemKey);
        /**
         * 删除当前项的筛选条件
         */
        Reflect.deleteProperty(current, itemKey);
        /**
         * 更新筛选标识
         */
        const { current: afterFilterItemListCurrent } = afterFilterItemList;
        const filteringList = Object.values(filterItemList).map(
            filterItem => filterItem.selectedAttr,
        );
        const filteredList = Object.values(afterFilterItemListCurrent).map(
            afterFilterItem => afterFilterItem.selectedAttr,
        );
        columns.forEach(column => {
            const { attrcode } = column;
            if (!filteringList.includes(attrcode)) {
                column.filterStatus = '';
            }
            if (filteredList.includes(attrcode)) {
                column.filterStatus = 'filtered';
            }
        });
        /**
         * 更新筛选标识, 和筛选项
         */
        // tableScope.setState({ table: currentTable });
        setFilterItemList({ ...filterItemList });
    };

    return (
        <div className="filter-item-container">
            <div className="select-container">
                <Select
                    showClear={false}
                    autoFocus={selectFcous}
                    value={selectedValue}
                    onChange={handleSelectChange}
                >
                    {/* 请选择 */}
                    {[{ attrcode: 'pselect', label: langJson['table0026'] }, ...columns].map(
                        column => {
                            const { attrcode, label, title } = column;

                            let option = title || label;

                            if (isFunction(option)) {
                                console.warn('the column label is Function , maybe error')
                                option = option({});
                            }
                            return <Option key={attrcode} value={attrcode}>{option}</Option>;
                        },
                    )}
                </Select>
            </div>
            <div className="input-container">
                <Input
                    value={inputValue}
                    disabled={selectedValue === 'pselect'}
                    onChange={handleInputChange}
                    // maxLength={20}
                    autoFocus={inputFocus}
                />
            </div>
            <i className="iconfont nc-theme-icon-font-c icon-shanchu" onClick={handleDel} />
        </div>
    );
};

/**
 * 筛选面板组件
 * @param {*} tableColumns  表格模版信息
 * @param {*} tableScope 表格实例
 * @param {*} tableName 表格名称
 * @param {*} langJson 多语
 * @param {*} handleResetCallBack 重置后回调
 * @param {*} handleFilterCallBack 筛选后回调
 * 
 */
const FilterPanel = ({
    tableColumns,
    tableScope,
    filterData,
    customColData,
    rowKey,
    // 当前选择的列
    selectedAttr,
    // 当前列的状态 
    filterStatus,
    // 是否重置后渲染
    isReset,
    // 重置后回调
    handleResetCallBack,
    // 筛选后回调
    handleFilterCallBack,
    tableName,
    langJson,
    open = false,
    onHide,
}) => {
    /**
     * FilterPanel的真实dom
     */
    let FilterPanelRef = null;
    /**
     * 保存整体的筛选条件
     */
    const filterFactors = useRef({});
    /**
     * 筛选后回显筛选项配置列表
     */
    const afterFilterItemList = useRef({});
    /**
     * 筛选后回显筛选项的筛选条件列表
     */
    const afterFilterFactors = useRef({});

    /**
     * 筛选条件录入项组件配置参数列表, 通过这个列表渲染录入项组件
     */
    const [filterItemList, setFilterItemList] = useState({});
    /**
     * 列筛选状态
     */
    // const [columnStatus, setColumnStatus] = useState({});
    /**
     * 当前筛选列
     */
    const [current, setCurrent] = useState({ selectedAttr, filterStatus });
    /**
     * 模态框的状态, 默认隐藏
     */
    // const [panelStatus, setPanelStatus] = useState(open);
    // 改为始终受控

    /**
     * 模态框的提示
     */
    const [filterTips, setFilterTips] = useState('');

    /**
     * 将模版处理成一维的
     */
    const columns = useMemo(
        () => getFactorList({ columns: tableColumns, finalFactorList: [] }),
        [tableColumns],
    );
    /**
     * 将模版数据处理,整理为每种类型的的筛选条件
     */
    const factorList = useMemo(
        () => columns.reduce((currentValue, column) => {
            const { attrcode, itemtype, languageMeta, scale, datatype, options, multiple } = column;
            currentValue[attrcode] = { attrcode, itemtype, languageMeta, scale, datatype, options, multiple };
            return currentValue;
        }, {}),
        [columns],
    );

    /**
     * 每次弹开重新渲染
     * 关闭弹窗回调
     */
    const handleHide = () => {
        // 设置选择项
        filterFactors.current = {};

        // 清除不符合的过滤项
        setFilterItemList({}); // 不清空 减少更新
        // 清空提示信息
        filterTips.length && setFilterTips('');

        // 这里更新状态的顺序会导致  弹窗可能不能正确销毁
        setTimeout(() => {
            // 关闭弹窗
            onHide && onHide({ filterItemList, afterFilterItemList, arrColumns: columns });
        }, 20);
    };

    /**
     * 以前是可以在外部直接调用的  现在不允许 外部的条件要传入尽量才行 然后执行默认筛选
     * 筛选按钮回调
     * @filterFactor 筛选条件 用于在卡片返回列表时重新筛选使用
     * @filterResultItem 是筛选结果项
     */
    const handleFilter = (filterFactor, filterResultItem) => {
        // 如果传入筛选条件,那就使用传入的
        filterFactor && (filterFactors.current = filterFactor);
        filterFactor && (afterFilterFactors.current = filterFactor);
        filterResultItem && (afterFilterItemList.current = filterResultItem);
        const { current: filterFactorsCurrent } = filterFactors;
        const { current: afterFilterItemListCurrent } = afterFilterItemList;
        const { current: afterFilterFactorsCurrent } = afterFilterFactors;

        // 筛选并更新表格显示数据
        const { filteredRow, filteredRowKeyValues, filteredColumnKey } = getFilterData(
            {
                // 筛选原数据, 去除筛选api可能添加的filterindex
                allRows: filterData.map(row => {
                    if (row.filterIndex) {
                        delete row.filterIndex;
                    }
                    return row;
                }),
                // 筛选条件
                filterFactor: Object.values(filterFactorsCurrent),
                // 自定义列传入数据
                customColData,
                rowKey,
                langJson,
            });

        // 判断筛选结果
        console.log(filteredRow, filteredRowKeyValues, filteredColumnKey);
        if (filteredRow.length) {
            // 清空提示
            setFilterTips('');

            // 存储有效的筛选项和筛选条件列表
            const {
                newAfterFilterItemListCurrent,
                newAfterFilterFactorsCurrent,
            } = filteredColumnKey.reduce(
                (currentValue, attr) => {
                    const {
                        newAfterFilterItemListCurrent,
                        newAfterFilterFactorsCurrent,
                    } = currentValue;
                    if (afterFilterItemListCurrent[attr]) {
                        newAfterFilterItemListCurrent[attr] = afterFilterItemListCurrent[attr];
                        newAfterFilterFactorsCurrent[attr] = afterFilterFactorsCurrent[attr];
                    } else {
                        newAfterFilterItemListCurrent[attr] = filterItemList[attr];
                        newAfterFilterFactorsCurrent[attr] = filterFactorsCurrent[attr];
                    }
                    return currentValue;
                },
                { newAfterFilterFactorsCurrent: {}, newAfterFilterItemListCurrent: {} },
            );

            // 缓存筛选条件
            afterFilterItemList.current = newAfterFilterItemListCurrent;
            afterFilterFactors.current = newAfterFilterFactorsCurrent;

            // 修改列上面的状态
            const filteringList = Object.values(filterItemList).map(
                filterItem => filterItem.selectedAttr,
            );
            const filteredList = Object.values(newAfterFilterItemListCurrent).map(
                afterFilterItem => afterFilterItem.selectedAttr,
            );
            columns.forEach(column => {
                const { attrcode } = column;
                if (filteredList.includes(attrcode)) {
                    column.filterStatus = 'filtered';
                } else if (!filteringList.includes(attrcode)) {
                    column.filterStatus = '';
                }
            });

            // 设置列状态
            // 设置行状态
            // 处理已选等逻辑
            handleFilterCallBack && handleFilterCallBack({
                filteredRow,
                filterItemList,
                filteredRowKeyValues,
                afterFilterItemList: newAfterFilterItemListCurrent,
                afterFilterFactors: newAfterFilterFactorsCurrent,
                arrColumns: columns,
            });

            // 关闭弹窗
            handleHide();
        } else {
            // '没有找到，换个词试一试'
            setFilterTips(langJson['table0027']);
        }
    };

    /**
     * enter执行筛选的快捷键
     */
    const enterCallBack = e => {
        if (e && e.keyCode == 13) {
            // enter 键
            handleFilter();
        }
    };

    /**
     * 模态框打开结束时回调
     */
    const handleEntered = () => {
        FilterPanelRef.childNodes[1]
            .querySelector('.modal-content-parent,.modal-content-resizeWrap-parent')
            .addEventListener('keydown', enterCallBack);
        setFilterItemList({ ...filterItemList });
    };

    /**
     * 模态框关闭结束时回调
     */
    const handleExited = () => {
        FilterPanelRef.childNodes[1]
            .querySelector('.modal-content-parent,.modal-content-resizeWrap-parent')
            .removeEventListener('keydown', enterCallBack);
    };

    /**
     * 添加按钮回调
     */
    const handleAdd = () => {
        /**
         * 新增筛选条件录入项组件的标识, 新增筛选项
         */
        let key = getRandom();
        Object.values(filterItemList).forEach(filterItem => {
            filterItem.selectFcous = false;
            filterItem.inputFocus = false;
        });
        filterItemList[key] = {
            key,
            selectedAttr: 'pselect',
            selectFcous: true,
            inputFocus: false,
        };
        setFilterItemList({ ...filterItemList });
    };

    /**
     * 清空按钮回调
     */
    const handleClear = () => {
        // 清空筛选项
        let key = getRandom();
        setFilterItemList({
            [key]: {
                key,
                selectedAttr: 'pselect',
                selectFcous: true,
                inputFocus: false,
            },
        });
        // 清空筛选条件
        filterFactors.current = {};
        // 设置表格筛选图标
        const filteringList = Object.values(filterItemList).map(
            filterItem => filterItem.selectedAttr,
        );
        columns.forEach(column => {
            const { attrcode } = column;
            if (filteringList.includes(attrcode)) {
                column.filterStatus === 'filtering' && (column.filterStatus = '');
            }
        });
        // 不更新表格
        // tableScope.setState({ table: currentTable });
    };

    /**
     * 清空表格筛选的标识
     */
    const handleClearFilterStatus = () => {
        columns.forEach(column => {
            column.filterStatus = '';
        });
        tableScope.filterData = null;
        afterFilterItemList.current = {};
        afterFilterFactors.current = {};

        // 去除 其他逻辑放到外部 store去处理
        // handleResetCallBack && handleResetCallBack({
        //     filterItemList,
        //     arrColumns: columns,
        // });
    };

    /**
     * 重置按钮回调
     */
    const handleReset = () => {
        /**
         * 清空筛选后回显筛选项配置列表
         */
        afterFilterItemList.current = {};
        /**
         * 清空筛选后回显筛选项的筛选条件列表
         */
        afterFilterFactors.current = {};

        // 设置表格筛选图标, 重置时将筛选图标全部清空
        columns.forEach(column => {
            column.filterStatus = '';
        });

        handleResetCallBack && handleResetCallBack({
            filterItemList,
            arrColumns: columns,
        });
        /**
         * 关闭弹窗
         */
        handleHide();
    };

    /**
     * CtrlF打开弹窗回调
     */
    const handleCtrlFOpen = () => {
        let finalFilterItemList = null;
        let { current: afterFilterFactorsCurrent } = afterFilterFactors;
        let { current: afterFilterItemListCurrent } = afterFilterItemList;

        if (Object.keys(afterFilterItemListCurrent).length) {
            /**
             * 渲染缓存的有效的筛选项和筛选条件
             */
            filterFactors.current = afterFilterFactorsCurrent;
            finalFilterItemList = afterFilterItemListCurrent;
            setFilterItemList(finalFilterItemList);
        } else {
            /**
             * 当没有有效的筛选项和筛选条件时
             */
            let key = getRandom();
            Object.values(filterItemList).forEach(filterItem => {
                filterItem.selectFcous = false;
                filterItem.inputFocus = false;
            });
            filterItemList[key] = {
                key,
                selectedAttr: 'pselect',
                selectFcous: true,
                inputFocus: false,
            };
        }
    };

    /**
     * 点击表头打开弹窗回调
     * 这里包含三种情况, 
     * 1.直接点击表头,弹出筛选框增一个筛选条件
     * 2.筛选成功点击成功图标,重新筛选, 如果只筛选了一项那个进行重置
     * 3.筛选成功, 点击表头其他区域, 弹出筛选框
     * 
     * @param selectedAttr 当前列
     * @param filterStatus 筛选状态
     */
    const handleHeaderOpen = ({ selectedAttr, filterStatus }) => {
        let key = getRandom();
        let finalFilterItemList = null;
        let { current: filterFactorsCurrent } = filterFactors;
        let { current: afterFilterFactorsCurrent } = afterFilterFactors;
        let { current: afterFilterItemListCurrent } = afterFilterItemList;
        if (Object.keys(afterFilterItemListCurrent).length) {
            /**
             * 已经筛选过的情况下, 点击表头不点击筛选图标, 只是弹出筛选框,
             * 并且保存原有筛选项, 不增加筛选项
             */
            if (filterStatus === 'filtered') {
                handleCtrlFOpen();
                return;
            }
            /**
             * 具有有效的筛选项, 已经筛选出结果
             */
            const allocationSelectedAttrs = Object.values(
                afterFilterItemListCurrent,
            ).filter(filterItem => {
                filterItem.selectFcous = false;
                filterItem.inputFocus = false;
                return filterItem.selectedAttr === selectedAttr;
            });

            if (allocationSelectedAttrs.length) {
                /**
                 * 当前点击列,为有效的筛选项, 将当前项筛选条件去掉重新筛选,
                 * 如果去掉当前筛选条件后没有了筛选条件,执行重置操作
                 */
                allocationSelectedAttrs.forEach(filterItem => {
                    const { key } = filterItem;
                    Reflect.deleteProperty(filterItemList, key);
                    Reflect.deleteProperty(filterFactorsCurrent, key);
                    Reflect.deleteProperty(afterFilterFactorsCurrent, key);
                    Reflect.deleteProperty(afterFilterItemListCurrent, key);
                });
                filterFactors.current = afterFilterFactorsCurrent;
                finalFilterItemList = afterFilterItemListCurrent;
                if (Object.values(afterFilterItemListCurrent).length) {
                    // 再次进行筛选
                    handleFilter();
                    return;
                } else {
                    // 进行重置
                    handleReset();
                    return;
                }
            } else {
                /**
                 * 当前点击列,不是有效的筛选项, 增加一个空筛选项
                 */
                filterFactors.current = afterFilterFactorsCurrent;
                finalFilterItemList = {
                    ...afterFilterItemListCurrent,
                    [key]: {
                        key,
                        selectedAttr,
                        selectFcous: false,
                        inputFocus: true,
                    },
                };
                /**
                 * 设置表格筛选图标
                 */
                columns.forEach(column => {
                    const { attrcode } = column;
                    if (selectedAttr === attrcode) {
                        // 解决  多页签情况下  列缓存被清除的问题
                        column.filterStatus = column.filterStatus || 'filtering';
                    }
                });
            }
            setFilterItemList({ ...finalFilterItemList });
        } else {
            /**
             * 没有有有效的筛选项, 没有筛选出结果, 增加一个空筛选项
             */
            Object.values(filterItemList).forEach(filterItem => {
                filterItem.selectFcous = false;
                filterItem.inputFocus = false;
            });
            filterItemList[key] = {
                key,
                selectedAttr,
                selectFcous: false,
                inputFocus: true,
            };
            /**
             * 设置表格筛选图标
             */
            columns.forEach(column => {
                const { attrcode } = column;
                if (selectedAttr === attrcode) {
                    // 解决  多页签情况下  列缓存被清除的问题
                    column.filterStatus = column.filterStatus || 'filtering';
                }
            });

        }
        /**
         * 1.打开弹窗
         */
        // setPanelStatus(true);
    };

    // 更新下
    useEffect(() => {
        console.log(isReset, 'isReset');
        isReset && handleClearFilterStatus();
    }, [isReset])

    // 更新下
    useEffect(() => {
        console.log(selectedAttr, filterStatus, 'currentFilter');
        selectedAttr && handleHeaderOpen({ selectedAttr, filterStatus });
    }, [selectedAttr, filterStatus])

    // 给筛选按钮事件, 设置默认值, 解决报错
    const handleFilterClick = handleFilter.bind(null, null, null);

    return (
        <Modal
            ref={dom => {
                FilterPanelRef = ReactDOM.findDOMNode(dom);
            }}
            size="lg"
            zIndex={800}
            show={open}
            backdrop={true} //是否展示遮罩层
            onHide={handleHide.bind(null)}
            backdropClosable={true}
            onEntered={handleEntered}
            onExited={handleExited}
            draggable={true}
            resizable={true}
            className="filterPanel-modal"
            fieldid="filterPanel"
        >
            <Header closeButton>
                <Title fieldid={`${langJson['table0028']}${tableName || ''}`}>
                    {`${langJson['table0028']}${tableName || ''}:`}
                    <span className={filterTips ? 'table-filter-tips' : 'filter-tips-hidden'}>
                        <span className="iconfont icon icon-gantanhao" />{filterTips}
                    </span>
                </Title>
            </Header>
            <Body>
                {Object.values(filterItemList).map(value => {
                    const { key } = value;
                    return (
                        <FilterItem
                            key={getRandom()}
                            itemKey={key}
                            afterFilterItemList={afterFilterItemList}
                            filterFactors={filterFactors}
                            filterItemList={filterItemList}
                            factorList={factorList}
                            columns={columns}
                            tableScope={tableScope}
                            currentTable={{}}
                            setFilterItemList={setFilterItemList}
                            langJson={langJson}
                        />
                    );
                })}
                <div className="add-btn-container">
                    <div className="add-btn" fieldid={getSysFieldid('add_btn')} onClick={handleAdd}>
                        {/* 添加条件 */}
                        {`+${langJson['table0029']}`}
                    </div>
                    {/* 清空 */}
                    <Button onClick={handleClear} fieldid="clear" >{langJson['table0030']}</Button>
                </div>
            </Body>
            <Footer>
                <ButtonGroup>
                    <Button
                        className="filter-btn"
                        fieldid="filter"
                        colors="primary"
                        onClick={handleFilterClick}
                    >
                        {/* 筛选 */}
                        {langJson['table0028']}
                    </Button>
                    <Button className="reset-btn" fieldid="reset" onClick={handleReset}>
                        {/* 重置 */}
                        {langJson['table0031']}
                    </Button>
                </ButtonGroup>
                {/* 取消 */}
                <Button onClick={handleHide.bind(null)} fieldid="cancel" >{langJson['table_cancel']}</Button>
            </Footer>
        </Modal>
    );
};

export default FilterPanel;