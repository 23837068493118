/*
 * @Descripttion: 行选中的逻辑整理
 * @version:
 * @Author: bbq
 * @Date: 2020-06-15 15:30:43
 * @LastEditors: bbq
 * @LastEditTime: 2020-07-30 13:43:55
 */

import React, { Component, Fragment } from "react";

import { isFunction, isArray, ViewModel } from "@platform/api";

import "./index.less";

let { getGlobalStorage, setGlobalStorage } = ViewModel;

/**
 * 行选中逻辑处理
 * @param {*} Table
 */
export default function hoverContent(Table) {
    if (!Table) {
        console.error("缺少Table组件");
    }
    return class MetaTable extends Component {
        constructor(props) {
            super(props);
            // setGlobalStorage('localStorage', 'btnRenderModel', 'hover');
        }

        render() {
            let {
                columns,
                totalColumns,
                hoverContent = getGlobalStorage("localStorage", "btnRenderModel"), // true
                ...others
            } = this.props;

            let oprColumn = null;

            if (hoverContent) {
                // 浅拷贝一下，避免出问题
                columns = [...columns];
                columns.forEach((item, index) => {
                    if (
                        (item.attrcode === "opr" || item.attrcode === 'operate') &&
                        item.itemtype === "customer" &&
                        item.render
                    ) {
                        oprColumn = item;
                        // 移除下
                        columns.splice(index, 1);
                        // 转单
                        totalColumns && totalColumns.splice(index, 1);
                    }
                });
            }

            return (
                <Table
                    columns={columns}
                    totalColumns={totalColumns}
                    hoverContent={
                        oprColumn && hoverContent
                            ? (record, index) => {
                                console.log(record, index);
                                return (
                                    <div className="hover-content">
                                        {record ? oprColumn.render("", record, index) : ""}
                                    </div>
                                );
                            }
                            : undefined
                    }
                    {...others}
                />
            );
        }
    };
}
