import React from 'react';
import { Tooltip } from '@platform/base'
import { isFunction, isWrong, formatDot, isBooleanType, formatAcuracy, isUndefined, convertNum, formatNumber } from '@platform/api'
import { getSysFieldid, sum } from '@platform/api';
import './index.less'

export function getTotalData(totalColumns, rows, config = {}, totalScale = {}) {
    let _this = this;
    let totalDataObj = {}; //合计行的数据
    let isTotal = []; //缓存模版中需要合计的列
    totalColumns.forEach(eve => {
        const { itemtype } = eve;
        totalDataObj[eve.key] = null; //给合计行数据设置模版上visible===true 的项的attrcode，作为属性
        totalDataObj[`${eve.key}_scale`] = -1; // 给每列存储一个最小精度，在遍历后存储较大的精度值
        if (eve.istotal && itemtype === 'number') {
            //收集模版中需要合计的列
            isTotal.push(eve.key);
        }
    });
    const { noTotalRows, noTotalCol = [], showTotal, allowTotalRows } = config; //  业务组传入设置不需要合计的行和列 和是否展示从左列

    // 将这个不让合计的行的这个提到外面来，减少循环
    let noTotalCode = {};
    if (isFunction(noTotalRows)) {
        if (
            Array.isArray(noTotalRows.call(_this)) &&
            noTotalRows.call(_this).length > 0
        ) {
            noTotalRows.call(_this).forEach(eve => {
                const { code, indexArr } = eve;
                //获取当前前行不需要合计的属性
                noTotalCode[indexArr] = code;
            });
        }
    }
    // 业务组自己注册进来的需要合计的行提出来，解决效率问题
    let allowTotal = [];
    if (isFunction(allowTotalRows)) {
        allowTotal = allowTotalRows.call(_this);
    }

    rows.forEach((eve, index) => {
        /*业务组自己注册进来的需要合计的行*/
        if (isFunction(allowTotalRows)) {
            if (!allowTotal.includes(index)) {
                return;
            }
        }
        let values = eve.values || eve
        Object.keys(values).forEach(key => {

            const { value, scale } = values[key] || {};
            if (
                !isWrong(value) &&
                !isBooleanType(value) &&
                !Number.isNaN(Number(value))
            ) {
                if (
                    (Object.keys(noTotalCode).length < 1 ||
                        (Object.keys(noTotalCode).length > 0 &&
                            (!noTotalCode[index] || !noTotalCode[index].includes(key)))) &&
                    key !== 'numberindex' &&
                    !noTotalCol.includes(key)
                ) {
                    const finalScale = Number(!isWrong(scale) && scale != "-1" ? scale : (totalScale[key] || 8));
                    const scaleNum = finalScale > totalDataObj[`${key}_scale`] ? finalScale : totalDataObj[`${key}_scale`];
                    totalDataObj[`${key}_scale`] = scaleNum;
                    // showTotal  意味着要进行外部控制  如果不使用 noTotalCol 控制列 会导致所有列都会计算合计
                    if (showTotal) {
                        //不是通过模版设置展示合计行时
                        totalDataObj[key] = sum(formatDot(totalDataObj[key] || 0, scaleNum), formatDot(convertNum(value), scaleNum));
                    } else {
                        //通过模版设置展示合计行时
                        if (isTotal.includes(key)) {
                            //判断是否需要合计
                            totalDataObj[key] = sum(formatDot(totalDataObj[key] || 0, scaleNum), formatDot(convertNum(value), scaleNum));
                        }
                    }
                }
            }
        });
    });
    return [totalDataObj];
}

export function getTotalScale(totalColumns, rows) {
    let totalScaleObj = {}; //收集合计每格精度
    totalColumns.forEach(item => {
        //便利合计行所有列
        let scale = 0;
        rows.forEach(eve => {
            let values = eve.values || eve
            //便利表格所有数据
            let valueItem = values[item.key]; //缓存每格数据
            if (
                valueItem &&
                !isWrong(valueItem.value) &&
                !isWrong(valueItem.scale) &&
                valueItem.scale != -1 &&
                Number(valueItem.scale) > scale
            ) {
                //当数据中的精度大于当前缓存的精度， 覆盖当前缓存精度
                scale = Number(valueItem.scale);
            } else if (
                valueItem &&
                !isWrong(valueItem.value) &&
                (isWrong(valueItem.scale) || valueItem.scale == '-1')
            ) {
                //当数据中没有精度并且模版设置了精度，覆盖当前缓存精度
                let itemScale = item.scale ? Number(item.scale) : 0;
                if (itemScale > scale) {
                    scale = itemScale;
                }
            } //模版的精短暂时不判断，有需求在说
        });
        totalScaleObj[item.key] = scale; //存储每格最终精度
    });
    return totalScaleObj;
}

export function getFinalTotalData(totalData, totalScale) {
    Object.keys(totalData).forEach(eve => {
        //便利合计行数据的属性
        // if (isNumber(totalData[eve])) {
        if (eve != 'checkbox' && eve != 'numberindex') {
            //判断合计行每格数据类型，如果为数字，进行精度转化
            totalData[eve] = formatAcuracy(totalData[eve], totalScale[eve]); //转换精度重新赋值
        }
    });
    return [totalData];
}

export function getTotalColumns(columns, config = {}) {
    let visibleColumns = []
    filterVisible(columns, visibleColumns, config)

    return visibleColumns
}

function filterVisible(columns, newColumns, config) {
    let numberPd = (config.numberPd || 20);
    columns.forEach(item => {
        const { children, visible, attrcode, width, fixed, istotal, scale, itemtype, islock } = item
        if (!isUndefined(children) && children.length) {
            if (visible) {
                filterVisible(children, newColumns, config)
            }
        } else {
            let finalFixed = (islock && !fixed) ? 'left' : fixed
            if (visible) {
                let colItem = {
                    key: attrcode,
                    dataIndex: attrcode,
                    attrcode,
                    itemtype,
                    width,
                    fixed: finalFixed,
                    istotal,
                    scale,
                };
                if (attrcode === 'opr' && !width) {//如果操作列没有值，则付200px宽度
                    colItem.width = 200;
                }
                //合计行中数字类型，右对齐
                if (attrcode !== 'checkbox' && attrcode !== 'numberindex') {
                    colItem.render = (text, record) => {
                        // let iWidth = 'calc(100% - 46px)';
                        // 三大表与 转单 树表等不一样
                        let iWidth = 'calc(100% - ' + numberPd + 'px)';
                        let styleObj = { textAlign: 'right', width: iWidth };
                        return record[attrcode] ? (
                            <div className="total-row-table-cell" style={styleObj} fieldid={getSysFieldid(attrcode)} >
                                < Tooltip
                                    className="tooltip-word-color table-pagination-tip"
                                    placement="top"
                                    delay={1}
                                    overlay={record[attrcode]}
                                >
                                    <span className="ellipsis">{formatNumber(record[attrcode])}</span>
                                </Tooltip >
                            </div >
                        ) : (<span> &nbsp;</span>)
                    };
                }
                newColumns.push(colItem)
            }
        }
    })
}

//空白列
export const addBlankCol = (tableDom, columns) => {
    if (tableDom && columns.length) {
        let mulHeaderFlag = false,
            blankrowFlag = false;
        //最后一列是操作列，且不是固定列的flag
        let oprNotFixFlag = false;
        let attrcodeArr = columns.map(item => {
            if (item.children) {
                mulHeaderFlag = true;
            }
            if (item.attrcode == 'opr' && !item.fixed) {
                oprNotFixFlag = true;
            }
            if (item.attrcode == 'blankrow') {
                blankrowFlag = true;
            }
            return item.attrcode;
        });
        if (!mulHeaderFlag && !oprNotFixFlag && !blankrowFlag) {
            let columnsWidth = 0;
            let isopr = false;
            if (attrcodeArr[attrcodeArr.length - 1] !== 'opr') {
                for (let i = 0; i < columns.length - 1; i++) {
                    switch (typeof columns[i].width) {
                        case 'undefined':
                            columnsWidth = columnsWidth + 120;
                            break;
                        case 'number':
                            columnsWidth = columnsWidth + columns[i].width;
                            break;
                        case 'string':
                            let a = columns[i].width;
                            columnsWidth = Number(a.replace(/px/, '')) + columnsWidth;
                            break;
                    }
                }
            } else {
                isopr = true;
                for (let j = 0; j < columns.length; j++) {
                    if (j !== columns.length - 2) {
                        switch (typeof columns[j].width) {
                            case 'undefined':
                                columnsWidth = columnsWidth + 120;
                                break;
                            case 'number':
                                columnsWidth = columnsWidth + columns[j].width;
                                break;
                            case 'string':
                                let w = columns[j].width;
                                columnsWidth = Number(w.replace(/px/, '')) + columnsWidth;
                                break;
                        }
                    }
                }
            }
            let lastColWidth = tableDom.getBoundingClientRect().width - columnsWidth;
            if (lastColWidth > 300) {
                blankrowFlag = true;
                let obj = {
                    title: '',
                    key: 'blankrow',
                    dataIndex: 'blankrow',
                    attrcode: 'blankrow',
                    className: 'blankrow',
                    visible: true,
                    itemtype: 'customer',
                    width: 20,
                    render: () => <div />,
                };
                if (isopr) {
                    columns.splice(columns.length - 1, 0, obj);
                } else {
                    columns.push(obj);
                }
            }
        }
    }
    return columns
};
