
import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import { isFunction, isArray, handleBrowseData } from '../../utils';
import classnames from "classnames";
import { NumberInput, HotKeys } from "@platform/base";
import './index.less';


export default function contextMenu(Table) {
    return class ContextRightMenu extends Component {
        constructor(props) {
            super(props);

            this.pasteCell = {
                rowIndex: -1,
                attrcode: '',
                column: {},
            };

            //需要业务适配点击事件
            this.editBussMenu = ['insert_up', 'insert_down', 'delete'];
            this.copyMenu = ['copy-row', 'copy-column'];
            this.insert_up = 0;
            this.insert_down = 0;
            this.wrapper = null;
        }

        //执行复制
        execCommandCopy = (btnCode, activeHeads, currentMeta, activedCells, selectedRows, arrayMeta) => {
            let copyText = this.getTableContent(btnCode, activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
            //执行复制事件
            if (this.wrapper) {
                let textElement = ReactDOM.findDOMNode(this.wrapper).querySelector("#table-copy-element");
                textElement.value = copyText; // 修改文本框的内容
                textElement.select(); // 选中文本
                document.execCommand("copy"); // 执行浏览器复制命令 
            }

        }

        getTableContent = (btnCode, activeHeads, currentMeta, activedCells, selectedRows, arrayMeta) => {
            //复制选中内容
            let copyContent = '';
            //选中表头
            if (activeHeads && activeHeads.length) {
                //多表头也是一个数组
                copyContent = copyContent + this.getHeadContent(activeHeads, currentMeta, btnCode, arrayMeta);
            }
            //选中表体
            if (activedCells) {
                let rowKeyValues = Object.keys(activedCells);
                if (rowKeyValues && rowKeyValues.length) {
                    //此处 activedCells必须是数组，因为需要按照行的顺序复制
                    copyContent = copyContent + this.getBodyContent(selectedRows, activedCells, btnCode, arrayMeta);
                }
            }
            return copyContent;
        }

        //获取选中表头数据 
        getHeadContent = (activeHeads, currentMeta, btnCode) => {
            //activeHeads: [{attrcode1,lable1},{attrcode2,label2}]
            //arrayMeta拉平数据
            let copyContent = '';

            let array = [];
            let start = 0;
            let maxLength = 0;

            let uniqueFlag = {};

            let arrayMeta = [];
            TraverNode(currentMeta, []);

            for (let i = 0; i < arrayMeta.length; i++) {
                let item = arrayMeta[i];
                let parentNode = item.parentNode;
                if (!item.visible) continue;
                //判断叶子节点
                let hasSel = false;
                if (btnCode === 'copy' || btnCode === 'copy-column') {
                    let index = activeHeads.findIndex((e) => e.attrcode === item.attrcode);
                    //选中节点包含叶子节点
                    if (index !== -1) {
                        array[start] = [item]
                        hasSel = true;
                        //判断父节点
                        if (parentNode && parentNode.length) {
                            //若为复制 需要判断是否选中
                            if (btnCode === 'copy') {
                                for (let j = parentNode.length - 1; j >= 0; j--) {
                                    let index = activeHeads.findIndex((e) => e.attrcode === parentNode[j].attrcode);
                                    if (index !== -1) {
                                        if (!array[start]) array[start] = [];
                                        if (!uniqueFlag[activeHeads[index].attrcode]) {
                                            uniqueFlag[activeHeads[index].attrcode] = true;
                                            array[start].push(activeHeads[index]);
                                        } else {
                                            array[start].push(' ');
                                        }
                                        hasSel = true;
                                    }
                                }
                                //若为复制列 不需要判断，直接所有父元素全部复制
                            } else if (btnCode === 'copy-column') {
                                if (!array[start]) array[start] = [];
                                for (let j = parentNode.length - 1; j >= 0; j--) {
                                    if (!uniqueFlag[parentNode[j].attrcode]) {
                                        uniqueFlag[parentNode[j].attrcode] = true;
                                        array[start].push(parentNode[j]);
                                    } else {
                                        array[start].push('');
                                    }
                                }
                                hasSel = true;
                            }
                        }
                        //选中节点不含叶子节点
                    } else {
                        //判断是否含父节点
                        if (parentNode && parentNode.length) {
                            //记录第一个被选中的父节点
                            let firstIndex = -1;
                            for (let j = parentNode.length - 1; j >= 0; j--) {
                                let index = activeHeads.findIndex((e) => e.attrcode === parentNode[j].attrcode);
                                if (index !== -1) {
                                    hasSel = true;
                                    if (btnCode === 'copy') {
                                        if (!array[start]) array[start] = [];
                                        if (!uniqueFlag[activeHeads[index].attrcode]) {
                                            uniqueFlag[activeHeads[index].attrcode] = true;
                                            array[start].push(activeHeads[index]);
                                        } else {
                                            array[start].push('');
                                        }

                                        if (j < parentNode.length - 1 && firstIndex !== -1) {
                                            firstIndex = parentNode.length - 1 - j;
                                            // 填充叶子节点
                                            array[start].unshift(...Array(firstIndex).fill(''));
                                        }
                                    }
                                }
                            }
                            if (hasSel && btnCode === 'copy-column') {
                                array[start] = [item]
                                for (let j = parentNode.length - 1; j >= 0; j--) {
                                    if (!uniqueFlag[parentNode[j].attrcode]) {
                                        uniqueFlag[parentNode[j].attrcode] = true;
                                        array[start].push(parentNode[j]);
                                    } else {
                                        array[start].push('');
                                    }
                                }
                            }

                        }
                    }
                    //复制行，则为复制整个表头区域
                } else if (btnCode === 'copy-row') {
                    hasSel = true;
                    array[start] = [item];
                    if (parentNode && parentNode.length) {
                        for (let j = parentNode.length - 1; j >= 0; j--) {
                            if (!uniqueFlag[parentNode[j].attrcode]) {
                                uniqueFlag[parentNode[j].attrcode] = true;
                                array[start].push(parentNode[j]);
                            } else {
                                array[start].push('');
                            }
                        }
                    }
                }

                if (hasSel) {
                    //记录树最大深度
                    array[start] = array[start].reverse();
                    maxLength = Math.max(array[start].length, maxLength)
                    start++;
                }
            }

            //处理表头选中内容
            for (let i = 0; i < array[0].length; i++) {
                for (let j = 0; j < array.length; j++) {
                    //填充多表头
                    if (array[j].length < maxLength) {
                        array[j].push(...Array(maxLength - array[j].length).fill(''))
                    }
                    //获取label
                    let tdContent = typeof array[j][i] === 'string' ? array[j][i] : array[j][i].label;
                    copyContent = copyContent + tdContent + "\t";
                }
                copyContent = copyContent + "\n";
            }
            return copyContent;

            function TraverNode(nodes, parentNode = []) {
                if (isArray(nodes)) {
                    nodes.map(item => {
                        item.parentNode = parentNode;
                        //只存叶子节点
                        if (item.visible && !isArray(item.children) || (isArray(item.children) && item.children.length < 1)) {
                            arrayMeta.push(item);
                        }
                        TraverNode(item.children, [...parentNode, item]);
                    })
                }
            }
        }

        //对选中列 根据模板顺序排序
        sortActiveColumn = (visibleColumns, activedCells) => {
            let activeColumn = {};
            //获取所有选中列编码
            for (let rowid in activedCells) {
                let rowActiveCells = activedCells[rowid];
                rowActiveCells.forEach(code => {
                    if (!activeColumn[code]) {
                        activeColumn[code] = true
                    }
                })
            }

            //对选中列编码 根据模板顺序排序
            let sortedColumn = [];
            visibleColumns.forEach(item => {
                let code = item.attrcode;
                if (activeColumn[code]) {
                    sortedColumn.push(item);
                }
            })

            return sortedColumn;
        }

        //获取选中表体数据
        getBodyContent = (selectedRows, activedCells, btnCode, arrayMeta) => {
            let visibleColumns = arrayMeta.filter(e => e.visible === true);
            let sortedActiveColumn = this.sortActiveColumn(visibleColumns, activedCells);
            let copyContent = '';

            //复制整行
            if (btnCode === 'copy-row') {
                selectedRows.forEach((selectedRow, rowIndex) => {
                    let values = selectedRow.values;
                    //根据模板顺序 获取显示的数据
                    visibleColumns.forEach((item, index) => {
                        let cellValue = values[item.attrcode] || {};
                        copyContent =
                            copyContent +
                            (this.getDisplay(item, cellValue, values) || '');
                        if (index < visibleColumns.length - 1) {
                            copyContent = copyContent + "\t";
                        }
                    })
                    if (rowIndex < selectedRows.length - 1) {
                        copyContent = copyContent + "\n";
                    }
                })
            }

            //复制整列
            if (btnCode === 'copy-column') {
                selectedRows.forEach((selectedRow, rowIndex) => {
                    let values = selectedRow.values;
                    //根据模板顺序 获取显示的数据
                    sortedActiveColumn.forEach((item, index) => {
                        let code = item.attrcode;
                        let cellValue = values[code] || {};
                        copyContent =
                            copyContent +
                            (this.getDisplay(item, cellValue, values) || '');

                        if (index < sortedActiveColumn.length - 1) {
                            copyContent = copyContent + "\t";
                        }
                    })
                    if (rowIndex < selectedRows.length - 1) {
                        copyContent = copyContent + "\n";
                    }
                })
            }

            //复制单元格
            if (btnCode === 'copy') {
                selectedRows.forEach((selectedRow, rowIndex) => {
                    let rowid = selectedRow.rowid || selectedRow.rowId;
                    let avtiveCodes = activedCells[rowid];
                    let values = selectedRow.values;
                    //根据模板顺序 获取显示的数据
                    sortedActiveColumn.forEach((item, index) => {
                        let code = item.attrcode;
                        if (avtiveCodes.includes(code)) {
                            let cellValue = values[code] || {};
                            copyContent =
                                copyContent +
                                (this.getDisplay(item, cellValue, values) || '')
                        }
                        if (index < sortedActiveColumn.length - 1) {
                            copyContent = copyContent + "\t";
                        }
                    })
                    if (rowIndex < selectedRows.length - 1) {
                        copyContent = copyContent + "\n";
                    }
                })
            }
            return copyContent;
        }

        //根据itemtype不同的类型，整理复制出来单元格的数据，使其与表格显示的数据统一
        getDisplay = (item, cellValue={}, values) => {
            //let { langJson = {} } = this.props.contextMenuConfig;
            return cellValue.display || cellValue.value || '';
            //与重量端保持一致，不转数据格式，复制原始数据
            // return handleBrowseData(
            //     item.itemtype,
            //     cellValue.display,
            //     cellValue.value,
            //     typeof cellValue.scale === 'undefined'? item.scale : cellValue.scale,
            //     item.attrcode,
            //     item.languageMeta,
            //     values,
            //     item.datatype,
            //     item.options,
            //     item.multiple,
            //     langJson
            // );
        }

        //获取粘贴板数据
        getPasteData = () => {
            return new Promise((resolve) => {
                // 异步处理
                //IE浏览器
                if (window.clipboardData) {
                    let pasteContent = this.handlePasteContent(window.clipboardData.getData('Text'));
                    resolve(pasteContent);
                    //其他浏览器
                } else if (navigator.clipboard) {
                    navigator.clipboard.readText().then(res => {
                        let pasteContent = this.handlePasteContent(res);
                        resolve(pasteContent);
                    })
                }
            });
        }

        //去除空内容及最后一个换行符 转为数据为数组
        handlePasteContent = (pasteContent) => {
            if (pasteContent.length > 2) {
                let endStr = pasteContent.substr(pasteContent.length - 1, pasteContent.length);
                if (endStr === '\n') {
                    pasteContent = pasteContent.substr(0, pasteContent.length - 1);
                }
            }
            pasteContent = pasteContent.split('\n');
            return pasteContent;
        }

        createContextMenu = () => {
            let { contextMenuClick, showBusinessBtn, status, langJson = {}, showPasteBtn, extendRightMenu } = this.props.contextMenuConfig;
            return (
                <Fragment>
                    {
                        <li
                            className={'table-extend-right-menu copy'}
                            onClick={() => {
                                contextMenuClick('copy', (activeHeads, currentMeta, activedCells, selectedRows, arrayMeta) => {
                                    this.execCommandCopy('copy', activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
                                });
                            }}
                        >{langJson['table_copy']}</li>
                    }
                    {/* {
                        status === 'edit' && showPasteBtn &&
                        <li
                            className='table-extend-right-menu paste'
                            onClick={() => {
                                this.getPasteData().then((pasteContent) => {
                                    contextMenuClick('paste', null, {
                                        pasteContent,
                                        activeCell: this.pasteCell,
                                    })
                                })
                            }}
                        >{langJson['table_paste']}</li>
                    } */}
                    {
                        this.copyMenu.map(code => {
                            return <li
                                className={classnames({
                                    'table-extend-right-menu': true,
                                    [code]: true,
                                    'table-menus-sep': code === 'copy-column'
                                })}
                                onClick={() => {
                                    contextMenuClick(code, (activeHeads, currentMeta, activedCells, selectedRows, arrayMeta) => {
                                        this.execCommandCopy(code, activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
                                    });
                                }}
                            >{langJson['table_' + code]}</li>
                        })
                    }

                    {
                        status === 'edit' && showBusinessBtn && this.editBussMenu.map(code => {
                            return <li
                                //className={'table-extend-right-menu insert ' + code}
                                className={classnames({
                                    'table-extend-right-menu insert': true,
                                    [code]: true,
                                    'table-menus-sep': code === 'insert_down' || code === 'delete'
                                })}
                                onClick={() => {
                                    contextMenuClick(code, null, null, this[code], this.pasteCell.rowIndex)
                                }}
                            >{langJson['table_' + code]}
                                {
                                    (code === 'insert_up' || code === 'insert_down') &&
                                    <NumberInput
                                        className='insert_number'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onChange={(value) => {
                                            this[code] = value;
                                        }}
                                    />
                                }
                            </li>
                        })
                    }


                    {/* {
                        status === 'edit' && showBusinessBtn && showPasteBtn &&
                        <li
                            className='table-extend-right-menu insert-paste'
                            onClick={() => {
                                this.getPasteData().then((pasteContent) => {
                                    this.pasteCell.rowIndex = this.pasteCell.rowIndex + 1;
                                    contextMenuClick('insert_paste', null, {
                                        pasteContent,
                                        activeCell: this.pasteCell,
                                    },
                                        pasteContent.length,
                                        this.pasteCell.rowIndex - 1
                                    )
                                })
                            }}
                        >{langJson['table_insert_paste']}</li>
                    } */}

                    {isFunction(extendRightMenu) && extendRightMenu()}
                </Fragment>
            )
        }

        onCellMouseDown = (record, rowIndex, attrcode, __col, e) => {
            this.pasteCell.rowIndex = rowIndex;
            this.pasteCell.attrcode = attrcode;
            this.pasteCell.column = __col;
            let { onCellMouseDown, store, rowKey } = this.props;
            //记录当前单元格
            if (e && e.button === 2) {
                let activedCells = store.getCache('activedCells');
                let rowId = record[rowKey].value || record[rowKey];
                if (activedCells) {
                    let currentRowCell = activedCells[rowId];
                    if (Array.isArray(currentRowCell) && currentRowCell.includes(attrcode)) {
                        return;
                    }
                    for (let rowKeyValue in activedCells) {
                        (activedCells[rowKeyValue] || []).forEach(attrcode => {
                            store.setCellProps(
                                rowKeyValue,
                                attrcode,
                                {
                                    actived: false,
                                    leftLine: false,
                                    rightLine: false,
                                    topLine: false,
                                    bottomLine: false,
                                },
                                false,
                            );
                        });
                    }
                }

                store.setCellProps(
                    rowId,
                    attrcode,
                    {
                        actived: true,
                        leftLine: true,
                        rightLine: true,
                        topLine: true,
                        bottomLine: true,
                    },
                    true,
                );
                activedCells = { [rowId]: [attrcode] };
                store.setCache(
                    "activedCells",
                    activedCells,
                );
            }
            isFunction(onCellMouseDown) && onCellMouseDown(record, rowIndex, attrcode, __col, e);
        }

        render() {
            let { contextMenuConfig, onCellMouseDown, ...others } = this.props;
            let { enable, extendRightMenu, contextMenuClick } = contextMenuConfig;
            return <div ref={dom => {
                this.wrapper = dom;
            }}>
                <HotKeys
                    keyMap={{ copyHandler: ['ctrl+c'] }}
                    handlers={{
                        copyHandler: () => {
                            contextMenuClick('copy', (activeHeads, currentMeta, activedCells, selectedRows, arrayMeta) => {
                                this.execCommandCopy('copy', activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
                            }, undefined, undefined, undefined, true);
                        },
                    }}
                    className="copy-hotkeys-wrapper"
                    focused={true}
                    attach={false}
                >
                    <textarea id="table-copy-element" tabIndex="-1"></textarea>
                    <Table
                        {...others}
                        //记录右键菜单触发行
                        onCellMouseDown={this.onCellMouseDown}
                        //注册右键菜单
                        extendRightMenu={
                            enable ? this.createContextMenu() :
                                isFunction(extendRightMenu) ? extendRightMenu() : null
                        }
                    />
                </HotKeys>
            </div>
        }

    }
}