import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { getMultiLang, getSysFieldid, myBrowser, getGlobalStorage } from '@platform/api';
import { Table } from '@platform/base';
import { getTotalData, getTotalScale, getFinalTotalData, getTotalColumns, addBlankCol } from './util'
import './index.less';
import { isFunction } from "../../utils";
import classnames from 'classnames';
// tinper提供的计算滚动条宽度的方法
import { measureScrollbar } from "bee-table/build/lib/utils"

const totalTableHeight = 28;

export default function total(HighTable) {
    return class ModalTable extends Component {
        constructor(props) {
            super(props);
            this.state = { json: {}, }
            this.browser = myBrowser()
            // this.bodyColumn = props.columns
        }
        UNSAFE_componentWillMount() {
            let callback = json => {
                this.setState({ json });
            };
            getMultiLang({ moduleId: 'page_table', callback });
        }
        componentDidMount() {
            const { isTotal } = this.props
            isTotal && this.addScrollEvent();
            isTotal && this.hidebodyScrollbar();
            window.addEventListener('resize', this.hidebodyScrollbar)
            let fontSize = getGlobalStorage('localStorage', 'nccFontSize')
            if (fontSize === 'large') {
                this.setState({ fontSize: 'large' })
            }
        }
        // UNSAFE_componentWillReceiveProps(nextprops) {
        //     this.bodyColumn = nextprops.columns;
        // }
        componentDidUpdate() {
            const { isTotal } = this.props
            isTotal && this.addScrollEvent();
            isTotal && this.hidebodyScrollbar();

        }
        componentWillUnmount() {
            const { isTotal } = this.props
            isTotal && this.removeScrollEvent();
        }
        // 添加滚动事件
        addScrollEvent() {
            //每次重新绑定
            this.removeScrollEvent();
            let totalArea = this.total && this.total.querySelector('.u-table-body');
            let totalInnerArea = this.total && this.total.querySelector('.u-table-body table');
            totalArea && totalArea.addEventListener('scroll', this.addTotalAreaScrollEvent);
            totalInnerArea && totalInnerArea.addEventListener('scroll', this.addTotalInnerAreaScrollEvent);
            // 目的是解决 火狐浏览器下 滚动条事件失效问题 NCCLOUD-163960
            if (myBrowser() == 'Firefox' && totalArea) {
                requestAnimationFrame(function () {
                    totalArea.style.overflowX = 'auto';
                    totalArea.style.marginBottom = '0px';
                });
                totalArea.style.marginBottom = '17px';
                totalArea.style.overflowX = 'hidden';
            }
        }

        addTotalAreaScrollEvent = () => {
            let totalArea = this.total && this.total.querySelector('.u-table-body');
            let bodyArea = this.table && this.table.querySelector('.u-table-body table');
            let headerArea = this.table && this.table.querySelector('.u-table-content .u-table-scroll .u-table-header');
            //合计行滚动条事件
            if (totalArea) {
                if (bodyArea) bodyArea.scrollLeft = totalArea.scrollLeft;
                if (headerArea) headerArea.scrollLeft = totalArea.scrollLeft;
            }
        }
        addTotalInnerAreaScrollEvent = () => {
            let totalInnerArea = this.total && this.total.querySelector('.u-table-body table');
            let bodyArea = this.table && this.table.querySelector('.u-table-body table');
            let headerArea = this.table && this.table.querySelector('.u-table-content .u-table-scroll .u-table-header');
            //合计行滚动条事件
            if (totalInnerArea) {
                if (bodyArea) bodyArea.scrollLeft = totalInnerArea.scrollLeft;
                if (headerArea) headerArea.scrollLeft = totalInnerArea.scrollLeft;
            }
        }
        // 去除滚动事件
        removeScrollEvent() {
            let totalArea = this.total && this.total.querySelector('.u-table-body');
            let totalInnerArea = this.total && this.total.querySelector('.u-table-body table');
            totalArea && totalArea.removeEventListener('scroll', this.addTotalAreaScrollEvent);
            totalInnerArea && totalInnerArea.removeEventListener('scroll', this.addTotalInnerAreaScrollEvent);
        }
        onDropBorderHandler = () => {
            if (!this.total) {
                return
            }
            // let th = this.table.querySelectorAll('.nc-table:not(.total-row) >div.u-table-content >div.u-table-scroll >div:nth-child(1) .u-table-body table colgroup col');
            // let totalBody = this.total.querySelectorAll('.u-table-scroll .u-table-body colgroup col');
            // let length = th?.length || 0;
            // for (let i = 0, len = length; i < len; i++) {
            //     let newWidth = th[i] && th[i].style.width;
            //     if (totalBody[i]) {
            //         totalBody[i].style.width = newWidth;
            //         totalBody[i].style.minWidth = newWidth;
            //     }
            // }
            let footerBody = this.total && this.total.querySelector('.u-table-body')
            footerBody.style.overflowX = 'auto';
            this.hidebodyScrollbar();
            // this.bodyColumn = newColumn;
        };

        onDraggingBorder = () => {
            let th = this.table.querySelectorAll('.nc-table:not(.total-row) >div.u-table-content >div.u-table-scroll >div:nth-child(1) .u-table-body table colgroup col');
            let totalBody = this.total && this.total.querySelectorAll('.u-table-scroll .u-table-body colgroup col');
            for (let i = 0, len = th.length; i < len; i++) {
                let newWidth = th[i] && th[i].style.width;
                if (totalBody[i]) {
                    totalBody[i].style.width = newWidth;
                }
                if (typeof newWidth === 'string') {
                    newWidth = Number(newWidth.match(/\d+/g)[0]);
                }
            }
            let footerBody = this.total && this.total.querySelector('.u-table-body table');
            if (footerBody) { footerBody.style.overflowX = 'auto' }
            this.hidebodyScrollbar();
            isFunction(this.props.onDraggingBorder) && (this.props.onDraggingBorder())
        };

        haveHorizontalScrollBar = () => {
            let outbody = this.total && this.total.querySelector('.u-table-body');
            if (outbody) {
                let innerbody = outbody.querySelector('table');
                if (innerbody) {
                    return innerbody.offsetWidth > outbody.offsetWidth
                } else {
                    return false
                }

            }
        }

        haveVerticalScrollBar = () => {
            let uTableScroll = this.total && this.total.parentNode.parentNode.parentNode;
            if(uTableScroll){
                let offsetHeight = uTableScroll.querySelector('.u-table-scroll .u-table-body')?.offsetHeight;
                let scrollHeight = uTableScroll.querySelector('.u-table-scroll .u-table-body')?.scrollHeight;
                return scrollHeight > offsetHeight
            }
            return false
        }

        //当有合计行时并且出现横向滚动条的时候，将合计行上移，盖住body部分的横向滚动条
        hidebodyScrollbar = () => {
            if (!this.total) { return }
            let tableFooter = this.total
            if (this.haveHorizontalScrollBar()) {
                if (tableFooter) {
                    if (this.browser === 'Firefox' || this.browser === 'IE' || this.browser === 'Edge') {
                        tableFooter.style.marginTop = '-17px';
                    } else {
                        tableFooter.style.marginTop = '-10px';
                    }
                    if(this.haveVerticalScrollBar()){
                        tableFooter.style.paddingRight = measureScrollbar() + 'px';
                    }else{
                        tableFooter.style.paddingRight = '0px';
                    }
                }
                //用于合计行文字上下居中 NCCLOUD-191974
                let totalTd = this.total.querySelectorAll('td');
                if (totalTd && totalTd.length) {
                    for (let i = 0, len = totalTd.length; i < len; i++) {
                        if (this.browser === 'Firefox' || this.browser === 'IE' || this.browser === 'Edge') {
                            totalTd[i].style.paddingTop = '5px';
                        } else {
                            totalTd[i].style.paddingTop = '10px';
                        }
                    }
                }
            } else if (!this.haveHorizontalScrollBar()) {
                if (tableFooter) {
                    tableFooter.style.marginTop = '0px';
                    // 解决NCC-93556问题，在拖动滚动条时，overflowX值发生变化导致重绘
                    // let footerBody = tableFooter.querySelector('.u-table-body table')
                    // if (footerBody) {
                    //     footerBody.style.overflowX = 'visible';
                    // }
                    tableFooter.style.paddingRight = '0px';
                }
                let totalTd = this.total.querySelectorAll('td');
                if (totalTd && totalTd.length) {
                    for (let i = 0, len = totalTd.length; i < len; i++) {
                        totalTd[i].style.paddingTop = '0px';
                    }
                }

            }
        }

        handleFooter = (columns) => {
            let { data, totalConfig = {}, checkColConfig = {}, inserttableTotalConfig, numberPd } = this.props
            let finalColumns = []
            let totalData = {}
            if (inserttableTotalConfig) {
                finalColumns = inserttableTotalConfig.totalColumns
                totalData = inserttableTotalConfig.totalData
            } else {
                const { json } = this.state
                //获得合计行的 items
                // finalColumns = getTotalColumns(this.columnAfterDrop || this.bodyColumn, { numberPd: totalConfig.numberPd });
                finalColumns = getTotalColumns(columns, { numberPd: totalConfig.numberPd });
                // 获得 每一列的精度
                let totalScale = getTotalScale(finalColumns, data)
                //合计行的data
                totalData = getTotalData(finalColumns, data, totalConfig, totalScale || {});
                if (totalScale !== null) {
                    // 获取合计行进行精度处理后的数据
                    totalData = getFinalTotalData(totalData[0], totalScale);
                }

                //totalData = this.formNumber(totalData);

                //合计文字
                checkColConfig.showCheck ? totalData[0].checkbox = json['table002'] : totalData[0].numberindex = json['table002']
            }
            return (
                <div fieldid={getSysFieldid('total-row')}>
                    <Table
                        minColumnWidth={80}
                        height={totalTableHeight}
                        ref={dom => {
                            this.total = ReactDOM.findDOMNode(dom);
                        }}
                        className={classnames('total-row',
                            { 'total-first-col-padding': this.state.fontSize }, //字体变大，合计两个字会被遮挡
                        )}
                        addBlankCol={false}
                        data={totalData}
                        columns={[...finalColumns]}
                        rowKey={'total-row'}
                        showHeader={false}
                        headerScroll={false}
                        scroll={{}}
                        bodyStyle={{ minHeight: totalTableHeight, overflowX: 'auto' }}
                    />
                </div>
            );
        };

        render() {
            const { isTotal } = this.props
            if (isTotal) {
                return (<HighTable
                    {...this.props}
                    handleFooter={(columns) => this.handleFooter(columns)}
                    setCellValue={params => {
                        // 暂时先去除
                        // this.forceUpdate()
                        // this.setState({});
                        isFunction(this.props.setCellValue) && (this.props.setCellValue(params))

                    }}
                    ref={dom => {
                        this.table = ReactDOM.findDOMNode(dom);
                    }}
                    // onDropAfter={newColumn => { this.columnAfterDrop = newColumn }}
                    onDropBorderHandler={() => { this.onDropBorderHandler() }}
                />)
            } else {
                return <HighTable {...this.props} />
            }
        }

    }
}