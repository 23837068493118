import React, { Component } from "react";
import { getSysFieldid } from '@platform/api';
import { Checkbox, Dropdown, Menu } from '@platform/base';
import { isFunction } from '@platform/api';
import './index.less';
import { isObject } from '../../utils';
const { Item } = Menu;

export default function checkCol(Table) {
    return class ModalTable extends Component {
        constructor(props) {
            super(props);
        }

        componentDidMount() {
        }

        getTitle() {
            let {
                checkColConfig = {},
                checkInfo = {},
                onAllCheckChange,
            } = this.props
            const { changeCrossSelect, crossPageSelect } = checkColConfig
            let { langJson } = checkColConfig

            const menu = < Menu onClick={this.chooseStatus} >
                <Item key="all">{langJson['table0036'] || '全选'}</Item>
                <Item key="reverse">{langJson['table0039'] || '全反选'}</Item>
                <Item key="current">{langJson['table0040'] || '仅当前页'}</Item>
            </Menu >

            return < span fieldid={getSysFieldid('firstcol')} >
                <Checkbox
                    className="table-checkbox"
                    checked={checkInfo.checkedAll}
                    disabled={checkInfo.disabledAll}
                    indeterminate={checkInfo.indeterminate}
                    tabindex="-1"
                    onChange={value => {
                        isFunction(onAllCheckChange) && onAllCheckChange(value)
                    }}
                />
                {crossPageSelect && isFunction(changeCrossSelect) &&
                    <Dropdown
                        trigger={['click']}
                        overlay={menu}
                        overlayClassName={'select-status'}
                        animation="slide-up">
                        <i className='icon-bottom iconfont' />
                    </Dropdown>
                }
            </span >;
        }

        onCellClick = (record, e) => {
            let {
                data, onCheckChange,
                rowKey,
            } = this.props
            
            const { disabled } = record;
            // 禁用不允许点击
            if (disabled && disabled.value) return;
            let rowId = record[rowKey] || record.key
            if (isObject(record[rowKey])) {//兼容simpletable
                rowId = record[rowKey].value
            }
            let index = data.findIndex(row => {
                if (isObject(row[rowKey])) {//兼容simpletable
                    return rowId === row[rowKey].value
                } else {
                    return row[rowKey] === rowId
                }
            })
            isFunction(onCheckChange) && onCheckChange(record, index)
            // 阻止冒泡防止行点击触发
            e.stopPropagation();
        }

        chooseStatus = ({ key }) => {
            let { checkColConfig = {} } = this.props
            const { changeCrossSelect } = checkColConfig
            changeCrossSelect(key)
        }

        render() {
            let { columns, checkColConfig = {}, onCheckChange } = this.props
            const { showCheck, checkColArea } = checkColConfig

            if (showCheck) {
                const checkboxColum = {
                    title: this.getTitle.bind(this),
                    // 这个是列使用的方法  要和  onTableCellClick 我们自己定义的方法区分下
                    // 增加单选的触发区域范围
                    onCellClick: this.onCellClick,
                    // 增加全选的触发区域范围, 禁用不允许点击
                    // onHeadCellClick: () => {
                    //     let { disabledAll, onAllCheckChange } = this.props
                    //     disabledAll || isFunction(onAllCheckChange) && onAllCheckChange()
                    // },
                    dataIndex: 'checkbox',
                    className: 'table-checkbox-class',
                    visible: true,
                    attrcode: 'checkbox',
                    itemtype: 'customer',
                    width: '56px', //NCC-56167
                    fixed: 'left',
                    render: (text, record, index) => {
                        let selected = record.selected
                        let disabled = record.disabled
                        if (isObject(record.selected)) {//兼容simpletable
                            selected = record.selected.value
                        }
                        if (isObject(record.disabled)) {//兼容simpletable
                            disabled = record.disabled.value
                        }

                        return (
                            <span fieldid={getSysFieldid('firstcol')} className='table-checkbox-wrapper'>
                                {isFunction(checkColArea) && <div>{checkColArea(text, record, index)}</div>}
                                <Checkbox
                                    className="table-checkbox"
                                    checked={!!selected}
                                    disabled={!!disabled}
                                    tabindex="-1"
                                    onChange={() => {
                                        isFunction(onCheckChange) && onCheckChange(record, index, selected)
                                    }}
                                />
                            </span >)
                    },
                }
                //     let haveCheckCol = columns.some(item => item.attrcode === 'checkbox')
                //     if (!haveCheckCol) {
                columns = [checkboxColum, ...columns]
                // }
            }
            return (
                <Table
                    {...this.props}
                    columns={columns}
                />)
        }

    }

}