import React, { Component } from "react";
import { moment } from '@platform/api';
import { Modal, Input, InputNumber, Select, DatePicker, TextArea, Radio, Switch, Button, Form } from '@platform/base'
import { toast, isWrong, createScript, isObj, getSafeRandom } from '@platform/api';
import refer from './createRefer'
import './index.less';
import { isFunction } from "../../utils";

const FormItem = Form.FormItem;
const Option = Select.Option;
const displayTypes = ['select', 'radio', 'checkbox', 'refer']

export default function simpleTableModal(Table) {
    return class ModalTable extends Component {
        constructor(props) {
            super(props);
            this.state = { json: {} }
        }

        // UNSAFE_componentWillMount() {
        //     // 初始化调用getPlatformLang方法获取多语
        //     let callback = (json, bool, LangData) => {
        //         this.setState({ json, LangData });
        //     };
        //     // moduleId为所需加载json文件夹名称前缀
        //     getMultiLang({ moduleId: 'page_table', callback });
        // }

        handleDoSave = () => {
            let { store, tableModelConfirm } = this.props
            let { closeModal, getStore } = store
            let { modelDatas } = getStore()
            let { operType, record: tableModeldata } = modelDatas
            let tempObj = {},
                moduleId = this.props.name,
                type = operType
            // TODO
            modelDatas.modelColumn.forEach(item => {
                let key = item.attrcode;
                // 过滤操作列和序号列
                if (key != 'customer') {
                    tempObj[key] = {
                        display: isObj(tableModeldata[key]) ? tableModeldata[key].display || null : null,
                        scale: isObj(tableModeldata[key]) ? this.handleScale(tableModeldata[key].scale) : '-1',
                        value: isObj(tableModeldata[key]) ? this.handleValue(key, type, tableModeldata[key].value) : null,
                    };
                }
            });

            let dist = {
                [moduleId]: {
                    areaType: 'table',
                    pageInfo: {},
                    rows: [
                        {
                            rowId: modelDatas.rowId,
                            status: type == 'add' ? 2 : 1,
                            values: { ...tempObj },
                        },
                    ],
                },
            };
            isFunction(tableModelConfirm) && tableModelConfirm(dist, type);
            closeModal(moduleId);
        };

        handleScale = data => {
            if (typeof data == 'undefined' || data == null) {
                return '-1';
            }
            return +data;
        };

        handleValue = (key, type, data) => {
            if (key == 'ts' && type == 'add') {
                return null;
            }
            return typeof data == 'undefined' || data == '' ? null : data;
        };

        handleSubmit = () => {
            const { modalConfig } = this.props;
            let { langJson } = modalConfig;
            let { flag, errorTitle, errorInfo, errorType, max } = this.checkSubmitRule();
            if (flag) {
                let content = {
                    // "1": `请${errorInfo + errorTitle}`,
                    1: `${langJson['table0011'] + errorInfo + errorTitle}`,
                    // "2": `${errorTitle}的最大长度为${max.toString()}`
                    2: `${errorTitle + langJson['table0012'] + max.toString()}`,
                };
                toast({ content: content[errorType], color: 'danger' });
            } else {
                // 保存
                this.handleDoSave();
            }
        };

        HanlderCheckInput = (n, slected) => {
            let { value } = slected;
            let len = String(value).length;
            if ((!n && len > 0) || (len > 0 && len <= n)) {
                return true;
            }
            return false;
        };

        HanlderCheckNumber = (max = Infinity, slected) => {
            let { value } = slected;
            if (value && value <= max) {
                return true;
            }
            return false;
        };

        // 只检测了为空 还要检测长度
        checkSubmitRule = () => {
            const { modalConfig } = this.props;
            let { langJson } = modalConfig;
            var [errorIndex, errorTitle, errorInfo, errorType, max] = [-1];

            let { modelDatas } = this.props.store.getStore()
            let { record: tableModeldata, modelColumn } = modelDatas

            // TODO 区分必输和长度限制
            let distCol = modelColumn.length && modelColumn.filter(
                item => !!item.itemtype && item.itemtype !== 'label' && item.itemtype !== 'customer' && !!item.required,
            );

            let checkEmpty = distCol.some((item, index) => {
                errorIndex = index;
                errorTitle = item.label;
                errorInfo = displayTypes.includes(item.itemtype) ? langJson['table0013'] : langJson['table0014'];
                errorType = null;
                max = '';
                let checkData = tableModeldata[item.attrcode] || {};
                let { value: checkVal } = checkData;
                // item.value == '' 为空   错误类型1
                // item.maxlength 超出     错误类型2
                if (checkVal == '' || checkVal == null || checkVal == undefined) {
                    errorType = '1';
                    return true;
                }
                if (item.maxlength && String(checkVal).length > item.maxlength) {
                    errorType = '2';
                    max = item.maxlength;
                    return true;
                }
                return false;
            });
            if (checkEmpty) {
                return {
                    flag: true,
                    errorIndex,
                    errorTitle,
                    errorInfo,
                    errorType,
                    max,
                };
            }
            return { flag: false };
        };

        close = () => {
            this.props.store.closeModal()
        };

        afterEvent = (attrcode, changedrows, val, index, record) => {
            if (typeof this.props.afterEvent == 'function') {
                index = this.props.store.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter');
                this.props.afterEvent(this.props.moduleId, attrcode, changedrows, val, index, record);
            }
        };

        render() {
            const { store, type, name, modalConfig } = this.props
            const { getStore, getCtx, setModalValue } = store
            let modelDatas = getStore('modelDatas')
            let { showModal, record: tableModeldata, modelColumn } = modelDatas
            let { langJson } = modalConfig;
            let title = langJson['table0015'];
            switch (type) {
                case 'add':
                    title = langJson['table0016'];
                    break;
                case 'edit':
                    title = langJson['table0017'];
                    break;
                default:
                    break;
            }
            let formMeta = modelColumn.length ?
                modelColumn.filter(
                    item => !!item.itemtype && item.itemtype !== 'label' && item.itemtype !== 'customer',
                ) : []
            return (
                <React.Fragment>
                    <Table {...this.props} />
                    {showModal && <Modal
                        id="tableModal"
                        show={showModal}
                        className="table-modal"
                        backdrop="static"
                        animation={false}
                        onHide={this.close}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form showSubmit={false} useRow={true} className="table-modal-form">
                                {
                                    formMeta.map((item, i) => {
                                        let index = modelDatas.index;
                                        if (item.render === 'function') {
                                            this.props.store.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter');
                                        }
                                        let render =
                                            typeof item.render === 'function' ? item.render('', modelDatas.record, index) : null;
                                        if (render) {
                                            let val = tableModeldata[item.attrcode] || {};
                                            return (
                                                <FormItem
                                                    key={i}
                                                    inline={true}
                                                    labelMd={3}
                                                    md={9}
                                                    valuePropsName="value"
                                                    showMast={!!item.required}
                                                    isRequire={!!item.required}
                                                    method="blur"
                                                    labelName={item.label}
                                                >
                                                    {{
                                                        ...(render),
                                                        key: item.attrcode,
                                                        props: {
                                                            ...(render),
                                                            ...item,
                                                            foolValue: val,
                                                            disabled: !!item.disabled,
                                                            onChange: value => {
                                                                setModalValue(item.attrcode, value, 'refer');
                                                                let changedrows = [];
                                                                changedrows.push({
                                                                    rowid:
                                                                        modelDatas.rowId || String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                    newvalue: { value },
                                                                    oldvalue: { value: 'value' },
                                                                });
                                                                this.afterEvent(item.attrcode, changedrows, value, modelDatas.index, modelDatas.record);
                                                            },
                                                        },
                                                    }}
                                                </FormItem>
                                            );
                                        } else if (item.refcode) {
                                            // getCtx 方法没有 注意后面需要的话自己加下
                                            // createScript.call(getCtx(), 'table', name, item.attrcode, item.refcode);
                                        } else {
                                            switch (item.itemtype) {
                                                case 'input':
                                                    return (
                                                        <FormItem
                                                            key={i}
                                                            inline={true}
                                                            labelMd={3}
                                                            md={9}
                                                            valuePropsName="value"
                                                            showMast={!!item.required}
                                                            isRequire={!!item.required}
                                                            method="blur"
                                                            asyncCheck={this.HanlderCheckInput.bind(this, item.maxlength)}
                                                            labelName={item.label}
                                                            errorMessage={
                                                                item.maxlength
                                                                    ? `${item.label + langJson['table0012'] + item.maxlength.toString()}`
                                                                    : `${langJson['table0011'] + langJson['table0014'] + item.label}`
                                                            }
                                                        >
                                                            <Input
                                                                placeholder={`${langJson['table0011'] + langJson['table0014']}+...`}
                                                                name={item.attrcode}
                                                                disabled={!!item.disabled}
                                                                {...item}
                                                                value={tableModeldata[item.attrcode] ? tableModeldata[item.attrcode].value : null}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, val);
                                                                }}
                                                                onBlur={val => {
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: val },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(item.attrcode, changedrows, val, modelDatas.index, modelDatas.record);
                                                                }}
                                                            />
                                                        </FormItem>
                                                    );
                                                case 'number':
                                                    return (
                                                        <FormItem
                                                            key={i}
                                                            inline={true}
                                                            labelMd={3}
                                                            md={9}
                                                            valuePropsName="value"
                                                            showMast={!!item.required}
                                                            isRequire={!!item.required}
                                                            method="blur"
                                                            asyncCheck={this.HanlderCheckNumber.bind(this, item.max)}
                                                            labelName={item.label}
                                                            errorMessage={
                                                                item.max
                                                                    ? `${item.label + langJson['table0018'] + item.max}`
                                                                    : `${langJson['table0011'] + langJson['table0014'] + item.label}`
                                                            }
                                                        >
                                                            <InputNumber
                                                                placeholder={`${langJson['table0011'] + langJson['table0014']}+...`}
                                                                name={item.attrcode}
                                                                scale={
                                                                    tableModeldata[item.attrcode] &&
                                                                        !isWrong(tableModeldata[item.attrcode].scale) &&
                                                                        tableModeldata[item.attrcode].scale != '-1'
                                                                        ? +tableModeldata[item.attrcode].scale
                                                                        : +item.scale || 0
                                                                }
                                                                disabled={!!item.disabled}
                                                                {...item}
                                                                value={tableModeldata[item.attrcode] ? tableModeldata[item.attrcode].value : null}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, val);
                                                                }}
                                                                onBlur={val => {
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: val },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(item.attrcode, changedrows, val, modelDatas.index, modelDatas.record);
                                                                }}
                                                            />
                                                        </FormItem>
                                                    );
                                                case 'refer':
                                                    return (
                                                        <FormItem
                                                            key={i}
                                                            inline={true}
                                                            labelMd={3}
                                                            md={9}
                                                            valuePropsName="value"
                                                            showMast={!!item.required}
                                                            isRequire={!!item.required}
                                                            method="blur"
                                                            labelName={item.label}
                                                        >
                                                            {refer(item.refcode, {
                                                                disabled: !!item.disabled,
                                                                foolValue: tableModeldata[item.attrcode] || {},
                                                                onChange: val => {
                                                                    setModalValue(item.attrcode, val, 'refer');
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: val },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(item.attrcode, changedrows, val, modelDatas.index, modelDatas.record);
                                                                },
                                                            })}
                                                        </FormItem>
                                                    );
                                                case 'select':
                                                    return (
                                                        <FormItem
                                                            key={i}
                                                            inline={true}
                                                            labelMd={3}
                                                            md={9}
                                                            method="blur"
                                                            showMast={!!item.required}
                                                            isRequire={!!item.required}
                                                            labelName={item.label}
                                                            errorMessage={`${langJson['table0011'] + langJson['table0013'] + item.label}`}
                                                        >
                                                            <Select
                                                                type="customer"
                                                                disabled={!!item.disabled}
                                                                value={tableModeldata[item.attrcode] ? tableModeldata[item.attrcode].value : ''}
                                                                {...item}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, String(val));
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: String(val) },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(
                                                                        item.attrcode,
                                                                        changedrows,
                                                                        String(val),
                                                                        modelDatas.index,
                                                                        modelDatas.record,
                                                                    );
                                                                }}
                                                                getPopupContainer={() => document.querySelector('#tableModal')}
                                                                dropdownStyle={{ zIndex: 18000 }}
                                                                dropdownClassName={NODE_ENV === 'test' && item.attrcode + '-' + 'select'}
                                                            >
                                                                {item.options.length &&
                                                                    item.options.map((one, i) => (
                                                                        <Option value={String(one.value)} key={i} title={one.display}>
                                                                            {' '}
                                                                            {one.display}{' '}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </FormItem>
                                                    );
                                                case 'datepicker':
                                                    return (
                                                        <FormItem
                                                            key={i}
                                                            inline={true}
                                                            labelMd={3}
                                                            md={9}
                                                            showMast={!!item.required}
                                                            isRequire={!!item.required}
                                                            labelName={item.label}
                                                            errorMessage={`${langJson['table0011'] + langJson['table0013'] + item.label}`}
                                                        >
                                                            <DatePicker
                                                                type="customer"
                                                                format="YYYY-MM-DD"
                                                                disabled={!!item.disabled}
                                                                getCalendarContainer={() => document.querySelector('#tableModal')}
                                                                placeholder={`${langJson['table0013'] + langJson['table0019'] + langJson['table0020']}`}
                                                                {...item}
                                                                value={tableModeldata[item.attrcode] ? moment(tableModeldata[item.attrcode].value) : null}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, val);
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: val },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(item.attrcode, changedrows, val, modelDatas.index, modelDatas.record);
                                                                }}
                                                            />
                                                        </FormItem>
                                                    );
                                                case 'textarea':
                                                    return (
                                                        <FormItem key={i} inline={true} labelMd={3} md={9} showMast={true} labelName={item.label}>
                                                            <TextArea
                                                                placeholder={`${langJson['table0011'] + langJson['table0014']}+...`}
                                                                disabled={!!item.disabled}
                                                                name={item.attrcode}
                                                                {...item}
                                                                value={tableModeldata[item.attrcode] ? tableModeldata[item.attrcode].value : null}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, val);
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: val },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(item.attrcode, changedrows, val, modelDatas.index, modelDatas.record);
                                                                }}
                                                            />
                                                        </FormItem>
                                                    );
                                                case 'radio':
                                                    return (
                                                        <FormItem key={i} inline={true} labelMd={3} md={9} showMast={true} labelName={item.label}>
                                                            <Radio.RadioGroup
                                                                type="customer"
                                                                name={item.attrcode}
                                                                disabled={!!item.disabled}
                                                                selectedValue={
                                                                    tableModeldata[item.attrcode] ? String(tableModeldata[item.attrcode].value) : null
                                                                }
                                                                {...item}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, String(val));
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: String(val) },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(
                                                                        item.attrcode,
                                                                        changedrows,
                                                                        String(val),
                                                                        modelDatas.index,
                                                                        modelDatas.record,
                                                                    );
                                                                }}
                                                            >
                                                                {item.options.length &&
                                                                    item.options.map((one, i) => (
                                                                        <Radio value={String(one.value)} key={i}>
                                                                            {' '}
                                                                            {one.display}{' '}
                                                                        </Radio>
                                                                    ))}
                                                            </Radio.RadioGroup>
                                                        </FormItem>
                                                    );
                                                case 'switch':
                                                    return (
                                                        <FormItem key={i} inline={true} labelMd={3} md={9} showMast={true} labelName={item.label}>
                                                            <Switch
                                                                checked={tableModeldata[item.attrcode] ? !!tableModeldata[item.attrcode].value : false}
                                                                disabled={!!item.disabled}
                                                                {...item}
                                                                onChange={val => {
                                                                    setModalValue(item.attrcode, val);
                                                                    let changedrows = [];
                                                                    changedrows.push({
                                                                        rowid:
                                                                            modelDatas.rowId ||
                                                                            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12),
                                                                        newvalue: { value: val },
                                                                        oldvalue: { value: 'value' },
                                                                    });
                                                                    this.afterEvent(item.attrcode, changedrows, val, modelDatas.index, modelDatas.record);
                                                                }}
                                                            />
                                                        </FormItem>
                                                    );

                                                case 'rangepicker':
                                                    return (
                                                        <FormItem key={i} inline={true} labelMd={3} md={9} showMast={true} labelName={item.label}>
                                                            <div>rangepicker</div>
                                                        </FormItem>
                                                    );
                                                default:
                                                    return null;
                                            }
                                        }
                                    })

                                }
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-2" onClick={this.handleSubmit}>
                                {langJson['table0021']}
                            </Button>
                            <Button className="btn-2 btn-cancel" onClick={this.close} shape="border">
                                {langJson['table_cancel']}
                            </Button>
                        </Modal.Footer>

                    </Modal>}
                </React.Fragment>
            )
        }

    }
}