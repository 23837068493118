/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-06-04 10:14:58
 * @LastEditors: bbq
 * @LastEditTime: 2021-02-25 19:09:45
 */
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import './cellIcon.less';

const TIME_TYPES = ['datepicker', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'timepicker', 'datetimepicker', 'rangepicker', 'datePickerNoTimeZone'];

function isWrong(param) {
    return typeof param === 'undefined' || param === null || param === '';
}

export default class CellIcon extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * 渲染图标
     */
    getIcon() {
        let { itemtype, disabled, value, isSaga_status } = this.props;
        if (itemtype == 'refer') {
            return <i
                className="refer-icon cell-icon align-right iconfont"
                onClick={(e) => {
                    let { onClick } = this.props;
                    // e.stopPropagation();
                    onClick && onClick('refer', e);
                    // if (isSaga_status) {
                    //     return;
                    // }
                    // this.mousedown && this.handleClick({ referAutoFocus: true });
                    // this.mousedown = false;
                }}
            >
                &#xe6ce;
            </i>;
        } else if (TIME_TYPES.includes(itemtype)) {
            return <i
                className="time-icon cell-icon align-right iconfont"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                    let { onClick } = this.props;
                    // e.stopPropagation();
                    onClick && onClick('picker', e);
                    // if (isSaga_status) {
                    //     return;
                    // }
                    // this.mousedown && this.handleClick({ timeAutoFocus: true });
                    // this.mousedown = false;
                }}
            >
                &#xe6cd;
            </i>;
        } else if (itemtype == 'select') {
            return <i
                className="select-icon cell-icon align-right iconfont"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                    let { onClick } = this.props;
                    // e.stopPropagation();
                    onClick && onClick('select', e);
                    // if (isSaga_status) {
                    //     return;
                    // }
                    // this.mousedown && this.handleClick({ selectAutoFocus: true });
                    // this.mousedown = false;
                }}
            >
                &#xe683;
            </i>;
        } else if (!isWrong(value)) {
            return null;
        } else {
            // 
        }
    }

    render() {
        // cellIcon 可以考虑自定义
        let { itemtype, attrcode, disabled, value, isSaga_status, cellIcon } = this.props;
        if (disabled) {
            return <span className="icon-placeholder" />;
        }
        // console.log(value, isWrong(value));
        // attrcode === 'numberTest' && console.log(isWrong(value), value)
        return <Fragment>
            {this.getIcon()}
        </Fragment>;
    }
}
