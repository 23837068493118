/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-08-17 09:56:42
 * @LastEditors: bbq
 * @LastEditTime: 2020-09-22 10:47:22
 */
// 总配置项
export default {
    allTypes: ['input', 'number', 'textarea', 'datepicker', 'rangepicker', 'switch', 'select', 'checkbox', 'radio', 'refer', 'customer', 'label', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'checkbox_switch', 'switch_browse'],
    displayTypes: ['select', 'radio', 'checkbox', 'refer'],
    string: ['input', 'textarea', 'datepicker', 'select', 'checkbox', 'radio', 'refer', 'label'],
    boolean: ['switch', 'checkbox_switch', 'switch_browse'],
    number: ['number'],
    valueTypesExceptNumber: ['input', 'textarea'],
    changeTypes: ['datepicker', 'rangepicker', 'switch', 'select', 'checkbox', 'radio', 'refer', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'checkbox_switch', 'switch_browse', 'datePickerNoTimeZone'],
    blurTypes: ['input', 'number', 'textarea'],
    // 编辑后在onblur中的类型
    afterBlurTypes: ['input', 'number', 'textarea', 'residtxt', 'datetimepicker', 'timepicker'],
    valueTypes: ['input', 'number', 'textarea'],
    noEditType: ['label', 'customer'],
    getDisplay: ['select', 'radio', 'checkbox'],
    ROW_STATUS: {
        origin: '0',
        edit: '1',
        add: '2',
        delete: '3',
        // 新增状态  不表现在status这个后台数据中  只为做判断
        filter: "9",
    },
    timeTypes: ['datepicker', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'timepicker', 'datetimepicker', 'rangepicker', 'datePickerNoTimeZone'],
    beforeFocusTypes: ['residtxt', 'input', 'select', 'number', 'textarea', 'password', 'switch_browse', 'refer'],
    beforeChangeTypes: ['radio'],
    beforeClickTypes: ['datepicker', 'timepicker', 'datetimepicker', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'rangepicker', 'datePickerNoTimeZone', 'checkbox_switch', 'checkbox'],
    hotKeyModuleIdPrefix: 'hot-key',
    defaultColor: ['#111111', '#555555', '#111', '#555'],
    complete: 'areaChangeComplete',
    //isPageModal: true,  // 为true,top的url放在sessionstorage;否则url不变; 默认值是true
    //isRemoveWorkbench: false, // 为true时,去掉工作桌面打开节点;默认值是false
    hotKeyDatePicker: 'datePicker',
    //由于enter键与业务组部分enter键搜索逻辑冲突因此添加标识，如果元素有此样式就不执行平台enter键逻辑
    stopEnterHotKey: 'stopEnterHotKey',
};