/*
 * @Descripttion: 单元格
 * @version:
 * @Author: bbq
 * @Date: 2020-06-01 09:53:27
 * @LastEditors: bbq
 * @LastEditTime: 2021-03-04 18:03:05
 *
 *
 * TODO
 * 1、number 类型居右
 * 2、自定义列
 * 3、表头排序  表头筛选
 * 4、固定列加上maxWidth=宽度
 * 5、tooltip
 *
 */
import React, { Component, Fragment, cloneElement, isValidElement } from "react";
import ReactDOM from 'react-dom';
import { Item, itemCreator } from "@platform/template";
import CellIcon from "./CellIcon";
import {
    isString,
    isFunction,
    isWrong,
    isArray,
    isBoolean,
    formatAcuracy,
    isNullOrVoid,
    isObject,
    changeTime,
    getRandomPassword,
    isIE,
    getOs,
} from "../../utils/index";
import { formatNumber, formatDatetime, isArrowKeyDown } from "@platform/api";
import { Tooltip } from '@platform/base';
import CONFIG from '../../config';
require("./cell.less");

let booleanType = {
    switch: true,
    checkbox_switch: true,
    switch_browse: true,
};

// 是否是火狐浏览器

const inFireFox = getOs() === 'Firefox';

// get nearest parent element matching selector   origin from stackoverflow  add by bbqin
const Closest = function (el, selector) {
    if (!el) {
        return null;
    }
    var matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

    while (el) {
        if (matchesSelector.call(el, selector)) {
            break;
        }
        el = el.parentElement;
    }
    return el;
};

function getDisplayCreator(value, display, whichKeyToDisplay) {
    if (whichKeyToDisplay === 'auto') {
        return (display === undefined || display === null) ? value : display;
    } else {
        return (whichKeyToDisplay === 'value' ? value : display) || ''
    }
}

/**
 * 数据处理
 * @param {*} IType 
 * @param {*} display 
 * @param {*} value 
 * @param {*} scale 
 * @param {*} attrcode 
 * @param {*} languageMeta 
 * @param {*} values 
 * @param {*} datatype 
 * @param {*} options 
 * @param {*} multiple 
 * @param {*} langJson 
 */
function handleBrowseData(IType, display, value, scale, attrcode, languageMeta, values, datatype, options, multiple, langJson, whichKeyToDisplay, loginLanguage) {
    // TODO 数据格式的转换
    // 特殊处理下逻辑型  枚举类型
    if (
        datatype === "32" &&
        ["select", "checkbox", "radio"].includes(IType) &&
        isArray(options) &&
        !display &&
        value !== null &&
        value !== undefined
    ) {
        if (IType === "checkbox" || multiple) {
            // 异常的value不做处理 不处理下面代码会报错
            if (isBoolean(value)) {
                return value;
            }
            let displayList = options
                .filter(item => value.includes(item.value))
                .map(option => {
                    return getDisplayCreator(option.value, option.display, whichKeyToDisplay || 'display');
                });
            return displayList.join(",");
        } else {
            let values =
                options.filter(item => item.value == value)[0] || {};
            return getDisplayCreator(values.value, values.display, whichKeyToDisplay || 'display');
        }
    }

    switch (true) {
        case CONFIG.displayTypes.includes(IType):
            return getDisplayCreator(value, display, whichKeyToDisplay || 'display');
        case CONFIG.boolean.includes(IType):
            return value == "Y" || value == true
                ? langJson["table_yes"]
                : langJson["table_no"];
        case CONFIG.timeTypes.includes(IType):

            value = getDisplayCreator(value, display, whichKeyToDisplay || 'value');

            if (IType === "datetimepicker") {
                return formatDatetime(changeTime(value, "YYYY-MM-DD HH:mm:ss"), 'datetime');
            } else if (IType === "timepicker") {
                return formatDatetime(value, 'time');
            } else if (IType === "datePickerNoTimeZone") {
                return formatDatetime(value, 'date');
            }
            return formatDatetime(changeTime(value), 'date');
        case IType === "label":
            return getDisplayCreator(value, display, whichKeyToDisplay || 'auto');
        case IType === "number":
            // value = getDisplayCreator(value, display, whichKeyToDisplay || 'value');
            // 表格浏览态加了四舍五入
            return formatNumber(formatAcuracy(value, scale), true);
        case IType === "password":
            // 密码框浏览态显示***
            return value && getRandomPassword();
        case IType === "residtxt": // 对多语的处理
            let loginLang = languageMeta.filter(i => i.languageCode == loginLanguage);
            if (loginLang.length > 0) {
                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                if (
                    values[attrcode + loginLang[0].index] &&
                    values[attrcode + loginLang[0].index].value
                ) {
                    value = values[attrcode + loginLang[0].index].value;
                    return value;
                }
                return value;
            }
            return value;
        case IType === "input":
        case IType === "textarea":
            return getDisplayCreator(value, display, whichKeyToDisplay || 'value');
        default:
            return getDisplayCreator(value, display, whichKeyToDisplay || 'auto');
    }
}

// 开启了有些问题，record中的属性没有正确检测到，导致框选之类的操作没有正确生效
// @pureRender()
class Cell extends Component {
    constructor(props) {
        super(props);
        // 单元格state
        this.state = {
            tipStatus: false,
            autoOpen: false,
            showIcon: true,
            showTip: false,
            isEdit: false,
            showActiveDom: false,
            // rowKeyValue,
        };

        // rowKeyValue
        let { record, rowIndex, config: { attrcode, scale, rowKey, tableId, disableEditType } } = props;
        // 可变
        this.rowKeyValue = record[rowKey].value || record[rowKey];
        // 给框选用
        this.cellSelectorKey = this.rowKeyValue + '@' + attrcode;
        // 单元格属性 与 props 有关
        this.cellStatus = this.getCellStatus(props, this.state);
        // 精度
        this.cellScale = this.getScale(record, attrcode, scale);
        // 值
        this.cellValue = this.getValue(props, attrcode, this.cellScale, this.cellStatus.editable);
        // fieldId 给自动化测试用
        // 给快捷键用 还有结构要优化一下  TODO 
        this.autoFocusIdentifier = {};
        if (!disableEditType && this.cellStatus.editable) {
            let hotKeyPrefix = CONFIG.hotKeyModuleIdPrefix + '-' + tableId + '-' + attrcode + '-' + rowIndex;
            this.autoFocusIdentifier[hotKeyPrefix] = hotKeyPrefix;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (window.updateTrigger) {
            return false;
        }
        // console.time('shouldComponentUpdate');
        // return false;
        let thisProps = this.props || {};
        let thisState = this.state || {};
        let { record: thisRecord, config: { __col: __thisCol } } = thisProps;
        let { record, config: { store, __col, attrcode, scale, rowKey, disableEditType } } = nextProps;

        // 记录下是否渲染
        let shouldUpdate = false;
        //  rowKeyValue 变化比较
        let rowKeyValue = record[rowKey].value || record[rowKey];
        if (this.rowKeyValue !== rowKeyValue || thisProps.attrcode !== attrcode) {
            this.rowKeyValue = rowKeyValue;
            store?.setCache(['cell', this.rowKeyValue + attrcode], this);
            // 给框选用
            this.cellSelectorKey = this.rowKeyValue + '@' + attrcode;
            // console.log(attrcode, 'update5', rowKeyValue);
            shouldUpdate = true;
        }

        // 精度变化比较
        let cellScale = this.getScale(record, attrcode, scale);
        let cellStatus = this.getCellStatus(nextProps, nextState);
        let cellValue = this.getValue(nextProps, attrcode, cellScale, cellStatus.editable) || {};

        // 自定义单元格默认渲染/或者非simpleTable !disableEditType
        if (cellStatus.renderType !== 'normal') {
            // console.timeEnd('shouldComponentUpdate')
            this.cellStatus = cellStatus;
            this.cellValue = cellValue;
            this.cellScale = cellScale;
            return true;
        }

        if (cellScale !== this.cellScale) {
            // console.log(attrcode, 'update00', cellScale, this.cellScale);
            this.cellScale = cellScale;
            shouldUpdate = true;
        }
        // 单元格属性变化比较
        if (Object.keys(cellStatus).length !== Object.keys(this.cellStatus).length) {
            // console.log(attrcode, 'update9', Object.keys(cellStatus).length, Object.keys(this.cellStatus).length);
            this.cellStatus = cellStatus;
            shouldUpdate = true;
        } else {
            for (let key in cellStatus) {
                if (cellStatus[key] !== this.cellStatus[key]) {
                    // console.log(attrcode, 'update8', key, cellStatus[key], this.cellStatus[key]);
                    this.cellStatus = cellStatus;
                    shouldUpdate = true;
                    break;
                }
            }
        }
        // 值变化比较
        if (Object.keys(cellValue).length !== Object.keys(this.cellValue).length) {
            // console.log(attrcode, 'update7', Object.keys(cellValue).length, Object.keys(this.cellValue).length);
            this.cellValue = cellValue;
            shouldUpdate = true;
        } else {
            for (let key in cellValue) {
                if (cellValue[key] !== this.cellValue[key]) {
                    // console.log(attrcode, 'update6', key, cellValue[key], this.cellValue[key]);
                    this.cellValue = cellValue;
                    shouldUpdate = true;
                    break;
                }
            }
        }
        // console.timeEnd('shouldComponentUpdate')
        // 更新
        if (shouldUpdate) {
            return true;
        }

        // 浏览态 下面的属性不做对比
        if (disableEditType || !cellStatus.editable) {
            return false;
        }

        // 先检查目标长度
        if (
            Object.keys(thisProps.config).length !== Object.keys(nextProps.config).length ||
            Object.keys(thisState).length !== Object.keys(nextState).length ||
            Object.keys(__thisCol).length !== Object.keys(__col).length ||
            Object.keys(thisRecord).length !== Object.keys(record).length
        ) {
            // console.log(attrcode, 'updateeeeeeeee');
            // console.timeEnd('shouldComponentUpdate')
            return true;
        }

        // 先检查state 数目少检查快
        for (let key in nextState) {
            if (
                !isFunction(thisState[key]) &&
                !isObject(thisState[key])
            ) {
                if (
                    thisState[key] !== nextState[key]
                ) {
                    // console.log(attrcode, 'update4', key, thisState[key], nextState[key]);
                    // console.timeEnd('shouldComponentUpdate')
                    return true;
                }
            }
        }
        // 检查最外层的属性
        for (let key in nextProps.config) {
            if (
                !isFunction(thisProps[key]) &&
                !isObject(thisProps[key])
            ) {
                if (
                    thisProps.config[key] !== nextProps.config[key]
                ) {
                    // console.log(attrcode, 'update3', key, thisProps.config[key], nextProps.config[key]);
                    return true;
                }
            }
        }
        // 检查最外层的属性
        for (let key in __col) {
            if (
                !isFunction(__col[key]) &&
                !isObject(__col[key])
            ) {
                if (
                    __thisCol[key] !== __col[key]
                ) {
                    // console.log(attrcode, 'update2', key, __thisCol[key], __col[key]);
                    return true;
                }
            }
        }
        // 检查最外层的属性
        for (let key in record) {
            if (
                !isFunction(record[key]) &&
                !isObject(record[key])
            ) {
                if (
                    thisRecord[key] !== record[key]
                ) {
                    // console.log(attrcode, 'update1', key, thisRecord[key], record[key]);
                    return true;
                }
            }
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let { record, rowIndex, config: { attrcode, rowKey, store, render, itemtype, disableEditType, tableId } } = nextProps;

        // rowKeyValue
        let rowKeyValue = isObject(record[rowKey]) ? record[rowKey].value : record[rowKey];
        if (this.rowKeyValue !== rowKeyValue && render && itemtype === "customer") {
            // rowKeyValue
            let rowKeyValue = isObject(record[rowKey]) ? record[rowKey].value : record[rowKey];
            this.unregister();
            // 用于setCellValue 等操作 更新自定义列
            this.unregister = store?.registerField([rowKeyValue, 'sp_col_customer__', attrcode], this);
        }

        if (!disableEditType && this.cellStatus.editable) {
            let hotKeyPrefix = CONFIG.hotKeyModuleIdPrefix + '-' + tableId + '-' + attrcode + '-' + rowIndex;
            this.autoFocusIdentifier[hotKeyPrefix] = hotKeyPrefix;
        }
    }

    componentDidMount() {
        let { attrcode, store, render, itemtype } = this.props.config;
        // 自定义render清理
        if (render && itemtype === "customer") {
            this.unregister = store?.registerField([this.rowKeyValue, 'sp_col_customer__', attrcode], this);
        }
        // 记录单元格的this
        store?.setCache(['cell', this.rowKeyValue + attrcode], this);

        // 计算状态
        if (this.cellStatus.isEdit) {
            let $wrapper = ReactDOM.findDOMNode(this);
            this.cellDom = $wrapper && $wrapper.parentElement;
            let $focusElement = this.cellDom && this.cellDom.querySelector('[tabindex="0"]');
            if ($focusElement && !Closest($focusElement, '.u-table-row-fixed-columns-in-body')) {
                // 自动增行 触发编辑前事件
                $focusElement.ncExecuteFocus = true;
                $focusElement.focus();
                $focusElement.ncExecuteFocus = false;
                store && store.setCellProps(this.rowKeyValue, attrcode, { isEdit: undefined }, false);
            }
        }
    }

    componentDidUpdate() {
        // 更新渲染后
    }

    componentWillUnmount() {
        let { attrcode, store } = this.props.config;
        // 记录单元格的this
        store?.setCache(['cell', this.rowKeyValue + attrcode], null);
        // 清理下
        // clearTimeout(this.timer);
        // this.cellDom = null;
        // this.rowKeyValue = null;
        // this.cellValue = null;
        this.unregister?.();
    }

    /**
     * @description: 规范精度
     * @param {type}
     * @return {type}
     */
    getScale(record, attrcode, scale) {
        let values = record.values || record || {};
        let value = values[attrcode];
        let valueScale = isObject(value) ? value.scale : scale;
        valueScale = !isWrong(valueScale) && valueScale != "-1" ? +valueScale : +scale;
        // console.log(valueScale, attrcode);
        return valueScale || 0;
    }

    /**
     * attrcode 多语的会产生变化
     * @description: 处理数据
     * @param {type}
     * @return:
     */
    getValue = (props, attrcode, scale, editable) => {
        let { record, rowIndex, config: { getCellValue, rowKey, itemtype, loginLanguage, languageMeta, langJson = {} } } = props;
        // console.log(langJson);
        let value = {};
        if (getCellValue) {

            // 处理下多语的情况 让item里的计算和这外面的计算一致
            if (loginLanguage && itemtype === "residtxt") {
                let loginValue,
                    mainValue;
                Array.isArray(languageMeta) && languageMeta.map(({ index, languageCode }) => {
                    if (languageCode === loginLanguage) {
                        // 登录语言值
                        let realCode = index == 1 ? attrcode : attrcode + index;
                        loginValue = getCellValue({
                            attrcode: realCode,
                            rowKey,
                            rowKeyValue: record[rowKey],
                            rowIndex,
                        });
                    }
                    if (index == 1 || index == '') {
                        // 主语言值
                        mainValue = getCellValue({
                            attrcode,
                            rowKey,
                            rowKeyValue: record[rowKey],
                            rowIndex,
                        });
                    }
                });
                if (loginValue && loginValue.value) {
                    value = loginValue;
                }
                if (mainValue && mainValue.value) {
                    value = mainValue;
                }
            } else {
                value = getCellValue({
                    rowKey,
                    rowKeyValue: record[rowKey],
                    rowIndex,
                    attrcode,
                });
            }
        } else {
            !record.values && console.error("无效的record格式");
            value = record.values[attrcode];
            if (
                !value ||
                value.display === undefined ||
                value.value === undefined
            ) {
                // console.error('无效的values格式');
                !value && (value = {});
            }
            value.display = value.display || value.value || "";
            value.value = value.value || "";
        }

        // 特殊类型的值处理下
        if (!editable && booleanType[itemtype]) {
            value = JSON.parse(JSON.stringify(value || {}));
            value.display = value.value == 'Y' || value.value == true ? langJson && langJson['table_yes'] : langJson && langJson['table_no'];
            value.value = value.value == 'Y' || value.value == true ? true : false;
        }

        return value;
    };

    /**
     * 编辑性 显示性 禁用性 选中 聚焦等
     * @description: 获取计算之后的 单元格属性
     * @param {type}
     * @return:
     */
    getCellStatus = (props, state) => {
        let {
            record,
            config: { // 表格与列合并属性
                status = "browse",
                editType = "toggle",

                disabled = false,
                visible = true,
                // 单元格
                selected = false,
                focused = false,
                actived = false,
                // 单元格属性
                itemtype,
                // 特殊情况
                render,
                // 图标 // static hover none
                cellIcon,
                // 禁用编辑态
                disableEditType = false,
                renderStatus = "edit",
                renderItem,
                // page上的全局的
                // renderItems = {},
                attrcode,
                store,
            },
        } = props;
        // 行属性
        let {
            disabled: rowDiabled = false,
            visible: rowVisible = true,
            isEdit: rowIsEdit = false,
            selected: rowSelected = false,
            focused: rowFocused = false,
        } = record || {};

        let { showActiveDom } = state;
        // values
        let values = record.values || record || {};
        // 单元格
        let {
            // 不能默认值  避免以单元格为主报错
            disabled: cellDisabled,
            visible: cellVisible = true,
            isEdit: cellIsEdit = false,
            selected: cellSelected = false,
            focused: cellFocused = false,
            actived: cellActived = false,
            showDrag = false,
            validated = true,
            leftLine = false,
            rightLine = false,
            bottomLine = false,
            topLine = false,
            focusToEdit = false,
            autoFocus,
        } = values[attrcode] || {};

        // 部分状态从store取，避免频繁更新整表
        if (store) {
            // 获取单元格属性 从store里获取可以避免再次更新
            let cellProps = store.getCellProps({ rowKeyValue: this.rowKeyValue, attrcode }) || {};
            actived = !!cellProps.actived;
            showDrag = !!cellProps.showDrag;
            leftLine = !!cellProps.leftLine;
            rightLine = !!cellProps.rightLine;
            topLine = !!cellProps.topLine;
            bottomLine = !!cellProps.bottomLine;
            focusToEdit = !!cellProps.focusToEdit;
        }

        // 错误行状态
        let isSagaStatus = values && values.saga_status && values.saga_status.value === '1';

        // 兼容旧代码 TODO
        let oldCellDisabled = isString(cellDisabled)
            ? cellDisabled === "off"
            : cellDisabled;

        // 禁用性 如果最外层禁用了 内层不能启用  (!!render || 不确定有用)
        disabled = isBoolean(cellDisabled)
            ? oldCellDisabled
            : disabled || oldCellDisabled;

        // 显示性 如果最外层不显示了 内层 不显示
        visible = visible && rowVisible && cellVisible;

        // 激活性 用于框选 批改等操作
        actived = actived || cellActived;

        // 表格编辑性
        let tableEdit = status === "edit";

        // 编辑的类型
        editType = editType === "toggle" ? "toggle" : "normal";

        // 是否选中
        selected = selected || rowSelected || cellSelected;

        // 是否聚焦
        focused = rowFocused || cellFocused;

        // 是否是switch 类型
        let isSwitchType =
            itemtype === "switch_browse" ||
            itemtype === "checkbox_switch" ||
            itemtype === "switch";
        // 是否是switch 类型
        let isCheckboxSwitchType = itemtype === "checkbox_switch";

        // 单元格的编辑性
        let editable = itemtype === "switch_browse" ? true : tableEdit && !disabled;

        // 单元格的编辑状态
        let isEdit = rowIsEdit || cellIsEdit;

        editType = itemtype === "switch_browse" ||
            (tableEdit && itemtype === "checkbox_switch") ||
            (tableEdit && itemtype === "switch")
            ? "normal" : "toggle";

        // cellIcon
        cellIcon = (render && itemtype === "customer") || isSwitchType ? "none" : cellIcon || "hover";

        // switch_browse 类型 编辑态禁用  浏览态不禁用
        if (itemtype === "switch_browse") {
            if (tableEdit) {
                disabled = true;
            }
        }

        // label 类型没有编辑态
        if (itemtype === "label") {
            // disabled = true;
            editable = false;
        }

        // attachment 类型没有编辑态
        let isAttachment = false,
            disableModify = false;
        if (itemtype === "attachment") {
            editable && (disableModify = true);
            isAttachment = true;
            editable = true;
            editType = 'normal';
            // 编辑图标
            cellIcon = 'none';
        } else if (disableEditType) {
            // 给列表用
            editable = false;
            editType = 'toggle';
        }

        // 记录当前单元格渲染状态
        let renderType = 'normal';
        let isRender = !!render;
        if (renderItem) {
            renderType = 'renderInItem';
        } else if (isRender && itemtype === "customer") {
            renderType = 'renderInCustomer';
        } else if (isRender && renderStatus == 'edit') {
            renderType = 'renderInEdit';
        } else if (isRender && renderStatus == 'browse') {
            renderType = 'renderInBrowse';
        }

        let renderItemInEdit = false;
        if (disableEditType) {
            renderItemInEdit = false;
        } else if ((tableEdit && itemtype != 'label' && itemtype != 'customer') || (itemtype === "switch_browse")) {
            renderItemInEdit = true;
        }

        return {
            disabled,
            tableEdit,
            isEdit,
            editable,
            editType,
            visible,
            selected,
            focused,
            actived,
            showDrag,
            cellIcon,
            isAttachment,
            disableModify,
            validated,
            leftLine,
            rightLine,
            bottomLine,
            topLine,
            focusToEdit,
            renderType,
            isSwitchType,
            isCheckboxSwitchType,
            autoFocus,
            isSagaStatus,
            renderItemInEdit,
        };
    };

    /**
     * @description:
     * @param {type}
     * @return:
     */
    getCellClassName = () => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性

                // 表格整体的属性
                bodyDisplayInRow = true,
                // 全部展开
                bodyExpandAllInRow = false,
                // 列属性
                attrcode,
                itemtype,
                cellClassName,
                store,
            },
        } = this.props;

        // 计算状态
        let {
            disabled, tableEdit, editable, selected, focused,
            cellIcon, actived, validated, focusToEdit,
            leftLine,
            rightLine,
            bottomLine,
            topLine,
            isCheckboxSwitchType,
        } = this.cellStatus;

        // 单元格状态
        let { showIcon, showActiveDom } = this.state;

        let extendClassName = isFunction(cellClassName) ? (cellClassName({ record, rowIndex, attrcode }) || '') : cellClassName;

        let statusClassName = (tableEdit ? ' table-status-edit' : '') +
            (actived ? ' table-cell-actived' : '') +
            (focusToEdit ? ' table-cell-focus-blue' : '') +
            (focused ? ' table-cell-focused' : '') +
            (disabled ? ' table-cell-disabled' : '') +
            (editable ? ' table-cell-edit' : ' table-cell-browse ') +
            (actived || showActiveDom || (inFireFox && (validated === false || disabled)) ? ' table-cell-bg-show' : '') +
            (validated === false ? ' table-cell-valid-failed' : '') + '';

        let cellBgBdClassName = " table-cell-wrapper-bg " +
            (topLine ? ' table-cell-line-top' : '') +
            (leftLine ? ' table-cell-line-left' : '') +
            (rightLine ? ' table-cell-line-right' : '') +
            (bottomLine ? ' table-cell-line-bottom' : '') +
            statusClassName;

        // 单元格className
        let cellWrapperClassName = ' table-cell-wrapper ' +
            extendClassName +
            (isIE() ? 'table-cell-ie-wrapper ':' ') +
            ' cell-icon-' + cellIcon +
            (selected ? ' table-cell-selected ' : '  ') +
            statusClassName +
            (isCheckboxSwitchType ? ' table-cell-checkbox-switch ' : '') +
            (tableEdit && showIcon && cellIcon !== 'none' ? ' table-cell-show-icon' : '');

        // cellContentClassName
        let cellContentClassName = " cell-content " +
            (bodyDisplayInRow ? ' cell-ellipsis' : bodyExpandAllInRow ? ' cell-expand-all' : ' cell-fold-display') +
            (editable ? ' cell-edit' : '') +
            (itemtype === 'number' ? ' number-right ' : ' ');
        return {
            cellBgBdClassName,
            cellContentClassName,
            cellWrapperClassName,
        };
    }

    setFieldValue = ({ attrcode, value }) => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性

                // 表格整体的属性
                rowKey,
                setCellValue,
                // 当前列的属性
                __col,
                itemtype,
            },
        } = this.props;

        // 单元格的值
        let rowKeyValue = this.rowKeyValue;

        // 获取事件类型, 可以放到全局, 但是对单元格渲染有影响
        let defaultItemCreator =
            itemCreator({ itemtype }) || {};

        // 这里就是onChange
        return (
            isFunction(setCellValue) &&
            setCellValue({
                record,
                rowKey,
                rowKeyValue,
                rowIndex,
                attrcode,
                value,
                column: { ...__col, ...this.cellStatus },
                triggerType: defaultItemCreator["valueChangePropName"] || "onChange",
            })
        );
    }

    getFieldValue = ({ attrcode }) => {
        // return this.cellValue;
        return this.getValue(this.props, attrcode, this.cellScale, this.cellStatus.editable);
    }

    onBeforeEvent = async ({ value, event }) => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 表格整体的属性
                rowKey,
                // 列属性
                attrcode,
                onBeforeEvent,
                store,
                // 当前列的属性
                __col,
                itemtype,
            },
        } = this.props;
        // 属性
        let { validated } = this.cellStatus;

        // 单元格的值
        let rowKeyValue = this.rowKeyValue;

        // 获取事件类型, 可以放到全局, 但是对单元格渲染有影响
        let defaultItemCreator =
            itemCreator({ itemtype }) || {};

        store && store.setCellProps(rowKeyValue, attrcode, { validated: true }, false);
        // 有一些操作要放在这里处理 比如值的转换等等
        // 清理错误校验的样式
        validated === false && store && store.setCellProps(rowKeyValue, attrcode, { validated: true }, false);

        if (isFunction(onBeforeEvent)) {
            let isContinue = (
                (await onBeforeEvent({
                    record,
                    rowKey,
                    rowKeyValue,
                    rowIndex,
                    attrcode,
                    value,
                    event,
                    column: { ...__col, ...this.cellStatus },
                    triggerType: defaultItemCreator["beforeEventTrigger"] || "onFocus",
                    validated,
                })) !== false
            )
            // 这里做更新会导致 编辑前如果返回了false  出现问题
            // isContinue === false && this.setState({ showIcon: isContinue });
            return isContinue;
        }
        return true;
    }

    onAfterEvent = ({ value, oldValue, componentValue, event }) => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性

                // 表格整体的属性
                rowKey,
                // 列属性
                attrcode,
                onAfterEvent,
                // 当前列的属性
                __col,
                itemtype,
            },
        } = this.props;

        // 获取事件类型, 可以放到全局, 但是对单元格渲染有影响
        let defaultItemCreator =
            itemCreator({ itemtype }) || {};

        // 单元格的值
        let rowKeyValue = this.rowKeyValue;

        this.setState({ autoOpen: false });
        // 有一些操作要放在这里处理 比如值的转换等等
        isFunction(onAfterEvent) &&
            onAfterEvent({
                record,
                rowKey,
                rowKeyValue,
                rowIndex,
                attrcode,
                value,
                componentValue,
                event,
                column: { ...__col, ...this.cellStatus },
                oldValue,
                triggerType:
                    defaultItemCreator["afterEventTrigger"] ||
                    "onBlur",
            });
    }

    onStatusChange = ({ isEdit }) => {
        let {
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 表格整体的属性
                // 列属性
                attrcode,
                onCellStatusChange,
                store,
                // 当前列的属性
                __col,
            },
        } = this.props;

        // 单元格的值
        let rowKeyValue = this.rowKeyValue;
        let selectable = {};
        if (this.cellStatus.showDrag && !isEdit) {
            selectable = {
                showDrag: false,
                topLine: false,
                leftLine: false,
                actived: false,
                bottomLine: false,
                rightLine: false,
            }
        }
        store && store.setCellProps(rowKeyValue, attrcode, { focusToEdit: isEdit, ...selectable }, false);
        this.setState({ autoOpen: !isEdit ? false : this.state.autoOpen, showIcon: !isEdit, isEdit, showActiveDom: isEdit });
        if (isFunction(onCellStatusChange)) {
            onCellStatusChange({ isEdit, column: { ...__col, ...this.cellStatus }, rowIndex, attrcode, rowKeyValue })
        }
    }

    renderItem = ({ isEdit }) => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性

                // 列属性
                attrcode,
                renderStatus = "edit",
                renderItem,
                // page上的全局的
                // renderItems = {},
                // renderItem,
                render,
                store,
                __col,
                getTrueRowIndex,
            },
        } = this.props;
        // 属性
        let {
            renderItemInEdit,
            isSwitchType,
        } = this.cellStatus;

        let cellValue = this.cellValue;

        let specialColumn = attrcode === 'checkbox' || attrcode === 'numberindex';

        // 取一下
        // renderItem = renderItems[attrcode];

        rowIndex = !specialColumn && (render || renderItem) ? getTrueRowIndex(this.rowKeyValue, rowIndex, record) : rowIndex;

        if (render && renderStatus === "customer") {
            let cellEdit = (isEdit || isSwitchType);
            return render(
                cellValue,
                record,
                rowIndex,
                store,
                renderItemInEdit ? (cellEdit ? "edit" : "editBrowse") : "browse",
                __col,
            );
        }

        // 如果表格是编辑态
        if (renderItemInEdit) {
            // 单元格编辑态
            let cellEdit = (isEdit || isSwitchType);
            if (cellEdit && render && renderStatus === "edit") {
                return render(
                    cellValue,
                    record,
                    rowIndex,
                    store,
                );
            }

            if (!cellEdit && render && renderStatus === "editBrowse") {
                return render(
                    cellValue,
                    record,
                    rowIndex,
                    store,
                );
            }

            // 单元格编辑态
            if (cellEdit && renderItem) {
                return isFunction(renderItem)
                    ? renderItem.call(
                        null,
                        cellValue,
                        record,
                        rowIndex,
                        store,
                    )
                    : renderItem;
            }
        } else {
            // 如果表格是浏览态
            // 单元格自定义渲染了
            // 如果表格是编辑态 那么label类型和customer类型也可以走这里逻辑
            if (render && renderStatus === "browse") {
                let customRender = render(
                    cellValue,
                    record,
                    rowIndex,
                    store,
                );
                if (!isValidElement(customRender)) {
                    if (isObject(customRender)) {
                        console.error('invalid react element', customRender);
                        customRender = JSON.stringify(customRender);
                    }
                    return <div className="use-customer-cell un-react-element">{customRender}</div>;
                }
                return customRender;
            }
        }
    }

    getCustomerRender = () => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                itemtype,
                hyperlinkflag,
                onCellHyperlinkTo,
                // 使用什么来渲染浏览态
                render,
                renderStatus,
                store,
                // 不传到  item 避免影响底层组件的参数
                // =====================
                // 当前列的属性
                __col,
                getTrueRowIndex,
            },
            // 用 ... this.props 会导致参照里的参数出现rowKey覆盖问题
        } = this.props;

        // 属性
        let {
            // 表格状态
            tableEdit,
            editable,
        } = this.cellStatus;

        // 单元格的值
        let cellValue = this.cellValue;

        // 自定义单元格
        if (render && itemtype === "customer") {

            let specialColumn = attrcode === 'checkbox' || attrcode === 'numberindex';

            // 取一下
            // renderItem = renderItems[attrcode];

            rowIndex = !specialColumn && render ? getTrueRowIndex(this.rowKeyValue, rowIndex, record) : rowIndex;

            // 没有设置单元格状态  比如customer列
            let customRender = render(
                cellValue,
                record,
                rowIndex,
                store,
            );

            if (!customRender) {
                return <div />;
            }

            if (!isValidElement(customRender)) { // 不是标准的react组件
                if (isObject(customRender)) {
                    console.error('invalid react element', customRender);
                    customRender = JSON.stringify(customRender);
                }
                return <div className="use-customer-cell un-react-element">{customRender}</div>;
            }

            // 还有一些事件要合并进来 TODO
            let content = {
                ...customRender,
                props: {
                    ...customRender.props,
                    renderInTable: true,
                },
            };

            return <div {...__col} className={" use-customer-cell " + __col.className}>{cloneElement(content)}</div>;
        }

        // 超链接
        if (!(render && renderStatus === 'browse') && hyperlinkflag && !editable && !tableEdit) {
            // 不在浏览态显示 TODO style 样式去除
            return (
                <span style={{ overflow: "hidden", display: 'flex', width: '100%' }}>
                    <a
                        href="javascript:;"
                        className='cell-hyper'
                        onClick={e => {
                            e.stopPropagation();
                            //超链接跳转
                            isFunction(onCellHyperlinkTo) && onCellHyperlinkTo(record, attrcode, cellValue);
                        }}
                    >
                        {cellValue.display || cellValue.value || ""}
                    </a>
                </span>
            );
        }
    };

    /**
     * @description: 获取单元格UI
     * @param {type}
     * @return:
     */
    getCellUI = () => {
        let {
            record,
            rowIndex,
            config: {

                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                itemtype,
                // 使用什么来渲染浏览态
                whichKeyToDisplay,
                // 单元格自身属性
                // 不传到  item 避免影响底层组件的参数
                // =====================
                onEnter,
                hotKeyWithArrow,
                // className 不能传递到底层
                maxlength,
                rowKey,
                // 用 ... this.props.config 会导致参照里的参数出现rowKey覆盖问题
                ...others
            },
        } = this.props;

        // 属性
        let {
            disabled,
            editType,
            editable,
            disableModify,
            autoFocus,
            isSagaStatus,
        } = this.cellStatus;
        // state
        let { autoOpen } = this.state;

        // 单元格的值
        let rowKeyValue = this.rowKeyValue;
        let cellScale = this.cellScale;

        autoOpen = autoOpen ? {
            open: autoOpen,
            autoShow: autoOpen,
        } : {};

        return (
            <Item
                {...others}
                record={record}
                rowIndex={rowIndex}
                // {...this.props}
                // 不能直接传open 会使底层组件受控
                {...autoOpen}
                autoFocus={autoFocus}
                isCheckChinese={!!maxlength}
                maxlength={maxlength}
                renderInTable
                disabled={disabled}
                scale={cellScale}
                browse={!editable || isSagaStatus}
                whichKeyToDisplay={whichKeyToDisplay}
                itemtype={itemtype}
                attrcode={attrcode}
                // tabIndex={!disabled && editable && !isSagaStatus ? 0 : -1}
                editType={editType}
                disableModify={disableModify}
                onEnter={onEnter}
                hotKeyWithArrow={hotKeyWithArrow}
                setFieldValue={this.setFieldValue}
                getFieldValue={this.getFieldValue}
                onBeforeEvent={this.onBeforeEvent}
                onAfterEvent={this.onAfterEvent}
                onStatusChange={this.onStatusChange}
                path={[rowKeyValue, attrcode]}
                // 自定义单元格
                renderItem={this.renderItem}
            />
        );
    }

    // 鼠标移入单元格
    handleMouseEnter = () => {
        if (!this.cellDom) {
            let $wrapper = ReactDOM.findDOMNode(this);
            this.cellDom = $wrapper && $wrapper.parentElement;
        }
        let {
            config: {
                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                itemtype,
                render,
            },
        } = this.props;

        // 计时执行
        // this.timer = setTimeout(() => {
        if (this.cellDom) {
            let { showTip } = this.state;
            let tipNode = null;
            tipNode = this.cellDom.getElementsByClassName('template-item-wrapper-inner')[0]
            if (tipNode) {
                if ((tipNode.scrollWidth > tipNode.clientWidth || tipNode.offsetHeight > 30) && showTip === false) {
                    if ((attrcode === 'opr' && itemtype === 'customer' && render)) {
                        this.setState({ showTip: false, showActiveDom: true });
                        return
                    }
                    let tooltipDelay;
                    // 操作列在字体变大之后会出现提示这里去除下
                    tipNode.scrollWidth > 4000 ? tooltipDelay = false : tooltipDelay = true
                    this.setState({ showTip: true, showActiveDom: true, tooltipDelay });
                    return;
                }
            }
        }
        this.setState({ showActiveDom: true });
        // }, 100)
    }

    // 鼠标移出单元格
    handleMouseLeave = () => {
        // 计时清理
        clearTimeout(this.timer);
        // this.setState({ showActiveDom: false });
    }

    // 点击事件处理逻辑
    handleCellClick = (tag, e) => {
        if (!this.cellDom) {
            let $wrapper = ReactDOM.findDOMNode(this);
            this.cellDom = $wrapper && $wrapper.parentElement;
        }
        if (this.cellDom) {
            let $focusElement = this.cellDom.querySelector('.template-item-wrapper.template-item-browse[tabindex="0"]');
            if (tag == 'picker' || tag == 'select' || tag == 'refer') {
                this.setState({ autoOpen: true });
            }

            if ($focusElement) {
                // 自动增行 触发编辑前事件
                $focusElement.ncExecuteFocus = true;
                // console.log($focusElement, 'xxx');
                this.cellDom.contains(e.target) && $focusElement.focus();
                // TODO 可能会有内存泄露
                $focusElement.ncExecuteFocus = false;
            }
        }
    }

    // 单元格点击事件
    onCellClick = e => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                onTableCellClick,
                __col,
            },
        } = this.props;
        // 计算状态
        let { isSagaStatus } = this.cellStatus;
        if (isSagaStatus) {
            return;
        }
        // 这里主要给自动化测试用
        this.handleCellClick('wrapper', e);

        if (onTableCellClick) {
            onTableCellClick(record, rowIndex, attrcode, __col, e);
        }
    }

    onCellKeyDown = e => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                itemtype,
                onCellKeyDown,
                onEnter,
                hotKeyWithArrow,
                __col,
            },
        } = this.props;

        // 计算状态
        let { focusToEdit } = this.cellStatus;
        if (!focusToEdit && e.key === 'Enter') {
            e.stopPropagation();
            onEnter({ e, target: e.target, itemType: itemtype });
        }
        if (!focusToEdit && isArrowKeyDown(e)) {
            e.stopPropagation();
            hotKeyWithArrow && hotKeyWithArrow({ e, target: e.target, itemType: itemtype });
        }
        return onCellKeyDown && onCellKeyDown(record, rowIndex, attrcode, __col, e);
    }

    onCellMouseDown = e => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                onCellMouseDown,
                __col,
            },
        } = this.props;
        return onCellMouseDown && onCellMouseDown(record, rowIndex, attrcode, __col, e);
    }

    onCellFocus = e => {
        let {
            record,
            rowIndex,
            config: {
                // 单元格beeTable render返回的属性
                // 列属性
                attrcode,
                onCellFocus,
                __col,
            },
        } = this.props;

        if (!this.cellDom) {
            let $wrapper = ReactDOM.findDOMNode(this);
            this.cellDom = $wrapper && $wrapper.parentElement;
        }
        // 添加一个样式
        this.cellDom && (this.cellDom.className += ' table-cell-focused');
        return onCellFocus && onCellFocus(record, rowIndex, attrcode, __col, e);
    }

    onCellBlur = e => {
        if (!this.cellDom) {
            let $wrapper = ReactDOM.findDOMNode(this);
            this.cellDom = $wrapper && $wrapper.parentElement;
        }
        this.cellDom && (this.cellDom.className = this.cellDom.className.replace('table-cell-focused', ''));
    }

    render() {

        let {
            record,
            config: {
                // 列属性
                attrcode,
                itemtype,
                disableEditType,
                __col,
                langJson,
                cellFieldId,
                whichKeyToDisplay,
                hyperlinkflag,
                customTips = false,
                loginLanguage,
            },
        } = this.props;

        // 计算状态
        let {
            disabled,
            editable,
            cellIcon,
            showDrag,
            renderType,
            isSagaStatus,
            actived,
            validated,
        } = this.cellStatus;

        // 单元格状态
        let {
            showIcon,
            tooltipDelay,
            showTip,
            showActiveDom,
            isEdit,
        } = this.state;

        //console.log("单元格渲染")

        let cellUI = '';
        let tipContent = '';

        if (editable || itemtype === "attachment") {
            // 浏览态
            if (showTip) {
                tipContent = handleBrowseData(itemtype,
                    this.cellValue.display,
                    this.cellValue.value,
                    this.cellScale,
                    attrcode,
                    __col.languageMeta,
                    record.values || record,
                    __col.datatype,
                    __col.options,
                    __col.multiple,
                    langJson,
                    whichKeyToDisplay, loginLanguage);
            }
            cellUI = this.getCustomerRender() || this.getCellUI();
            if (!cellUI) {
                return <div />;
            }
        } else {
            let customer = this.getCustomerRender() || this.renderItem({ isEdit: false });
            if (customer) {
                cellUI = customer;
                if (showTip) {
                    tipContent = hyperlinkflag ? handleBrowseData(itemtype,
                        this.cellValue.display,
                        this.cellValue.value,
                        this.cellScale,
                        attrcode,
                        __col.languageMeta,
                        record.values || record,
                        __col.datatype,
                        __col.options,
                        __col.multiple,
                        langJson,
                        whichKeyToDisplay, loginLanguage) : cellUI;
                }
            } else {
                cellUI = handleBrowseData(itemtype,
                    this.cellValue.display,
                    this.cellValue.value,
                    this.cellScale,
                    attrcode,
                    __col.languageMeta,
                    record.values || record,
                    __col.datatype,
                    __col.options,
                    __col.multiple,
                    langJson,
                    whichKeyToDisplay, loginLanguage);
                if (showTip) {
                    tipContent = cellUI;
                }
            }
        }

        let {
            cellBgBdClassName,
            cellContentClassName,
            cellWrapperClassName,
        } = this.getCellClassName();

        if (disableEditType || !editable) {
            return <Fragment >
                {(actived || showActiveDom || (inFireFox && (validated === false || disabled))) &&
                    < div
                        className={cellBgBdClassName}
                        cellSelectorKey={this.cellSelectorKey}
                        data-code={attrcode}
                    >
                        <div className="left-line line-line-line"></div>
                        <div className="right-line line-line-line"></div>
                        <div className="top-line line-line-line"></div>
                        <div className="bottom-line line-line-line"></div>
                    </div>
                }
                <div
                    fieldid={cellFieldId}
                    className={cellWrapperClassName}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.onCellClick}
                    // 给框选之类的使用
                    cellSelectorKey={this.cellSelectorKey}
                    data-type={itemtype}
                    data-code={attrcode}
                    data-renderType={renderType}
                    onKeyDown={this.onCellKeyDown}
                    // 快捷键
                    onMouseDown={this.onCellMouseDown}
                >
                    {/*  && this.state.isEdit */}
                    {(customTips || editable || !showTip) ? <div
                        className={cellContentClassName}
                    >
                        <div className="template-item-wrapper-inner">{cellUI}</div>
                    </div> :
                        <Tooltip
                            placement="rightBottom"
                            overlay={tipContent}
                            className="tooltip-word-color high-table-tooltip"
                            delay={tooltipDelay ? 1 : undefined}
                            inverse>
                            <div
                                className={cellContentClassName}
                            >
                                <div className="template-item-wrapper-inner"> {cellUI}</div>
                            </div>
                        </Tooltip>
                    }
                </div >
            </Fragment >
        }
        return <Fragment>
            {
                (actived || showActiveDom || (inFireFox && (validated === false || disabled))) && <div
                    className={cellBgBdClassName}
                    cellSelectorKey={this.cellSelectorKey}
                    data-code={attrcode}
                    {...this.autoFocusIdentifier}
                    onClick={e => this.handleCellClick('wrapperBg', e)}
                >
                    <div className="left-line line-line-line"></div>
                    <div className="right-line line-line-line"></div>
                    <div className="top-line line-line-line"></div>
                    <div className="bottom-line line-line-line"></div>
                </div>
            }
            <div
                fieldid={cellFieldId}
                {...this.autoFocusIdentifier}
                className={cellWrapperClassName}
                ref={this.refHandle}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.onCellClick}
                // 给框选之类的使用
                cellSelectorKey={this.cellSelectorKey}
                data-type={itemtype}
                data-code={attrcode}
                data-renderType={renderType}
                onKeyDown={this.onCellKeyDown}
                // 快捷键
                onMouseDown={this.onCellMouseDown}
                onFocus={this.onCellFocus}
                onBlur={this.onCellBlur}
            >
                {/*  isEdit */}
                {(customTips || !showTip) ? <div
                    className={cellContentClassName}
                >
                    {cellUI}
                </div> :
                    <Tooltip
                        placement="rightBottom"
                        // 如果单元格是编辑态了，不展示
                        overlay={isEdit ? '' : tipContent}
                        trigger={"hover"}
                        className="tooltip-word-color high-table-tooltip"
                        delay={tooltipDelay ? 1 : undefined}
                        inverse
                    >
                        <div
                            className={cellContentClassName}
                        >
                            {cellUI}
                        </div>
                    </Tooltip>
                }
                {editable && !isSagaStatus && showIcon && <CellIcon
                    itemtype={itemtype}
                    attrcode={attrcode}
                    value={this.cellValue.value ?? this.cellValue.display}
                    disabled={disabled}
                    cellIcon={cellIcon}
                    onClick={this.handleCellClick}
                />}
            </div >
            {
                !disabled && showDrag && !isSagaStatus && <div
                    cellDrag="on"
                    cellSelectorKey={this.cellSelectorKey}
                    className="table-cell-drag-icon iconfont"
                ></div>
            }
        </Fragment >;
    }
}

export default Cell;