import CONFIG from '../../config';
import { isFunction, isUndefined } from '../../utils/index';
import { Subtr, isNullOrVoid } from '@platform/api';


/**
 * 处理多表头
 * @param {object} column 
 */
export function handleMeta(column) {
    if (column.children) {
        column.children.map(child => {
            if (child.children) {
                return handleMeta(child);
            }
            return handleChildMeta(child);
        })
    } else {
        return handleChildMeta(column);
    }

}
/**
 * 给meta添加sorter
 * @param {object} item 
 */
function handleChildMeta(item) {
    let {
        attrcode: ICode,
        itemtype: IType,
        isSort = true,
        sorter: Isorter,
    } = item;

    if (isSort && !isFunction(Isorter)) {
        switch (true) {
            case IType === 'number':
                item.sorter = (front, behind) => {
                    let prev = front.values || front;
                    let next = behind.values || behind;
                    let frontValue = String(prev[ICode] ? prev[ICode].value || '0' : '0');
                    let behindValue = String(next[ICode] ? next[ICode].value || '0' : '0');
                    return Subtr(Number(frontValue.replace(/,/g, '')), Number(behindValue.replace(/,/g, '')));
                };
                break;
            case CONFIG.timeTypes.includes(IType):
                item.sorter = (front, behind) => {
                    let prev = front.values || front;
                    let next = behind.values || behind;
                    let frontValue = prev[ICode] ? prev[ICode].value || '' : '';
                    let behindValue = next[ICode] ? next[ICode].value || '' : '';
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case CONFIG.displayTypes.includes(IType):
                item.sorter = (front, behind) => {
                    let prev = front.values || front;
                    let next = behind.values || behind;
                    let frontValue = String(prev[ICode] ? prev[ICode].display || '' : '');
                    let behindValue = String(next[ICode] ? next[ICode].display || '' : '');
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case IType === 'label':
                item.sorter = (front, behind) => {
                    let prev = front.values || front;
                    let next = behind.values || behind;
                    let fvalue = prev[ICode] ? prev[ICode].value || '' : '';
                    let fdisplay = prev[ICode] ? prev[ICode].display : '';
                    let bvalue = next[ICode] ? next[ICode].value || '' : '';
                    let bdisplay = next[ICode] ? next[ICode].display : '';
                    const frontValue = String(isNullOrVoid(fdisplay) ? fvalue : fdisplay);
                    const behindValue = String(isNullOrVoid(bdisplay) ? bvalue : bdisplay);
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case ICode !== 'numberindex' && ICode !== 'opr' && ICode !== 'checkbox' && ICode !== 'blankrow':
                item.sorter = (front, behind) => {
                    let prev = front.values || front;
                    let next = behind.values || behind;
                    let frontValue = String(prev[ICode] ? prev[ICode].value || '' : '');
                    let behindValue = String(next[ICode] ? next[ICode].value || '' : '');
                    return frontValue.localeCompare(behindValue);
                };
                break;
        }
    }

}

// 处理多头排序标识
export function handleSortcolumns({ columns, sortObj }) {
    columns.forEach(eve => {
        //获取合计行的列配置
        const { children } = eve;
        if (!isUndefined(children)) {
            //判断和并列的情况
            handleSortcolumns({ columns: children, sortObj });
        } else {
            columns.forEach(item => {
                //保存返回的column状态，没有则终止order状态
                if (sortObj[item.attrcode]) {
                    item.order = sortObj[item.attrcode].order;
                    item.orderNum = sortObj[item.attrcode].orderNum;
                } else {
                    item.order = 'flatscend';
                    item.orderNum = '';
                }
            });
        }
    });
}