/*
 * @Descripttion: 表格的tab键 enter键 上下左右键
 * @version: 
 * @Author: bbq
 * @Date: 2020-09-05 09:49:57
 * @LastEditors: bbq
 * @LastEditTime: 2020-12-22 14:31:59
 */

import React, { Component } from 'react';
import { ViewModel, isFunction, isArray, isArrowKeyDown } from '@platform/api';
import { WithKeyboardChange, WithAutoFocus } from '@platform/base';

const { TableWithAutoFocus } = WithAutoFocus;
const { findNextFocusItemInTable, EditTableWithKeyboardChange, TableWithKeyboardChange } = WithKeyboardChange;

import './index.less';
/**
* 快捷键逻辑处理
* @param {*} Table 
*/
export default function hotkey(Table, componentParams) {

    if (!Table) {
        console.error('缺少Table组件');
    }

    class MetaTable extends Component {
        constructor(props) {
            super(props);
        }

        // click
        handleTableCellClick(record, index, attrcode, col, e) {
            this.mousedown = false;
        }

        // cell 点击时
        handleCellMouseDown(record, index, attrcode, col, e) {
            this.mousedown = true;
        }

        render() {
            let {
                rowKey,
                onCellMouseDown,
                onCellFocus,
                onCellKeyDown,
                onTableCellClick,
                onEnter,
                hotKeyWithArrow,
                hotKeyWithEnter,
                high_hotkey,
                ...others
            } = this.props;
            return <Table
                onCellMouseDown={(record, index, attrcode, col, e) => {
                    let res = onCellMouseDown ? onCellMouseDown(record, index, attrcode, col, e) : true;
                    return res !== false && this.handleCellMouseDown(record, index, attrcode, col, e);
                }}
                onCellFocus={(record, index, attrcode, col, e) => {
                    let res = onCellFocus ? onCellFocus(record, index, attrcode, col, e) : true;
                }}
                onCellKeyDown={(record, index, attrcode, col, e) => {
                    let res = onCellKeyDown ? onCellKeyDown(record, index, attrcode, col, e) : true;
                }}
                onTableCellClick={(record, index, attrcode, col, e) => {
                    let res = onTableCellClick ? onTableCellClick(record, index, attrcode, col, e) : true;
                    return res !== false && this.handleTableCellClick(record, index, attrcode, col, e);
                }}
                onEnter={({ e, target = undefined, itemType }) => {
                    const { tableId } = high_hotkey;
                    hotKeyWithEnter({ e, ViewModel, moduleId: tableId, target, itemType: itemType });
                }}
                hotKeyWithArrow={({ e, target = undefined, itemType }) => {
                    const { tableId } = high_hotkey;
                    hotKeyWithArrow({ e, ViewModel, moduleId: tableId, target, itemType: itemType });
                }}
                rowKey={rowKey}
                {...others}
            />
        }
    }

    // 自动聚焦
    // MetaTable = TableWithAutoFocus(MetaTable);
    // 上下键 enter键
    // console.log(componentParams);
    const { name, type, user } = componentParams;
    return TableWithAutoFocus(name === 'editTable' ? EditTableWithKeyboardChange(MetaTable) : TableWithKeyboardChange(MetaTable));
}