/*
 * @Descripttion: 行选中的逻辑整理
 * @version:
 * @Author: bbq
 * @Date: 2020-06-15 15:30:43
 * @LastEditors: bbq
 * @LastEditTime: 2020-07-30 13:43:55
 */

import React, { Component, Fragment } from 'react';

import { isFunction, isArray } from '@platform/api';

import './index.less';

const Closest = function (el, selector) {
    if (!el) {
        return null;
    }
    var matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

    while (el) {
        if (matchesSelector.call(el, selector)) {
            break;
        }
        el = el.parentElement;
    }
    return el;
};

/**
* 行选中逻辑处理
* @param {*} Table 
*/
export default function selectRow(Table) {
    if (!Table) {
        console.error('缺少Table组件');
    }
    return class MetaTable extends Component {
        constructor(props) {
            super(props);
            this.state = { selectedIndex: new Set() };
        }

        hasSelected(record, rowKey, rowKeyValue, rowIndex) {
            let { hasSelected } = this.props;

            if (isFunction(hasSelected)) {
                return hasSelected({ record, rowKey, rowKeyValue, rowIndex });
            } else {
                return this.state.selectedIndex.has(rowKeyValue || rowIndex);
            }
        }

        render() {
            let {
                rowKey,
                focusIndex,
                hasSelected,
                setSelectedIndex,
                rowSelectedCls = ' table-row-selected ',
                // disabledSelectRow = false,
                multiSelectRow = true, // 多选
                rowClassName,
                onRowClick,
                onRowDoubleClick,
                disableClickRowToSelectIndex,
                ...others
            } = this.props;
            return <Table
                rowKey={rowKey}
                currentIndex={focusIndex}
                rowClassName={(record, current) => {
                    let isSelected = this.hasSelected(record, rowKey, record[rowKey], current),
                        cls = '';

                    isSelected && (cls = rowSelectedCls)

                    cls += isFunction(rowClassName) ? rowClassName(record, current) : rowClassName;

                    return cls;
                }}
                onRowClick={(record, index, e) => {
                    if (isFunction(setSelectedIndex)) {
                        // 先处理样式
                        // 错误行禁用
                        let values = record.values || record;
                        if (
                            !values ||
                            !values.saga_status ||
                            values.saga_status.value !== "1"
                        ) {
                            // 如果禁用了就不选
                            if (!disableClickRowToSelectIndex) {
                                let $table = Closest(e.target, '.nc-table');
                                let rowKeyValue = record[rowKey].value || record[rowKey];
                                let $trs = $table && $table.querySelectorAll(`[data-row-key="${rowKeyValue}"]`)
                                // console.log(e, $trs);
                                if ($trs) {
                                    Array.from($trs).forEach(ele => {
                                        ele && (ele.className += rowSelectedCls);
                                    })
                                }
                            }
                        }
                        setTimeout(() => {
                            setSelectedIndex({ record, rowKey, rowIndex: index }, e);
                        }, 0);
                    } else {
                        let selectedIndexs = this.state.selectedIndex;
                        if (selectedIndexs.has(record[rowKey] || index)) {
                            selectedIndexs.delete(record[rowKey] || index);
                        } else {
                            !multiSelectRow && (selectedIndexs.clear());
                            selectedIndexs.add(record[rowKey] || index);
                        }
                        this.setState({ selectedIndex: this.state.selectedIndex });
                    }

                    isFunction(onRowClick) && onRowClick(record, index, e);
                }}
                onRowDoubleClick={(record, index, e) => {

                    if (isFunction(setSelectedIndex)) {
                        setSelectedIndex({ record, rowKey, rowIndex: index })
                    } else {
                        let selectedIndexs = this.state.selectedIndex;
                        if (selectedIndexs.has(record[rowKey] || index)) {
                            selectedIndexs.delete(record[rowKey] || index);
                        } else {
                            !multiSelectRow && (selectedIndexs.clear());
                            selectedIndexs.add(record[rowKey] || index);
                        }
                        this.setState({ selectedIndex: this.state.selectedIndex });
                    }

                    isFunction(onRowDoubleClick) && onRowDoubleClick(record, index, e);
                }}
                {...others}
            />
        }
    }
}