/*
 * @Descripttion: 标题
 * @version: 
 * @Author: bbq
 * @Date: 2020-06-01 09:53:27
 * @LastEditors: bbq
 * @LastEditTime: 2021-02-02 16:50:02
 */
import React, { Component, Fragment } from 'react';
import { isFunction, isIE } from '../../utils/index';
import classNames from 'classnames';
import { getSysFieldid } from "@platform/api";

require('./title.less');

const defaultColor = ['#111111', '#555555', '#111', '#555'];
export default class ColumnTitle extends Component {

    constructor(props) {
        super(props);
    }

    getParentTypeTitle() {
        let { title, attrcode, isParent, itemtype, color, unitActive } = this.props;
        color = defaultColor.includes(color) ? '' : color;
        let titleClassName = (unitActive ? 'column-title-actived' : '') + '  column-title-wrapper-bg ';

        return <Fragment>
            <div
                className={classNames("column-title-wrapper", { 'column-title-actived': unitActive })}
                style={{ color }}
                cellSelectorKey={`${'__tableHeader__'}@${attrcode}`}>
                {title}
            </div>
            <div className={titleClassName} cellSelectorKey={`${'__tableHeader__'}@${attrcode}`}>

            </div>
        </Fragment>;
    }

    getNormalTypeTitle() {
        let { title, required, attrcode, itemtype, color, status = "browse", unitActive, titleIcon } = this.props;
        color = defaultColor.includes(color) ? '' : color;
        let titleClassName = (unitActive ? 'column-title-actived' : '') + '  column-title-wrapper-bg ';

        return <Fragment>
            <div
                className={classNames("column-title-wrapper", { 'column-title-actived': unitActive , 'column-title-ie-wrapper': isIE()  })}
                cellSelectorKey={`${'__tableHeader__'}@${attrcode}`}
                fieldid={getSysFieldid(attrcode)}
                style={itemtype === 'number' ? { width: '100%', textAlign: 'right', color } : { color }}
            >
                {status == 'edit' && required && <span className="mark-required">*</span>}
                {isFunction(title) ? title(this.props) : title}
            </div >
            {titleIcon && titleIcon(this.props)}
            <div className={titleClassName} cellSelectorKey={`${'__tableHeader__'}@${attrcode}`}></div>
        </Fragment>
    }

    render() {
        let { isParent } = this.props;
        // console.log(title, 'column title');
        return isParent ? this.getParentTypeTitle() : this.getNormalTypeTitle();
    }
}
