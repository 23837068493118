/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-08-07 16:24:53
 * @LastEditors: bbq
 * @LastEditTime: 2021-03-03 16:39:20
 */
import React from 'react';

require('./index.less');

const OFFSET = {
    x: 2,
    y: 2,
};

// 获取px
function getPx(value) {
    return Math.abs(value) + 'px';
}

// get nearest parent element matching selector   origin from stackoverflow  add by bbqin
const Closest = function (el, selector) {
    if (!el) {
        return null;
    }
    var matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

    while (el) {
        if (matchesSelector.call(el, selector)) {
            break;
        }
        el = el.parentElement;
    }
    return el;
};

/**
 * 获取父级可以滚动的元素 特指y轴
 * @param {DOM} el 
 */
const getScrollParentDOM = function (el) {
    if (!el) {
        return null;
    }
    while (el) {
        if (el.style.overflowY === 'scroll' || el === document.body) {
            break;
        }
        el = el.parentElement;
    }
    return el;
}

/**
 * 设置样式
 * @param {DOM} $dom 
 * @param {Object} style 
 */
const setStyle = function ($dom, style = {}) {
    for (const key in style) {
        if (style.hasOwnProperty(key)) {
            $dom.style[key] = style[key];
        }
    }
};

const isHasClass = function ($dom, name) {
    return $dom && name && $dom.className.indexOf(name) !== -1;
};

// 添加class
const addClass = function ($dom, name) {
    !isHasClass($dom, name) && ($dom.className += ` ${name}`);
};

// 移除class
const removeClass = function ($dom, name) {
    $dom && ($dom.className = $dom.className.replace(name, ''));
};

const isDomShow = function ($dom) {
    return $dom.style.display !== 'none';
};

const mouseButton = 0; // 鼠标左键

/**
 * 已知BUG 
 * 点击checkbox时  ctrl选中难以生效 猜测 可能是checkbox 自己的选中事件和本事件出现了冲突
 */

export default class Selectable extends React.Component {
    static defaultProps = {
        unitKey: 'key', // 行关键字 rowid
        canvas: '', // 绘制区域  默认是body
        onSelect: null, // 选中回调
        selector: 'tr', // 行选择器
        response: 15, // 响应距离
        disabled: false, // 可选值 true false 'shift' 'ctrl' 'slide'
        userSelect: false, // 启用用户文字选中
        single: false, // shif启用内置单选
    }

    constructor(props) {
        super(props);
        // 框选响应遮罩
        this.createSelectableShape();
        // 事件指定
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        // 当前组件的键位状态
        this.hotkey = null;
        this.drag = null;

        // 鼠标初始位置的记录
        this.startPoint = {
            x: 0,
            y: 0,
        };

        // receive 的初始值
        this.receiveItem = props.startItem;

        // 记录起始行和最终行
        this.startItem = props.startItem;
        this.endItem = null;

        // 记录当前选中的数据 针对方案一
        this.selectorItem = {};
        // 或者框选的行目前有两种方案 
        // 方案一  给cavans 上的tr都注册mouseEnter  mouseLeave 事件 实时记录经过的行
        // 方案二  渲染好框之后 计算位置 落在坐标之内的行记录下来
    }

    /**
     * 创建框选遮罩
     */
    createSelectableShape() {
        let shapeCls = 'mouse-selectable-shape';
        this.$selectableShape = document.querySelector(`.${shapeCls}`);
        if (this.$selectableShape) {
            return null;
        }
        let fragment = document.createDocumentFragment();
        let $selectableShape = document.createElement('div');
        $selectableShape.className = shapeCls;
        // 合并样式
        setStyle($selectableShape, this.props.style);
        fragment.appendChild($selectableShape);
        document.body.appendChild(fragment);
        this.$selectableShape = document.querySelector(`.${shapeCls}`);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // 如果传入的初始位置不为空  那么重新设置初始位置
        if (this.receiveItem != nextProps.startItem && nextProps.startItem) {
            this.startItem = nextProps.startItem;
        }

        this.props = { ...this.props, ...nextProps };

        let { userSelect, canvas } = this.props;
        let $canvas = document.querySelector(`${canvas}`);
        // 禁用鼠标选中 文字
        // !userSelect && $canvas && this.getEnabled('slide') && addClass($canvas, 'user-unselect');
    }

    componentDidMount() {
        let { canvas, selector, userSelect } = this.props;
        // 目标容器画布
        let $canvas = document.querySelector(`${canvas}`);
        // specialCanvas  用来越过tr的mouseDown 封锁

        document.body.addEventListener('mousedown', this.onMouseDown);
        document.body.addEventListener('mousemove', this.onMouseMove);
        document.body.addEventListener('mouseover', this.onMouseOver);
        document.body.addEventListener('mouseup', this.onMouseUp);
        // 禁用鼠标选中 文字
        // !userSelect && $canvas && this.getEnabled('slide') && addClass($canvas, 'user-unselect');

        if (!$canvas) {
            // console.log('特殊情况，初始化时没有找到对应dom');
            return;
        }
        let $item = $canvas.querySelector(selector);
        if ($item) {
            this.startItem = this.getItemKey($item);
        }
    }

    componentWillUnmount() {

        // 清理事件 
        document.body.removeEventListener('mousedown', this.onMouseDown);
        document.body.removeEventListener('mousemove', this.onMouseMove);
        document.body.removeEventListener('mouseover', this.onMouseOver);
        document.body.removeEventListener('mouseup', this.onMouseUp);

        // 清理dom
        this.$selectableShape = null;
    }

    /**
     * 根据关键子获取禁用状态
     * @param {String} key 
     */
    getEnabled(key) {
        let { disabled } = this.props;

        if (Object.prototype.toString.call(disabled) !== "[object Array]") {
            disabled = [disabled];
        }

        return !disabled.includes(key || true);
    }

    /**
     * 获取选中项的 值
     * @param {DOM} $item 
     */
    getItemKey($item) {
        if (!$item) {
            return null;
        }
        return $item.getAttribute('cellSelectorKey');
    }

    /**
     * 获取当前的 选中项dom
     * @param {DOM} $target 
     */
    getItem($target) {
        // 如果点到tbody
        if (!$target || $target.querySelector('tr')) {
            return null;
        }
        if ($target.tagName === 'TD') {
            return $target.querySelector(this.props.selector);
        }
        // 如果往下找  找到了
        return Closest($target, this.props.selector + ', tr');
    }

    /**
     * 鼠标按下事件
     */
    onMouseDown = e => {
        let { canvas } = this.props;
        // 目标容器画布
        let $canvas = document.querySelector(`${canvas}`);
        // console.log('onMouseDown', e);
        let ele = e.target;
        let $sideBox = $canvas && $canvas.querySelector('.edit-table-modal');


        // mac 平台右键是使用control + 触摸板点击触发的  会有问题
        if (/Mac|iPod|iPhone|iPad/.test(navigator.platform)
            && (e.keyCode === '17' || e.key === 'Control' || e.code === 'ControlLeft')
            && e.button === mouseButton) {

            return;
        }
        if (e.button !== mouseButton || !$canvas || !$canvas.contains(ele) || !this.getEnabled() || !ele ||
            ele.className.indexOf('u-table-thead-th-drag-gap') !== -1 ||
            ($sideBox && $sideBox.contains(ele)) ||
            ele.parentElement.className.indexOf('u-table-thead-th-drag-gap') !== -1) {
            this.hotkey = null;
            this.drag = false;
            let $FormulaEditorWrap = document.querySelector('.FormulaEditorWrap');

            if ($FormulaEditorWrap && $FormulaEditorWrap.contains(ele)) {
                return true;
            }
            if ($sideBox && $sideBox.contains(ele)) {
                // 此时不能clearData 否则可能有问题
                return true;
            }
            let $setColumnMenu = document.querySelector('#nc-table-setColumnMenu');
            if ($canvas && !$canvas.contains(ele) && !($setColumnMenu && $setColumnMenu.contains(ele))) {
                let isSelect = $canvas.querySelector('.column-title-actived, .table-cell-actived');
                // TODO 是否需要判断页面是否选中了内容
                isSelect && this.clearData({ isSelect }, this.hotkey);
            }
            return true;
        }

        // 注意顺序 
        this.clearShape();
        // 上面的方法会清空所有状态
        this.startPoint = {
            x: e.clientX,
            y: e.clientY,
        };

        // 不同的键位判断
        if ((e.ctrlKey || e.metaKey) && e.shiftKey && this.getEnabled('ctrl') && this.getEnabled('shift')) {
            this.hotkey = 'ctrl&shift';
        } else if ((e.ctrlKey || e.metaKey) && this.getEnabled('ctrl')) { // e.metaKey 修复 mac commend 键
            // console.log(this.getItem(ele));
            this.hotkey = 'ctrl';
            // 将上一次选中的获取并转换
            let hasSelectorItem = this.props.hasSelectorItem;
            let key = this.getItemKey(this.getItem(ele));
            let isSelect = false;
            // 外部方法处理
            if (hasSelectorItem) {
                isSelect = hasSelectorItem(this.hotkey, key);
            }
            // 选中获取反选
            if (this.selectorItem[key] || isSelect) {
                // 移除
                delete this.selectorItem[key];
                // this.selectorItem[key] = false;
            } else {
                this.selectorItem[key] = true;
                this.startItem = key;
            }
        } else if (e.shiftKey && this.getEnabled('shift')) {
            this.hotkey = 'shift';
        } else {
            this.hotkey = 'down';
            if (ele.getAttribute('cellDrag') && this.getEnabled('drag')) {
                // 触发拖拽事件
                this.drag = true;
                this.hotkey = 'drag';
            } else {
                // 清理数据
                let isSelect = $canvas && $canvas.querySelector('.column-title-actived, .table-cell-actived');
                this.clearData({ isSelect }, this.hotkey);
            }
            // 记录一下点击时的值
            let key = this.getItemKey(this.getItem(ele));
            this.startItem = key;
        }
    };

    // getScrollParentDOM
    /**
     * 最后一个元素时  移动并滚动
     * @param {DOM} $last 
     */
    moveToScroll($last, reverse) {
        if (!$last) {
            return;
        }
        let $scroll = getScrollParentDOM($last),
            sh = $scroll.offsetHeight,
            step = $last.offsetHeight,
            lt = $last.getBoundingClientRect().top,
            st = $scroll.getBoundingClientRect().top;
        if (reverse && (step * 2 > lt - st)) {
            $scroll.scrollTop -= step;
            return;
        }
        if (step * 2 > sh + st - lt) { // 剩余接近两行时
            $scroll.scrollTop += step;
        }
    }

    /**
     * 记录最后一次
     * @param {*} e 
     */
    onMouseOver = e => {
        this.endItem = this.getItemKey(this.getItem(e.target)) || this.endItem;
    }

    /**
     * 鼠标区域内移动事件
     */
    onMouseMove = e => {
        let { canvas } = this.props;

        // 目标容器画布
        let $canvas = document.querySelector(`${canvas}`);

        let { response } = this.props;
        // 禁用了
        if (!this.getEnabled('slide') || !$canvas || (!$canvas.contains(e.target) && this.$selectableShape !== e.target)) {
            return false;
        }

        // console.log('onMouseMove');


        if (e.button == mouseButton && this.hotkey && !this.drag) {
            // 初始位置
            let X = this.startPoint.x,
                Y = this.startPoint.y,
                // 当前视口
                clientX = e.clientX,
                clientY = e.clientY,
                // 当前页面
                pageX = e.pageX,
                pageY = e.pageY,
                // 滚动条偏移量
                offsetY = pageY - clientY,
                offsetX = pageX - clientX,
                // width = document.body.clientWidth,
                // height = document.body.clientHeight,
                // 应该用窗口高度
                width = window.innerWidth,
                height = window.innerHeight,
                style = this.$selectableShape.style;


            let { userSelect } = this.props;
            // 禁用鼠标选中 文字
            if (!userSelect && this.getEnabled('slide') && (Math.abs(clientY - Y) > 6 || Math.abs(clientX - X) > 6)) {
                if (window.getSelection) {
                    let selection = window.getSelection();
                    selection.removeAllRanges();
                }
                addClass($canvas, 'user-unselect');
            }

            // 如果响应距离不够或者  响应遮罩未显示 就不进行遮罩的选择  以及是否是可用的功能
            if ((Math.abs(clientY - Y) < response && Math.abs(clientX - X) < response) || this.endItem === this.startItem) {
                return false;
            }

            addClass($canvas, 'pointer-events-none');
            this.hotkey = 'slide';
            // 位置计算
            style.right = 'auto';
            style.bottom = 'auto';
            // 框框的绘制
            if (clientX >= X && clientY >= Y) {
                style.top = getPx(offsetY + Y - OFFSET.y);
                style.left = getPx(offsetX + X - OFFSET.x);
            } else if (clientX < X && clientY >= Y) {
                style.top = getPx(offsetY + Y - OFFSET.y);
                style.left = getPx(offsetX + clientX + OFFSET.x);
            } else if (clientX >= X && clientY < Y) {
                style.top = getPx(offsetY + clientY + OFFSET.y);
                style.left = getPx(offsetX + X - OFFSET.x);
            } else {
                style.top = getPx(offsetY + clientY + OFFSET.y);
                style.left = getPx(offsetX + clientX + OFFSET.x);
            }

            // 初始化宽度
            style.width = getPx(clientX - OFFSET.x - X);
            style.height = getPx(clientY - OFFSET.y - Y);

            // 滚动框选
            this.moveToScroll(e.target, clientY < Y);
        }

        // 拖拽
        if (e.button == mouseButton && this.hotkey && this.drag) {
            let { onSelect, response } = this.props;
            let { userSelect } = this.props;
            // 禁用鼠标选中 文字
            if (!userSelect && this.getEnabled('slide')) {
                if (window.getSelection) {
                    let selection = window.getSelection();
                    selection.removeAllRanges();
                }
                addClass($canvas, 'user-unselect');
            }
            this.endItem = this.getItemKey(this.getItem(e.target)) || this.endItem;
            onSelect && onSelect(this.selectorItem, this.hotkey, { currentKey: this.endItem, startKey: this.startItem }); // 回调中给出操作类型
        }
    };

    /**
     * 鼠标弹起事件
     */
    onMouseUp = e => {
        let { canvas } = this.props;
        // 目标容器画布
        let $canvas = document.querySelector(`${canvas}`);

        let { onSelect, response, single, clearData } = this.props;

        let { userSelect } = this.props;
        // 禁用鼠标选中 文字
        if (!userSelect && $canvas && this.getEnabled('slide')) {
            removeClass($canvas, 'user-unselect');

        }
        if (e.button !== mouseButton || !this.hotkey || !$canvas || !$canvas.contains(e.target)) {
            this.clearShape();
        } else {
            this.endItem = this.getItemKey(this.getItem(e.target)) || this.endItem;
            // console.log(this.endItem, 'this.endItem');
            let direction = 'down';
            if (this.hotkey === 'drag' && this.endItem === this.startItem) {
                this.hotkey = null;
                this.drag = false;
                return;
            }
            switch (this.hotkey) {
                case 'ctrl':
                case 'ctrl&shift':
                case 'shift':
                    break;
                case 'slide':
                case 'drag':
                    // 这种情况 只记录开始和结束
                    let startY = this.startPoint.y,
                        endY = e.clientY,
                        startX = this.startPoint.x,
                        endX = e.clientX;
                    if (endY - startY < 0) {
                        direction = 'up';
                    }
                    this.clearShape();
                    if ((Math.abs(endY - startY) < response && Math.abs(endX - startX) < response) || this.endItem === this.startItem) {
                        this.hotkey = null;
                        this.drag = false;
                        return;
                    }
                    break;
                default:
                    // console.log('空空如也', this.hotkey, this.selectorItem, this.endItem);
                    break;
            }
            // 清理一下无效数据
            delete this.selectorItem[null];
            // down的情况 由外部的rowClick来进行处理 否则就需要去除外部的逻辑
            if (this.hotkey && this.hotkey !== 'down') {
                onSelect && onSelect(this.selectorItem, this.hotkey, { currentKey: this.endItem, startKey: this.startItem, dragEnd: this.drag, direction }); // 回调中给出操作类型
            }
            if (this.hotkey && this.hotkey === 'down' && single) {
                onSelect && onSelect(this.selectorItem, this.hotkey, { currentKey: this.endItem, startKey: this.startItem });
            }
            // console.log('onMouseUp', this.hotkey, this.selectorItem, this.endItem);
        }

        this.hotkey = null;
        this.drag = false;
    };

    /**
     * 清理数据
     */
    clearData(info, hotkey) {
        let { clearData } = this.props;
        // 记录当前选中的数据 针对方案一
        this.selectorItem = {};

        clearData && clearData(info, hotkey);
    }

    /**
     * 主要给框选用
     * 清理框选遮罩样式
     */
    clearShape() {
        let { canvas } = this.props;
        // 目标容器画布
        let $canvas = document.querySelector(`${canvas}`);
        removeClass($canvas, 'pointer-events-none');
        if (this.$selectableShape) {
            this.$selectableShape.style.width = '0';
            this.$selectableShape.style.height = '0';
            this.$selectableShape.style.top = 'auto';
            this.$selectableShape.style.right = 'auto';
            this.$selectableShape.style.bottom = 'auto';
            this.$selectableShape.style.left = 'auto';
            this.$selectableShape.style.display = 'block';
            this.$selectableShape.style.border = 'none';
        }

        // 清除初始位置
        this.startPoint = {
            x: 0,
            y: 0,
        };
    }

    render() {
        return null;
    }
}
