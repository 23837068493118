/*
 * @Descripttion: 数据源  状态
 * @version:
 * @Author: renyjk
 * @Date: 2020-05-29 10:59:26
 * @LastEditors: bbq
 * @LastEditTime: 2021-03-08 15:48:28
 */

/**
 * 一些原则的说明
 * 1、所有index  应该是指当前可见的数据 不包含被过滤的和假删除的
 * 2、所有表格内部的操作  应尽量使用 rowKey 来完成
 * 3、内部的api也尽量用  rowKey来操作
 * 4、getTrueRowIndex 方法里的这个index  应该是指
 * 5、getTrueRowIndexOfFilter过滤之前的index 所有涉及的API 都需要调整下 TODO
 *
 *
 *
 */

import {
    BaseStore,
    ArrayData,
    TreeData,
    itemCreator,
} from "@platform/template";
import { ajax, pageTo, getSafeRandom } from "@platform/api";
import {
    isBoolean,
    isPromise,
    isArray,
    isObject,
    isString,
    isWrong,
    isUndefined,
    isFunction,
    isNullOrVoid,
    isWrongFalse,
} from "./utils/index";
import CONFIG from "./config";
const { ROW_STATUS } = CONFIG;

// 表格数据类型
export function TableData(data, keyPath = "rowid") {
    return new ArrayData(data, keyPath);
}
// 表格META类型
/**
 *
 *  meta={
 *  'body1':{
 *      name: "分组一",
 *      code: "body1",
 *      items:[],
 *  },
 * 'body2':{
 *      name: "分组一",
 *      code: "body1",
 *      items:[],
 *  },
 *  gridrelation:{
 *  },
 * }
 *
 */
export function TableMeta(data, keyPath = "attrcode") {
    for (let areacode of Object.keys(data)) {
        if (
            isObject(data[areacode]) &&
            isArray(data[areacode].items) &&
            data[areacode].moduletype === "table"
        ) {
            //业务组修改模板, 增加新列, treeData中没有 导致treeData的query方法获取不到新列
            data[areacode].items.isArrayData = false;
            data[areacode].items = new TreeData(data[areacode].items, keyPath);
        }
    }
    return data;
}

/**
 * 不要删 后面可以用来测试效率
 * @description: info打印
 * @param {type}
 * @return:
 */
//
const infoLog = function (target, name, descriptor) {
    var oldValue = descriptor.value;
    descriptor.value = function () {
        console.time(`Function ${name} is call, time `);
        let res = oldValue.apply(this, arguments);
        console.timeEnd(`Function ${name} is call, time `);
        return res;
    };
    return descriptor;
};

/**
 *  使用轩轩的  itemCreator 来获取类型的初始值
 *  如果初始值出问题  找轩轩讨论
 * @description: 暂时保留
 * @param {type}
 * @return:
 */
//
function typeFormat(origin, type) {
    let isVoid = isWrong.call(null, origin);
    switch (true) {
        // 'input', 'textarea', 'datepicker', 'select', 'checkbox', 'radio', 'refer', 'label' 和 number的空value处理
        case [
            "input",
            "textarea",
            "datepicker",
            "select",
            "checkbox",
            "radio",
            "refer",
            "label",
            "number",
        ].includes(type) && isVoid:
            return "";
        // switch 的空value处理为boolean值
        case ["switch", "checkbox_switch", "switch_browse"].includes(type) &&
            isVoid:
            return !!origin;
        default:
            return origin;
    }
}

export default class Store extends BaseStore {
    constructor(props) {
        super(props);
        this.name = "PublicStore";
        this.refresh = this.refresh.bind(this);
    }

    // 数据源
    store = {
        // 表格数据
        data: new TableData([], "rowid"),

        // 列模板
        meta: {},

        // 表格属性
        props: {
            rowKey: "rowid",
            status: "browse",
            // 需要聚焦的目标行
            focusIndex: -1,
            // 当前正在操作的行
            currentIndex: -1,
            currentInfo: {},
        },

        // 已选行
        selectedIndex: new Set(),

        // 所有pk
        allpks: [],

        // checkbox选中
        checkInfo: {
            checkedAll: false, //全选标志
            indeterminate: false, //半选标志
            selectIndex: [], //选中行
            disabledAll: false,
            crossPageSelectIndex: new Set(), //跨页选择
        },
        config: {}, //业务组传进来的config
        sortParam: undefined, //存储新的排序信息
        key: 1,
    };

    // 缓存
    cache = {
        base: null, // 不同情况的初始数据
        beforeEdit: null, //侧拉展开之前的数据（）
        all: null, //初始表格的全数据
        // 进行深度删除后的数据，作为删除后的操作的初始数据 为了在浏览态删除后，编辑态取消不会将删除的数据在显示出来
        afterDel: null,
        // 行的旧值 为数组 既可以存对象 也可以存索引
        oldRows: [],
        // 列初始值
        colInitialValue: {},
        // 多语
        langJson: {},
        // 批改
        oldBatchData: null,
    };

    /**
     * @description: 复写
     * @param {type}
     * @return:
     */
    getStore(paths) {
        return super.get(paths);
    }

    /**
     * @description: 复写
     * @param {type}
     * @return:
     */
    setStore(paths, value, shouldForceUpdate = true, callback) {
        let res = super.set(paths, value);
        shouldForceUpdate && this.forceUpdate(callback);
        return res;
    }

    /*-------------缓存-------------*/

    /**
     * @description: 设置缓存
     * @param {type}
     * @return:
     */
    setCache(path, value) {
        if (!path) {
            console.warn("invalid params <path> to setting");
            return;
        }
        if (!isArray(path)) {
            path = [path];
        }
        let current = this.cache;
        let len = path.length;
        path.forEach((item, index) => {
            if (!current[item]) {
                current[item] = {};
            }

            if (index === len - 1) {
                current[item] = value;
            } else {
                current = current[item];
            }
        });
    }

    /**
     * @description: 获取缓存
     * @param {type}
     * @return:
     */
    getCache(paths) {
        if (isUndefined(paths)) {
            console.warn("invalid params <paths> to setting");
            return;
        }
        if (!isArray(paths)) {
            paths = [paths];
        }

        let current = this.cache;

        paths.some(item => {
            if (isUndefined(item) || isNullOrVoid(current)) {
                isUndefined(item) &&
                    console.warn("paths has empty item[%s]", paths);
                return true;
            }
            if (!current[item]) {
                current = null;
                return true;
            }
            current = current[item];
            return false;
        });
        // console.log(this.cache);
        return current;
    }

    /*-------------私有-------------*/

    /*-------------列-------------*/
    /**
     * @description: 设置所有meta
     * @param {type}
     * @return:
     */
    setMeta(meta, id, shouldForceUpdate = true) {
        let name = this.getStore("name");
        id && this.setStore("name", id || name, false);

        // 我觉得这里应该 考虑page上的meta更新
        this.setStore(["meta"], new TableMeta(meta), shouldForceUpdate);

        // console.log(this._setColInitialValue);

        // 设置列里面的初始值  如果这时候 没有name 则下面在获取初始值是需要重新计算
        this._setColInitialValue();
    }

    /**
     * @description: 获取所有meta
     * @param {type}
     * @return:
     */
    getMeta = () => {
        let meta = this.getStore("meta"),
            res = {};
        for (let key of Object.keys(meta)) {
            // 去除一下浅拷贝 避免JSON.parse报错
            res[key] = meta[key]; // JSON.parse(JSON.stringify(meta[key]));
            if (isObject(meta[key]) && meta[key].items) {
                if (meta[key].moduletype === "table") {
                    res[key].items = meta[key].items.get
                        ? meta[key].items.get()
                        : meta[key].items; // getArrayData
                } else if (meta[key].moduletype === "form") {
                    res[key].items = meta[key].items;
                }
            }
        }
        // console.log(res);
        return res;
    };

    /**
     * @description: 是否包含当前meta
     * @param {type}
     * @return:
     */
    _hasMeta(id) {
        let meta = this.getStore("meta") || {};
        if (!id || !isObject(meta[id]) || !meta[id].items) {
            return null;
        }
        return meta[id].items;
    }

    /**
     * @description: 获取id所在视图的meta
     * @param {type}
     * @return:
     */
    getViewMeta(id) {
        let viewMeta = this._hasMeta(id);
        // getTreeData
        viewMeta = viewMeta && isFunction(viewMeta.get) ? viewMeta.get() : [];
        // console.log(viewMeta);
        return viewMeta;
    }

    /**
     * filterParentColumn 代表是否去除父级元素
     * @description: 获取id所在视图的meta
     * @param {type}
     * @return:
     */
    getArrayMeta(id, filterParentColumn = true) {
        if (isUndefined(id)) {
            id = this.getStore("name");
            !id && console.warn("not find current meta name");
        }
        let viewMeta = this._hasMeta(id);
        if (viewMeta && !viewMeta.getArrayData) {
            console.warn('viewMeta not init, please setMeta');
        }
        // getArrayData
        viewMeta = viewMeta ? (viewMeta.getArrayData ? viewMeta.getArrayData() : viewMeta) : [];
        // console.log(viewMeta);
        return viewMeta.filter(item => {
            let children = item.children;
            let condition =
                !children ||
                !(filterParentColumn && isArray(children) && children.length >= 0);
            return condition;
        });
    }

    /**
     * @description: 获取列字段属性
     * @param {type}
     * @return:
     */
    getColumn(id, code) {
        let viewMeta = this._hasMeta(id);
        if (!viewMeta) {
            console.warn(`can't find meta id, please check meta & id`, id);
            return {};
        }
        let item = viewMeta.query(code);
        !item && console.warn(`can't find code, please check meta & id`, viewMeta, code);
        return item || {};
    }

    /**
     * @description: 设置列数据
     * @param column: { [attrcode]: { visible: false } }
     * @return:
     */
    setColumn(id, column = {}, shouldForceUpdate = true, pattern = "merge") {
        for (let attrcode in column) {
            let item = this.getColumn(id, attrcode);
            let value = column[attrcode];

            if (pattern === "merge") {
                // TODO 合并数据
                for (let key in value) {
                    item[key] = value[key];
                }
            } else {
                // TODO: 替换数据
                item = value;
            }
        }
        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * 生成列的初始值
     * @description:
     * @param {type}
     * @return:
     */
    _generateColInitialValue() {
        let values = {};
        let name = this.getStore("name");

        let allMeta = this.getMeta();
        let tabRelation =
            allMeta.gridrelation &&
            allMeta.gridrelation[name] &&
            allMeta.gridrelation[name].tabRelation;
        if (isArray(tabRelation)) {
            tabRelation.forEach(itemCode => {
                // 这里有个隐患  可能获取不到对应code的meta
                this._generateItemValue(itemCode, values);
            });
        } else {
            this._generateItemValue(name, values);
        }

        // console.log('初始值 ', values);

        return JSON.parse(JSON.stringify(values));
    }

    _generateItemValue(itemCode, values) {
        let meta = this.getArrayMeta(itemCode);
        if (!meta.length) {
            console.warn("meta is empty array");
        }

        meta.forEach(item => {
            if (!item.children) {
                // 有children 说明是父级节点
                // 不进行else判断 为了避免下面校验时   不存在合并行字段报错
                let defaultCreator =
                    itemCreator({ itemtype: item.itemtype }) || {};

                // console.log(defaultCreator);

                // console.log(defaultCreator.defaultValue);
                // TODO:
                values[item.attrcode] = {
                    scale: null,
                    display: "",
                    value: "",
                    ...(defaultCreator.defaultValue || {}),
                    ...(item.initialvalue || {}),
                };
            }
        });
    }

    /**
     * @description: 更新设置列的默认值
     * @param {type}
     * @return:
     */
    _setColInitialValue() {
        let values = this._generateColInitialValue();
        this.setCache("colInitialValue", values);

        // console.log(this);
    }

    /**
     * @description: 获取列的默认值
     * @param {type}
     * @return:
     */
    _getColInitialValue() {
        let values = this.getCache("colInitialValue");
        if (!values || !Object.keys(values).length) {
            values = this._generateColInitialValue();
            this.setCache("colInitialValue", values);
        }
        return JSON.parse(JSON.stringify(values));
    }

    /*-------------单元格-------------*/

    /**
     * @description: 获取单元格values
     * @param {type}
     * @return:
     */
    _getCellValue(row = {}, attrcode) {
        return ((row || {})["values"] || {})[attrcode] || {};
    }

    /**
     * @description: 设置单元格values
     * @param {type}
     * @return:
     */
    _setCellValue(row, attrcode, value) {
        row["values"] = row["values"] || {};
        return (row["values"][attrcode] = value);
    }

    /**
     * rowIndex 可视数据index
     * @description: 设置单元格数据
     * @param {type}
     * @return:
     */
    setCellValue({
        rowIndex,
        rowKeyValue,
        attrcode,
        value = {},
        pattern = "merge",
        shouldForceUpdate = true,
        callback,
        view = 'normal',
    } = {}) {
        if (!rowKeyValue && rowIndex === -1) {
            console.warn("rowIndex is -1");
            return;
        }

        // console.log(this.itemGraph);

        // 获取真实的rowIndex
        !rowKeyValue && (rowKeyValue = this._getRowKeyValue({ rowIndex, view }));

        let data = this.getStore("data");
        if (!data.query(rowKeyValue || rowIndex)) {
            return console.warn('not find row data!');
        }

        let row = data.update(
            rowKeyValue || rowIndex,
            oldRow => {
                let props = this._getCellValue(oldRow, attrcode);
                if (pattern == "merge") {
                    value = { ...props, ...value };
                }

                // 一旦设置 清理掉 validated
                callback && callback(props, value, rowKeyValue, attrcode);

                // TODO:
                if (
                    !callback && props && value.validated === false && (
                        (isWrong(props.value) && !isWrong(value.value)) ||
                        (isWrong(props.display) && !isWrong(props.display))
                    )
                ) {
                    delete value.validated;
                }

                // console.log(value);
                this._setCellValue(oldRow, attrcode, value);
                return oldRow;
            },
        );

        shouldForceUpdate && this.updateItem([rowKeyValue, attrcode]);
        shouldForceUpdate && this.updateItem([rowKeyValue, 'sp_col_customer__']);

        // shouldForceUpdate && this.forceUpdate();

        return row;
    }

    /**
     * rowIndex 可视数据index
     * @description: 获取单元格数据
     * @param {type}
     * @return:
     */
    getCellValue({ rowIndex, rowKeyValue, attrcode, view } = {}) {
        if (!rowKeyValue && rowIndex === -1) {
            console.warn("rowIndex is -1");
            return;
        }
        // 获取真实的rowIndex
        !rowKeyValue && (rowKeyValue = this._getRowKeyValue({ rowIndex, view }));

        let row = this.getStore("data").query(rowKeyValue || rowIndex);

        return this._getCellValue(row, attrcode);
    }

    /**
     * rowIndex 可视数据index
     * @description: 设置单元格属性
     * @param {type}
     * @return:
     */
    setCellProps(
        rowKeyValue,
        attrcode,
        value = {},
        shouldForceUpdate = true,
        pattern = "merge",
    ) {
        if (isUndefined(rowKeyValue)) {
            console.warn("invalid params");
            return;
        }

        // rowKeyValue = this._getRowKeyValue({ rowKeyValue });

        // get(this.valueGraph, [rowKeyValue, attrcode]).forceUpdate();
        let data = this.getStore("data");
        if (!data.query(rowKeyValue)) {
            return console.warn('not find row data!');
        }

        let row = data.update(rowKeyValue, oldRow => {
            let props = this._getCellValue(oldRow, attrcode);

            if (pattern == "merge") {
                value = { ...props, ...value };
            }

            // console.log(value);
            this._setCellValue(oldRow, attrcode, value);
            return oldRow;
        });

        shouldForceUpdate && this.forceUpdate();
        // 这里需要优化
        // shouldForceUpdate && this.updateItem([rowKeyValue, attrcode]);
        // shouldForceUpdate && this.updateItem([rowKeyValue, 'sp_col_customer__']);

        return row;
    }

    /**
     * rowIndex 可视数据index
     * @description: 获取单元格属性
     * @param {type}
     * @return:
     */
    getCellProps({
        rowIndex, // 行
        attrcode, // 列
        rowKeyValue,
        view = 'normal'
    } = {}) {
        if (!rowKeyValue && rowIndex === -1) {
            console.warn("rowIndex is -1");
            return;
        }

        // 获取真实的rowIndex
        !rowKeyValue && (rowKeyValue = this._getRowKeyValue({ rowIndex, view }));

        let row = this.getStore("data").query(rowKeyValue || rowIndex);

        return this._getCellValue(row, attrcode);
    }

    /*-------------行-------------*/

    /**
     * TODO 看看能不能优化下
     * 获取真实的rowIndex  不建议频繁使用
     * view 目前有 normal sort delete filter
     * @description: 获取行的index
     * @param {type}
     * @return:
     */
    getTrueRowIndex({ rowKeyValue, rowIndex } = {}, view = "normal", outputView) {
        if (isUndefined(rowKeyValue) && isUndefined(rowIndex)) {
            console.warn("invalid params");
            return -1;
        }

        let rowKey = this.getTableProps("rowKey");
        // 内外之分
        let data = !outputView ? this.getStore("data") : this.getViewData(outputView === true ? 'filter' : outputView);
        let len = data.length;

        // 只传index 的情况
        if (isUndefined(rowKeyValue)) {
            let row = this.getViewData(view)[rowIndex];
            if (!row) {
                console.warn("not find row, maybe row overflow");
                return -1;
            }
            rowKeyValue = row[rowKey];
        }

        // 可以some 优化下
        data.some((item, index) => {
            if (item[rowKey] === rowKeyValue) {
                rowIndex = index;
            }
            return item[rowKey] === rowKeyValue;
        });

        rowIndex = rowIndex >= 0 && rowIndex < len ? rowIndex : -1;
        rowIndex === -1 && console.warn("rowIndex 为 -1，可能会导致后面报错");
        return rowIndex;
    }

    /**
     * @description: 获取行的index
     * @param {type}
     * @return:
     */
    _getRowKeyValue({ rowKeyValue, rowIndex, view = 'normal' }) {
        if (isUndefined(rowKeyValue) && isUndefined(rowIndex)) {
            console.warn(`invalid params _getRowKeyValue`);
            return;
        }

        // 检测 rowKeyValue
        if (rowKeyValue) {
            return rowKeyValue;
        }

        let rowKey = this.getTableProps("rowKey");
        let viewData = this.getViewData(view);
        let len = viewData.length;

        let row = viewData[rowIndex];

        if (row && row[rowKey]) {
            rowKeyValue = row[rowKey];
        } else {
            console.warn(
                "rowIndex overflow rowIndex: %s, len: %s",
                rowKeyValue,
                len,
            );
        }

        return rowKeyValue;
    }

    /**
     * 如果rowKeyValues里有数字  则转换一下
     * @description: 获取行的rowKeyValues
     * @param {type}
     * @return:
     */
    _getRowKeyValues({ rowKeyValues, rowIndexs, defaultAll = false, view = 'normal' }) {
        if (isUndefined(rowKeyValues) && isUndefined(rowIndexs)) {
            !defaultAll && console.warn(`invalid params ${rowKeyValues}`);
            if (defaultAll) {
                let data = this.getStore("data");
                let rowKey = this.getTableProps("rowKey");
                // data.getKeys()
                return data.order || data.map(row => row[rowKey]);
            }
            return [];
        }

        // 检测 rowKeyValues 的类型
        if (rowKeyValues) {
            // 转数组
            rowKeyValues = isArray(rowKeyValues)
                ? rowKeyValues
                : [rowKeyValues];
            return rowKeyValues;
        }

        // 转数组
        rowIndexs = isArray(rowIndexs) ? rowIndexs : [rowIndexs];

        let viewData = this.getViewData(view);
        let rowKey = this.getTableProps("rowKey");
        let len = viewData.length;
        let finalRowKeyValues = [];

        // 避免外部没判断报错
        rowIndexs.forEach(rowIndex => {
            let row = viewData[rowIndex];

            if (row && row[rowKey]) {
                finalRowKeyValues.push(row[rowKey]);
            } else {
                console.warn(
                    "rowIndex overflow rowIndex: %d, len: %d",
                    rowIndex,
                    len,
                );
            }
        });

        // console.log("rowKeyValues", finalRowKeyValues);
        return finalRowKeyValues || [];
    }

    /**
     * rowIndex 可视数据index
     * @description: 获取行属性
     * @param {type}
     * @return:
     */
    getRowProps({ rowKeyValue, rowIndex, view = 'normal' }, name) {
        // 获取 rowKeyValue
        rowKeyValue = this._getRowKeyValue({ rowKeyValue, rowIndex, view });

        let data = this.getStore("data"),
            row = data.query(rowKeyValue);
        return row[name];
    }

    /**
     * rowKeyValue 可以是 index 也可以是 key
     * @description: 设置行属性
     * @param {type}
     * @return:
     */
    setRowProps(
        { rowKeyValue, rowIndex, view = 'normal' } = {},
        value = {},
        shouldForceUpdate = true,
        pattern = "merge",
    ) {
        // 获取rowKeyValue
        rowKeyValue = this._getRowKeyValue({ rowKeyValue, rowIndex, view });

        let data = this.getStore("data");

        data.update(rowKeyValue, oldRow => {
            oldRow = Object.assign({}, oldRow, value);
            return oldRow;
        });

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * 这个方法和上面一个是否合并为一个 要考虑下
     * rowKeyValues 可以是 index 也可以是 key
     * @description: 设置行属性
     * @param {type}
     * @return:
     */
    setRowsProps(
        { rowKeyValues, rowIndexs, defaultAll = true, view = 'normal' } = {},
        value = {},
        shouldForceUpdate = true,
    ) {
        // 获取rowKeyValue
        rowKeyValues = this._getRowKeyValues({
            rowKeyValues,
            rowIndexs,
            defaultAll,
            view,
        });

        rowKeyValues.forEach(rowKeyValue => {
            this.setRowProps({ rowKeyValue }, value, false);
        });

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 设置行属性
     * @param {type}
     * @return:
     */
    setRowsCellPropsByKey(
        rowKeyValues,
        value = {},
        shouldForceUpdate = true,
        defaultAll,
    ) {
        rowKeyValues = this._getRowKeyValues({ rowKeyValues, defaultAll });
        rowKeyValues.forEach(rowKeyValue => {
            let data = this.getStore("data"),
                row = data.query(rowKeyValue);
            if (isObject(row)) {
                // Update
                for (let attrcode in row.values) {
                    this.setCellProps(rowKeyValue, attrcode, value, false);
                }
            } else {
                console.warn(`can't find row data`, rowKeyValue);
            }
        });
        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 设置行属性
     * @param {type}
     * @return:
     */
    setRowsCellPropsByIndex(
        rowIndexs,
        value = {},
        shouldForceUpdate = true,
        defaultAll,
        view = 'normal'
    ) {
        // 获取rowKeyValue
        let rowKeyValues = this._getRowKeyValues({ rowIndexs, defaultAll, view });
        rowKeyValues.forEach(rowKeyValue => {
            let data = this.getStore("data"),
                row = data.query(rowKeyValue);
            if (isObject(row)) {
                // Update
                for (let attrcode in row.values) {
                    this.setCellProps(rowKeyValue, attrcode, value, false);
                }
            } else {
                console.warn(`can't find row data`, rowKeyValue);
            }
        });
        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 根据规则来获取数据
     * // 可以设计为字符串或者数组
     * @param {rule: '!disabled && !selected || value > 1' }
     * @return:
     */
    _getDataByRules(data) {
        // TODO 规则解析
        return data;
    }

    /**
     * rowIndexs 是当前视图的
     * @description: 获取多行的数据

     * @return: 
     */
    getRows({ rowIndexs, rowKeyValues, filter, view = 'normal' } = {}) {
        let data = this.getStore("data"),
            rows = [];

        if (filter) {
            return this._getDataByRules(data, filter);
        }

        // 获取rowKeyValues
        rowKeyValues = this._getRowKeyValues({ rowKeyValues, rowIndexs, view });

        (rowKeyValues || rowIndexs || []).forEach(item => {
            let row = data.query(item);
            !row && console.warn("not find row data");
            rows.push(row);
        });

        return rows;
    }

    /**
     * rowIndex 是当前视图的
     * @description: 设置行数据  主要用于更新
     * @param {type}
     * @return:
     */
    setRows({ rows = [], shouldForceUpdate = true } = {}) {
        let rowKey = this.getTableProps("rowKey");
        let data = this.getStore("data");
        // 更新不需要默认值
        // let initialValue = this._getColInitialValue();

        let newRows = [];

        rows.forEach(item => {
            let rowKeyValue = item[rowKey],
                row = data.query(item[rowKey]);

            if (isObject(row)) {
                data.update(rowKeyValue, oldRow => {
                    //  Update values status ==
                    const values = Object.assign(
                        {},
                        // 合并里面的状态
                        oldRow.values || {},
                        // 合并默认值

                        item.values || {},
                    );

                    // 深度合并拷贝一下 (先合并 values层  再合并values内部)
                    for (let key in values) {
                        values[key] = values[key] || {};

                        values[key] = Object.assign(
                            {},
                            // 合并默认状态
                            (oldRow.values || {})[key],
                            // 合并默认值  实际上应该从itemCreate中取出默认值  这里可以偷个懒
                            {
                                display: !isUndefined(values[key].display) ? values[key].display : null,
                                value: !isUndefined(values[key].value) ? values[key].value : null,
                                scale: !isUndefined(values[key].scale) ? values[key].scale : -1,
                            },
                            // values[key],
                        )

                        // TODO 清除 错误状态
                        if (
                            values[key].validated === false && (!isWrong(values[key].value || values[key].display))
                        ) {
                            delete values[key].validated;
                            delete oldRow.values[key].validated;
                        }
                    }

                    // 合并状态等其他属性
                    oldRow = Object.assign({}, oldRow, item || {});
                    // 赋值
                    oldRow.values = values;
                    // for (let key in oldRow) {
                    //     if (isObject(oldRow[key])) {
                    //         oldRow[key] = Object.assign({}, oldRow[key], item[key] || {});
                    //     } else {
                    //         oldRow[key] = item[key];
                    //     }
                    // }
                    let newRow = JSON.parse(JSON.stringify(oldRow));
                    newRows.push(newRow);
                    return newRow;
                });
            } else {
                console.warn(`can't find row data`, rowKey);
            }
        });

        shouldForceUpdate && this.forceUpdate();

        return newRows;
    }

    /**
     * @description: 移动行
     * @param {type}
     * @return {type}
     */
    moveRow(current, next, shouldForceUpdate = true) {
        let data = this.getStore("data");

        if (
            isUndefined(current) ||
            isUndefined(next) ||
            current === next ||
            current < 0 ||
            next > data.length
        ) {
            return console.warn("invalid index");
        }
        // 交换数据索引
        let rows = this.getViewData("filter");
        let rowKey = this.getTableProps("rowKey");

        const start = rows[current];
        const end = rows[next];

        // 重新计算索引
        data.forEach((row, index) => {
            if (start && start[rowKey] === row[rowKey]) {
                current = index;
            }
            if (end && end[rowKey] === row[rowKey]) {
                next = index;
            }
        });

        data[next] = start;
        data[current] = end;

        this.setData({ data, shouldForceUpdate });
    }

    /**
     * @description: 给行换个索引
     * @param {type}
     * @return {type}
     */
    setRowPosition(indexStart, position, shouldForceUpdate = true) {
        // 交换数据索引
        let rows = this.getViewData("filter");

        if (
            indexStart < 0 ||
            isUndefined(indexStart) ||
            indexStart > rows.length
        ) {
            return console.warn("invalid index");
        }

        indexStart = this.getTrueRowIndex({ rowIndex: indexStart }, "filter");

        let data = this.getStore("data");

        // 更新位置
        if (position === "up") {
            let delItem = data.splice(indexStart, 1);
            data.unshift(delItem[0]);
        } else if (position === "down") {
            let delItem = data.splice(indexStart, 1);
            data.push(delItem[0]);
        }

        this.setData({ data, shouldForceUpdate });
    }

    /**
     * rowKeyValue
     * @description: 是否选中行
     * @param {type}
     * @return:
     */
    hasSelected(rowKeyValue) {
        // 获取 rowKeyValue
        rowKeyValue = this._getRowKeyValue({ rowKeyValue });
        let selectedIndex = this.getStore(["selectedIndex"]);
        return selectedIndex.has(rowKeyValue);
    }

    /**
     * value 里放的是rowKeyValue
     * @description: 设置选中行
     * @param {type}
     * @return:
     */
    setSelectedIndex(value, pattern = "add", shouldForceUpdate = true, view = 'normal') {
        let selectedIndex = this.getStore(["selectedIndex"]);
        if (pattern === "clear") {
            selectedIndex.clear();
            this.setTableProps("focusIndex", -1, false);
        }

        // 值的转换
        value = isArray(value)
            ? value
            : [isObject(value) ? value : { rowKeyValue: value }];

        value.forEach(item => {
            let { rowKeyValue, rowIndex, pattern = "add" } = item;

            // 值的转换
            rowKeyValue = this._getRowKeyValue({ rowKeyValue, rowIndex, view });

            if (pattern === "add") {
                selectedIndex.add(rowKeyValue);
            }
            if (pattern === "reverse") {
                selectedIndex.has(rowKeyValue)
                    ? selectedIndex.delete(rowKeyValue)
                    : selectedIndex.add(rowKeyValue);
            }
            if (pattern === "delete") {
                selectedIndex.delete(rowKeyValue);
            }
        });

        this.setStore(["selectedIndex"], selectedIndex, shouldForceUpdate);
    }

    /**
     * rowIndex 是真实的index
     * @description: 删除行
     * @param {type}
     * @return:
     */
    // @infoLog
    _deleteRow(rowIndex, rowKeyValue, thorough) {
        let data = this.getStore("data"),
            len = data.length;

        if (rowIndex > len || rowIndex < 0) {
            console.warn("rowIndex overflow");
            return;
        }

        let delItem = data[rowIndex];
        let status = delItem.status;

        // status != ROW_STATUS.delete 要剔除 假删除 否则 连续两次假删除 就会真删除
        if (
            thorough ||
            (status != ROW_STATUS.delete &&
                status != ROW_STATUS.origin &&
                status != ROW_STATUS.edit)
        ) {
            // 彻底删除
            delItem = data.splice(rowIndex, 1);
        } else {
            this.setRowProps(
                { rowKeyValue },
                { status: ROW_STATUS.delete },
                false,
            );
        }

        return delItem;
    }

    /**
     * rowIndex 可视数据index
     * 增行是否要扩展一个参数 可以插入到某个行之前 ？
     * 可以考虑扩展为 插入行
     * 插入行 没有取到index  不进行插入  增行 没有取到 默认最后
     * @description: 新增行
     * @param {type}
     * @return:
     */
    // @infoLog
    _insertRow({
        rowValues = {},
        rowIndex,
        shouldForceUpdate = true,
        type = "insert",
        isMergeInitValue = true,
        callback,
        view = 'normal',
    }) {
        // 这个方法比较特殊
        !isUndefined(rowIndex) &&
            (rowIndex = this.getTrueRowIndex({ rowIndex }, view));

        let data = this.getStore("data"),
            len = data.length,
            initialValue = isMergeInitValue ? this._getColInitialValue() : {};
        let rowKey = this.getTableProps("rowKey");

        // 合并数据哟  深度合并 一些不规范的值  做下转换
        Object.keys(rowValues).forEach(key => {
            const oldValue = (isObject(rowValues[key]) ? rowValues[key] : {});
            let newValue = {};
            // 清理oldValue里乱七八糟的状态
            !isUndefined(oldValue.display) && (newValue.display = oldValue.display);
            !isUndefined(oldValue.value) && (newValue.value = oldValue.value);
            !isUndefined(oldValue.scale) && (newValue.scale = oldValue.scale);
            !isUndefined(oldValue.isEdit) && (newValue.isEdit = oldValue.isEdit);
            // 清理一下原来行数据里的状态
            initialValue[key] = {
                ...initialValue[key],
                ...newValue,
            };
        });

        // TODO 对默认值和checkbox_switch勾选框值转化的操作

        // 插入行 没有取到index  不进行插入  增行 没有取到 默认最后
        if (type === "insert" && (isUndefined(rowIndex) || rowIndex == -1)) {
            rowIndex = len;
        }

        if (type === "add" && (isUndefined(rowIndex) || rowIndex == -1)) {
            rowIndex = len;
        }

        let newRow = {
            [rowKey]: this.generateRowKey(),
            status: ROW_STATUS.add,
            values: initialValue,
        };

        // 新增行
        data.splice(rowIndex, 0, newRow);

        shouldForceUpdate && this.forceUpdate(callback);
        return newRow;
    }

    /**
     * @description: 插入行
     * @param {type}
     * @return {type}
     */
    _insertRows({
        rows = [],
        rowIndex,
        isGenerateRowKey,
        shouldForceUpdate = true,
        view = 'normal',
    }) {
        if (!isArray(rows)) {
            console.warn("rows params not array");
            rows = [rows];
        }

        // 这个方法比较特殊
        rowIndex && (rowIndex = this.getTrueRowIndex({ rowIndex }, view));

        let data = this.getStore("data"),
            len = data.length,
            initialValue = this._getColInitialValue();

        // 注意插入最后一行index的情况的判断
        // 没有取插到最后
        if (isUndefined(rowIndex) || rowIndex === -1) {
            rowIndex = len;
        }

        // rowIndex > len ||  // 插入是可以超出界限的
        if (rowIndex > len || rowIndex < 0) {
            console.warn("rowIndex overflow");
            return;
        }

        let rowKey = this.getTableProps("rowKey");

        rows = rows.map(row => {
            let rowValues = row.values;
            // 合并数据哟  深度合并 一些不规范的值  做下转换
            Object.keys(rowValues).forEach(key => {
                rowValues[key] = {
                    ...initialValue[key],
                    ...(isObject(rowValues[key]) ? rowValues[key] : {}),
                };
            });
            rowValues = { ...initialValue, ...rowValues }
            // TODO 对默认值和checkbox_switch勾选框值转化的操作

            // console.log(initialValue);

            let rowKeyValue = this.generateRowKey();

            return {
                [rowKey]: isGenerateRowKey
                    ? rowKeyValue
                    : row[rowKey] || rowKeyValue,
                status: ROW_STATUS.add,
                values: JSON.parse(JSON.stringify(rowValues)),
            };
        });

        // 变换为编辑态
        this.setTableProps("status", "edit", false);

        // 新增行
        data.splice(rowIndex, 0, ...rows);

        // 设置 旧值
        this.saveRowsOldValue({ rows });

        shouldForceUpdate && this.forceUpdate();

        return rows;
    }

    /*-------------表格-------------*/
    /**
     * @description: 格式化数据
     * @param {type}
     * @return:
     */
    formatData(data = [], rule) {
        let rowKey = this.getTableProps("rowKey");
        data = isArray(data) ? data : [data];
        data = data.map(item => {
            item[rowKey] = item[rowKey] || this.generateRowKey();
            item["status"] = item["status"] || ROW_STATUS.origin;
            if (rule === 'clearSelect') {
                delete item["selected"];
            }
            return item;
        });
        return data;
    }

    /**
     * @description: 设置数据
     * @param {type}
     * @return:
     */
    setData({ data = [], shouldForceUpdate = true, callback }) {
        let rowKey = this.getTableProps("rowKey"); // rowid

        let res = isArray(data) ? new TableData(data, rowKey) : this.getData();
        // console.log(res);
        // 格式化data
        this.setStore(["data"], res, shouldForceUpdate, callback);
    }

    /**
     * regexp 可以是方法 也可以是参数
     * @description: 获取数据
     * @param {type}
     * @return:
     */
    // @infoLog
    getData(regexp = []) {
        let data = this.getStore("data");
        // console.log(data);
        // 添加规则什么的  比如删除 比如过滤 TODO 过滤要考虑不能放在status中  这样会影响原来的业务侧的代码

        data = data.filter(item => {
            if (isFunction(regexp)) {
                return regexp(item, ROW_STATUS);
            }

            if (regexp.includes(ROW_STATUS.filter)) {
                return !regexp.includes(item.status) && !item.filter;
            }
            return !regexp.includes(item.status);
        });
        return data || [];
    }

    /**
     * 目前是简单规则 后面可以想个规则解析器
     * @description: 获取当前视图的data
     * @param {type}
     * @return:
     */
    getViewData(view = "normal") {
        let regexp = view;
        if (view === "normal") {
            regexp = [ROW_STATUS.filter, ROW_STATUS.delete];
        }

        if (view === "filter") {
            regexp = [ROW_STATUS.delete];
        }

        if (view === "all") {
            regexp = [];
        }

        if (view == 'invisible') {
            regexp = [ROW_STATUS.filter, ROW_STATUS.origin, ROW_STATUS.edit, ROW_STATUS.add];
        }

        let data = this.getData(regexp);
        return data || [];
    }

    /**
     * @description: 获取表格属性
     * @param {type}
     * @return:
     */
    getTableProps(key) {
        let props = this.getStore(["props", key]);
        // console.log(props);
        return props;
    }

    /**
     * @description: 设置表格属性
     * @param {type}
     * @return:
     */
    setTableProps(key, value, shouldForceUpdate = true, callback) {
        this.setStore(["props", key], value, shouldForceUpdate, callback);
    }

    /*-------------高阶组件-------------*/

    /**
     * @description: 获取分页信息
     * @param {type}
     * @return:
     */
    getPageInfo() {
        // console.log(this.getStore(['pageInfo']));
        return this.getStore(["pageInfo"]);
    }

    /**
     * @description: 設置分页信息
     * @param {type}
     * @return:
     */
    setPageInfo({ shouldForceUpdate = true, pageInfo = {} }) {
        let prevPageInfo = this.getStore(["pageInfo"]) || {};
        prevPageInfo = { ...prevPageInfo, ...pageInfo };
        // console.log(prevPageInfo);
        return this.setStore(["pageInfo"], prevPageInfo);
    }

    /**
     * @description: 获取checked信息
     * @param {type}
     * @return:
     */
    getCheckInfo() {
        let info = this.getStore(["checkInfo"]);
        return info || {};
    }

    /**
     * @description: 设置checked信息
     * @param {type}
     * @return:
     */
    setCheckInfo({ checkInfo, shouldForceUpdate = true, callback }) {
        this.setStore(["checkInfo"], checkInfo, shouldForceUpdate, callback);
    }

    /**
     * @description: 设置某些行选中状态
     * @param {type}
     * @return:
     */
    setRowsSelected(
        { rowKeyValues, rowIndexs, view = 'normal' },
        selected,
        shouldForceUpdate = true,
        { pattern, defaultAll = true, callback, triggerType = 'api' } = {},
    ) {
        rowKeyValues = this._getRowKeyValues({
            rowKeyValues,
            rowIndexs,
            defaultAll,
            view,
        });

        // 这个用filter 暂时不用管
        let data = this.getViewData('filter');
        // 设置选中行模式
        pattern = pattern || (selected ? "add" : "delete");

        let selectedIndex = rowKeyValues.map(rowKeyValue => {
            let row = this.getRows({ rowKeyValues: [rowKeyValue] })[0] || {};
            let values = row.values || row;
            let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
            // 非禁用  模式是新增时  不允许选中  但是允许删除
            let disabled = pattern == "add" ? row.disabled : false;
            if (triggerType === 'event') {
                disabled = row.disabled
            }
            unSaga && !disabled && this.setRowProps({ rowKeyValue }, { selected }, false);
            return {
                rowKeyValue,
                pattern,
            };
        });

        let checkInfo = this.getCheckInfo();
        if (data && data.length) {
            checkInfo.indeterminate = data.some(item => {
                let values = item.values || item;
                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                // 非禁用  模式是新增时  不允许选中  但是允许删除
                let disabled = pattern == "add" ? item.disabled : false;
                return unSaga && !disabled && item.selected;
            });
            checkInfo.checkedAll = data.every(item => {
                let values = item.values || item;
                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                // 非禁用  模式是新增时  不允许选中  但是允许删除
                let disabled = pattern == "add" ? item.disabled : false;
                return !unSaga || disabled || item.selected;
            });
        } else {
            checkInfo.indeterminate = false;
            checkInfo.checkedAll = false;
        }
        this.setSelectedIndex(selectedIndex || [], "", false);
        this.setStore(["checkInfo"], checkInfo, shouldForceUpdate, callback);
    }
    /**
     * @description: 选中所有行
     * @param {type}
     * @return:
     */
    setAllRowsSelected(selected, shouldForceUpdate = true, callback, view = 'normal') {
        this.setRowsSelected({ view }, selected, shouldForceUpdate, { defaultAll: true, callback });
    }
    /**
     * @description: 设置某些行选中状态
     * @param {type}
     * @return:
     */
    setRowsSelectedByIndexs(
        rowIndexs,
        selected,
        shouldForceUpdate = true,
        { pattern, defaultAll = true, view = 'normal' } = {},
    ) {
        this.setRowsSelected({ rowIndexs, view }, selected, shouldForceUpdate, {
            pattern,
            defaultAll,
        });
    }
    /**
     * @description: 设置某些行选中状态
     * @param {type}
     * @return:
     */
    setRowsSelectedByRowKey(
        rowKeyValues,
        selected,
        shouldForceUpdate = true,
        { pattern, defaultAll = true, view = 'normal' } = {},
    ) {
        this.setRowsSelected({ rowKeyValues, view }, selected, shouldForceUpdate, {
            pattern,
            defaultAll,
        });
    }

    /**
     * 反选某些行或者所有行
     * @description:
     * @param {type}
     * @return:
     */
    reverseRowsSelected(shouldForceUpdate) {
        let data = this.getStore("data");
        let rowKey = this.getTableProps("rowKey");

        let selectedIndex = [];
        data.forEach(item => {
            let selected = item.selected;
            this.setRowProps(
                { rowKeyValue: item[rowKey] },
                { selected: !selected },
                false,
            );
            !selected &&
                selectedIndex.push({
                    rowKeyValue: item[rowKey],
                    pattern: "add",
                });
        });

        let checkInfo = this.getCheckInfo();

        checkInfo.indeterminate = data.some(
            item => item.selected && item.status !== ROW_STATUS.delete,
        );
        checkInfo.checkedAll = data.every(item => !!item.selected);

        this.setSelectedIndex([], "clear", false);
        this.setSelectedIndex(selectedIndex || [], "", shouldForceUpdate);
    }

    /*-------------包含业务的方法-------------*/

    /**
     * @description: 设置行的旧值
     * @param {type}
     * @return:
     */
    saveRowOldValue(rowKeyvalue, attrcode, value) {
        this.setCache(["oldRows", rowKeyvalue, attrcode], value);
    }

    /**
     * @description: 获取行的旧值
     * @param {type}
     * @return:
     */
    getRowOldValue(rowKeyvalue, attrcode) {
        // console.log(this.getCache(['oldRows', rowKeyvalue, attrcode]));
        return this.getCache(["oldRows", rowKeyvalue, attrcode]);
    }

    /**
     * @description: 清楚行的旧值
     * @param {type}
     * @return:
     */
    deleteRowOldValue(rowKeyvalue, attrcode) {
        this.setCache(["oldRows", rowKeyvalue, attrcode], null);
    }

    /**
     * @description: 移除没有输入内容，所有的空行
     * @param {type}
     * @return:
     */
    removeEmptyRows({ data, keys, rule = "except", shouldForceUpdate = true, deep = false }) {
        data = data || this.getStore("data");
        let rowKey = this.getTableProps("rowKey");
        let newData = [];

        data.forEach((item, index) => {
            let values = item.values;
            // TODO 这里的一段代码后面可以优化下
            let valueKeys = Object.keys(values).filter(
                item => item != "numberindex",
            );
            if (isArray(keys) && (rule == "except" || rule == "include")) {
                valueKeys = valueKeys.filter(val => {
                    return rule == "except"
                        ? keys.every(key => {
                            return val !== key;
                        })
                        : keys.includes(val);
                });
            } else {
                console.warn("Invalid params ", rule, keys);
            }

            let flag = valueKeys.every(one => isWrongFalse(values[one].value));

            // 如果没有找到就不push
            if (flag) {
                // TODO 关闭侧拉
                //  myCardTable.modelIndex === index ? (myCardTable.model = 'close') : '';

                // TODO 清空
                this.deleteRowOldValue(item[rowKey], {});

                // 为啥不用index  因为index不一定准确  没找到就不过滤
                let trueIndex = this.getTrueRowIndex({ rowKeyValue: item[rowKey] });
                if (trueIndex === -1) {
                    // 不进行删除
                    newData.push(item);
                    return;
                }

                // 假删除
                if (!deep) {
                    if (item.status !== ROW_STATUS.add) {
                        item.status = ROW_STATUS.delete;
                        newData.push(item);
                    }
                }
            } else {
                newData.push(item);
            }
        });

        // console.log(newData);

        // 这种方式 更新了正表
        this.setData({ data: newData, shouldForceUpdate });
    }

    /**
     * @description: 存旧值方法 为了适配筛选功能将旧值标识, 改成通过rowid, 原来是index
     * @param {type}
     * @return:
     */
    saveRowsOldValue({ rows }) {
        let rowKey = this.getTableProps("rowKey");
        // 过滤假删除数据
        rows.filter(row => row.status != ROW_STATUS.delete).map(item => {
            const { values } = item,
                rowid = item[rowKey];
            for (let keys in values) {
                const OldVal = values[keys] ? values[keys].value : null;
                this.saveRowOldValue(rowid, keys, OldVal);
            }
        });
    }

    /**
     * @description: 清理旧值方法 为了适配筛选功能将旧值标识, 改成通过rowid, 原来是index
     * @param {type}
     * @return:
     */
    _clearRowsOldValue({ rows }) {
        let rowKey = this.getTableProps("rowKey");
        // 过滤假删除数据
        rows.map(item => {
            const { values } = item,
                rowid = item[rowKey];
            for (let keys in values) {
                this.saveRowOldValue(rowid, keys, null);
            }
        });
    }

    // 清空排序
    clearSortStatus({ columns }) {
        columns.forEach(eve => {
            //获取表格列配置
            const { children } = eve;
            if (!isUndefined(children)) {
                //判断和并列的情况
                this.clearSortStatus({ columns: children });
            } else {
                columns.forEach(item => {
                    //保存返回的column状态，没有则终止order状态
                    item.order = 'flatscend';
                    item.orderNum = '';
                });
            }
        });
    }

    /**
     * @description: 设置表格数据
     * @param {type}
     * @return:
     */
    setTableData(
        data = {},
        { isCache = true, isTop = false, shouldForceUpdate = true } = {},
    ) {
        if (!data.rows) {
            return console.warn("invalid data rows");
        }
        // 格式化数据
        data.rows = this.formatData(data.rows, 'clearSelect');

        // 将排序标记清空 
        let columns = this.getArrayMeta();
        if (columns) {
            this.clearSortStatus({ columns: columns });
        }

        // 改变一下这里的缓存值的位置，这样的话缓存的，只是业务组传给我们的值，而没有我们的其他内部控制字段，这样不会污染我们的数据
        // 每次重新设值内部字段都是取当前表格的，业务组不回给我们传我们内部的控制段，所以这样存储源值更合理
        if (isCache) {
            // 为了让表格可以进行单一的渲染，然后添加了这个判断条件，不进行缓存，只为表格设置数据 zhanghengh
            this.setCache(["base"], JSON.parse(JSON.stringify(data)));
            this.setCache(["all"], null);
            this.setCache(["afterDel"], null);
        }

        /**
         * 处理筛选状态下的情况
         * 清空的表格筛选标志
         * 清空筛选状态下的表格全数据
         * 退出筛选状态
         */
        // TODO 筛选
        this.setStore('filterReset', true, false, () => {
            this.setStore('filterReset', false, false);
        });
        this.resetFilter(undefined);
        // console.log(this.getStore('filterReset'));
        // 将之前表格上的状态更新到新数据上进行同步

        // 将多选框的全选恢复成不勾选
        let crossPageSelectIndex = this.getStore(["checkInfo", "crossPageSelectIndex"]);
        let checkedAllpage = this.getStore(["checkInfo", "checkedAllpage"]);
        if (crossPageSelectIndex.size === 0) {
            checkedAllpage = false
        }
        let allpks = this.getStore('allpks') || []
        if (data.allpks) {
            allpks = data.allpks;
        } else if (data.rows && data.rows.length == 0) {
            checkedAllpage = false
            crossPageSelectIndex = new Set()
            allpks = []
        }
        this.setStore('allpks', allpks, false);

        let checkInfo = {
            checkedAll: false, //全选标志
            indeterminate: false, //半选标志
            selectIndex: [], //选中行
            disabledAll: false,
            checkedAllpage,
            crossPageSelectIndex, //跨页选择
        }
        this.setCheckInfo({
            checkInfo,
            shouldForceUpdate: false,
        });
        // 清空之前点击存的数据 TODO
        this.setTableProps("currentInfo", null, false);
        this.setTableProps("currentIndex", -1, false);
        // 将选中行去掉
        // 清理行选中态  删除自动选中到下一个行的逻辑 , 与快捷键的的删除逻辑冲突
        this.setSelectedIndex([], "clear", false);

        // 重新设置数据模版滚动条定位到顶部
        this.setTableProps("focusIndex", isTop ? 0 : -1, false);

        let oldData = this.getData();
        // 清理旧值
        this._clearRowsOldValue({ rows: oldData });
        // 设置数据
        this.setData({
            data: data.rows,
            shouldForceUpdate,
            callback: () => {
                this.setStore('filterReset', false, false);
                this.setTableProps("focusIndex", -1, false);
            },
        });
        // 设置值之后  存旧值
        let newData = this.getData();
        this.saveRowsOldValue({ rows: newData });
        // 跨页全选
        let config = this.getConfig();
        let { crossPageSelect, pkname } = config;
        if (crossPageSelect && pkname) {
            let { crossPageSelectIndex } = this.getCheckInfo();
            //如果传了pk 并且所传pk和上一次不一样则清空所有跨页全选的数据
            if (data.allpks && data.allpks.length) {
                if (data.allpks.length !== allpks.length || data.allpks[0] !== allpks[0]) {
                    checkedAllpage = false
                    crossPageSelectIndex.clear()
                    checkInfo.checkedAllpage = false
                    checkInfo.crossPageSelectIndex = crossPageSelectIndex
                    this.setCheckInfo({
                        checkInfo,
                        shouldForceUpdate: false,
                    });
                }
            }
            if (checkedAllpage) {
                this._setSelect(undefined, "allpage");
            } else {
                let rows = this.getData();
                let rowKey = this.getTableProps("rowKey");
                let rowIdArr = [];
                rows.forEach(row => {
                    if (!row.values[pkname]) {
                        console.error(`行数据中没有pkname为${pkname}的value`);
                        console.log(row);
                        return;
                    }
                    let pk = row.values[pkname].value;
                    if (crossPageSelectIndex.has(pk)) {
                        rowIdArr.push(row[rowKey]);
                    }
                });
                this._setSelect(rowIdArr, "set", true);
            }
        }
        return data;
    }

    /**
     * @description: 更新表格数据
     * @param {type}
     * @return:
     */
    updateTableData(data, isCache = true, shouldForceUpdate = true) {
        if (!data.rows) {
            return console.warn("invalid data rows");
        }
        // let rowKey = this.getTableProps('rowKey');
        // 格式化数据
        data.rows = this.formatData(data.rows) || [];
        let newRows = this.setRows({ rows: data.rows, shouldForceUpdate: shouldForceUpdate });

        if (isCache) {
            let allRows = this.getData();
            // 设置缓存
            this.setCache(["base"], { rows: JSON.parse(JSON.stringify(allRows)) });
            this.setCache(["afterDel"], null);
            this.setCache(["all"], null);
        }

        // 设置旧值
        this.saveRowsOldValue({ rows: newRows });
    }

    /**
     * 拆分更新方法
     * @param {*} data 
     * @param {*} shouldForceUpdate 
     * @returns 
     */
    updateRows(data, shouldForceUpdate = true) {
        if (!data) {
            return console.warn("invalid data params");
        }
        let rowKey = this.getTableProps("rowKey");

        if (data.rows) {
            data.rows.map(row => {
                let { values, status } = row;
                let rowKeyValue = row[rowKey];

                // 格式化数据
                row = (this.formatData([row]) || [row])[0];

                // 同步 rowKeyValue
                row[rowKey] = rowKeyValue;

                // 根据 rowKeyValue 来设置
                row.status = isUndefined(status) || status == ROW_STATUS.origin ? ROW_STATUS.edit : status;

                this.setRows({ rows: [row], shouldForceUpdate: false });
            });
        }

        if (isArray(data)) {
            data.map(item => {
                let { index, data: row } = item;
                let rowKeyValue = this._getRowKeyValue({ rowIndex: index });
                // 格式化数据
                row = (this.formatData([row]) || [row])[0];

                // 根据 rowKeyValue 来设置
                row.status = isUndefined(row.status) || row.status == ROW_STATUS.origin ? ROW_STATUS.edit : row.status;

                // 同步 rowKeyValue
                row[rowKey] = rowKeyValue;
                // 根据 rowKeyValue 来设置

                this.setRows({ rows: [row], shouldForceUpdate: false });
            });
        }

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * 更新行属性
     * @param {*} data 
     * @param {*} shouldForceUpdate 
     */
    updateRowsProps(data, props = {}, shouldForceUpdate = true) {
        if (!data) {
            return console.warn("invalid data params");
        }

        if (data.rows) {
            data.rows.map(row => {
                row = { ...row, ...props };
                this.setRows({ rows: [row], shouldForceUpdate: false });
            });
        }

        if (isArray(data)) {
            data.map(item => {
                let { data: row } = item;
                row = { ...row, ...props };
                this.setRows({ rows: [row], shouldForceUpdate: false });
            });
        }

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * 更新表格缓存
     * @param {*} data 
     * @param {*} shouldForceUpdate 
     */
    resetTableCaches(shouldForceUpdate = true) {
        let rows = this.getData();
        this.setCache(["base"], { rows: JSON.parse(JSON.stringify(rows)) });
        this.setCache(["afterDel"], null);
        this.setCache(["all"], null);
        // 设置旧值
        this.saveRowsOldValue({ rows });
        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 根据index 更新表格数据
     * @param {type}
     * @return:
     */
    updateTableDataByIndexs(data, flag, shouldForceUpdate = true, view = 'normal') {
        console.log("打印日志========", "updateTableDataByIndexs", "数据data:", data, "flag：", flag)
        if (!isArray(data)) {
            return console.warn("invalid data params");
        }

        console.log("打印日志========", "updateTableDataByIndexs中调用saveRowsOldValue", "数据rows:", data);

        let rowKey = this.getTableProps("rowKey");

        data.map(item => {
            let { index, data: row } = item;
            let rowKeyValue = this._getRowKeyValue({ rowIndex: index, view });
            // 格式化数据
            row = (this.formatData([row]) || [row])[0];

            // 根据 rowKeyValue 来设置
            row.status = isUndefined(row.status) || row.status == ROW_STATUS.origin ? ROW_STATUS.edit : row.status;

            // 同步 rowKeyValue
            row[rowKey] = rowKeyValue;
            // 根据 rowKeyValue 来设置

            this.setRows({ rows: [row], shouldForceUpdate: false });
        });


        let rows = this.getData();
        // 设置旧值
        this.saveRowsOldValue({ rows });

        // TODO
        // changeCheckboxSwitch

        // 设置缓存
        if (flag) {
            this.setCache(["base"], { rows: JSON.parse(JSON.stringify(rows)) });
            this.setCache(["afterDel"], null);
            this.setCache(["all"], null);
        }
        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 根据rowId 更新表格数据
     * @param {type}
     * @return:
     */
    updateTableDataByRowId(data, isCache, shouldForceUpdate = true, isDel = false) {
        console.log("打印日志========", "updateTableDataByRowId", "数据data:", data, "isCache：", isCache);
        if (!data || !isArray(data.rows)) {
            return console.warn("invalid data params");
        }
        let rowKey = this.getTableProps("rowKey");

        // 设置旧值
        console.log("打印日志========", "updateTableDataByRowId中调用saveRowsOldValue", "数据rows:", data);
        this.saveRowsOldValue({ rows: data.rows });

        data.rows.map(row => {
            let { values, status } = row;
            let rowKeyValue = row[rowKey];

            // 格式化数据
            row = (this.formatData([row]) || [row])[0];

            // 同步 rowKeyValue
            row[rowKey] = rowKeyValue;

            // 根据 rowKeyValue 来设置
            row.status = isUndefined(status) || status == ROW_STATUS.origin ? ROW_STATUS.edit : status;

            this.setRows({ rows: [row], shouldForceUpdate: false });
        });

        // TODO
        // changeCheckboxSwitch

        let rows = this.getData(isDel ? [ROW_STATUS.delete] : []);
        // 设置缓存
        if (isCache) {
            this.setCache(["base"], { rows: JSON.parse(JSON.stringify(rows)) });
            this.setCache(["afterDel"], null);
            this.setCache(["all"], null);
        }

        // 设置数据
        this.setData({ data: rows, shouldForceUpdate });
    }

    /**
     * @description: 设置表格状态
     * @param {type}
     * @return:
     */
    setTableStatus(status, { shouldForceUpdate = true } = {}) {
        if (!["edit", "browse"].includes(status)) {
            return console.warn(
                `unknown status: ${status}, status should be 'edit' or 'browse'`,
            );
        }

        // 切换成浏览态的时候把所有的toast清掉
        if (this.getTableProps("status") !== "browse" && status == "browse") {
            // clearOneTypeToast('danger'); // TODO
        }

        //变为浏览台将批改数据清空
        if (status === "browse") {
            this.setCache("oldBatchData", null);
        }

        this.setTableProps("status", status, shouldForceUpdate);
    }

    /**
     * @description: 获取所有的行
     * @param {type}
     * @return:
     */
    getAllRows(isDeepCopy = true) {
        // 好像不需要 取缓存了
        // let cacheAllData = this.getCache(['all']);
        let rows = this.getData() || [];
        let res = isDeepCopy ? JSON.parse(JSON.stringify(rows)) : rows;
        let noDeleteData = [];
        let deleteData = [];
        res.forEach(row => {
            if (row.status === ROW_STATUS.delete) {
                deleteData.push(row);
            } else {
                noDeleteData.push(row);
            }
        });
        res = noDeleteData.concat(deleteData);
        // rows = (cacheAllData ? cacheAllData.rows : rows) || [];
        return res;
    }

    /**
     * @description: 获取可见的行
     * @param {type}
     * @return:
     */
    getVisibleRows(isDeepCopy = true, view = 'normal') {
        let rows = this.getViewData(view); // 不带过滤的数据
        return isDeepCopy ? JSON.parse(JSON.stringify(rows)) : rows;
    }

    /**
     * @description: 获取已经变化的数据
     * @param {type}
     * @return:
     */
    getChangedRows() {
        let rows = this.getData([ROW_STATUS.origin]);
        return JSON.parse(JSON.stringify(rows));
    }

    focusItem(rowKeyValue, shouldForceUpdate) {
        let metaItems = this.getArrayMeta();
        for (let i = 0; i < metaItems.length; i++) {
            if (
                !metaItems[i].children && metaItems[i].attrcode != 'numberindex' &&
                (typeof metaItems[i].disabled == 'undefined' || metaItems[i].disabled == false) &&
                (typeof metaItems[i].visible != 'undefined' && metaItems[i].visible == true)
            ) {
                let attrcode = metaItems[i].attrcode;
                // console.log(rowKeyValue, attrcode);
                this.setCellProps(rowKeyValue, attrcode, { isEdit: true, autoFocus: true }, shouldForceUpdate);
                break;
            }
        }
    }

    /**
     * @description: 添加一行
     * @param {type}
     * @return:
     */
    addTableRow(
        index,
        defaultValue = {},
        shouldForceUpdate = true,
        { autoFocus = false, isAutoAddRow, callback, view = 'normal' } = {},
    ) {
        // 方法锁  解决点击自动增行 点击增行导致  增两行问题
        if (this.__addRowLock && !isAutoAddRow) {
            return;
        }
        this.__addRowLock = true;
        // setTimeout(() => { this.__addRowLock = false; });

        let rowKey = this.getTableProps("rowKey");

        // 控制增行后的行定位
        let unFilterData = this.getViewData(),
            last = unFilterData.length;
        let trueIndex = !isUndefined(index) ? this.getTrueRowIndex({ rowIndex: index }, view) : last;
        let focusIndex = trueIndex === -1 ? last : trueIndex;
        this.setTableProps("focusIndex", focusIndex === 0 ? focusIndex : focusIndex + 1, false);

        // 变换为编辑态
        this.setTableProps("status", "edit", false);

        /**
         * 延迟增行执行，解决表格出现纵向滚动条时，在最后一行，录入数据
         * 在点击最后一行其他单元格，进行自动增行后，被点击的单元格没有切换到可编辑状态
         * 导致这个问题的原因是，当初出现纵向滚动条后，设置myCardTable.focusIndex之后，更新表格
         * 导致我们无法点上，我们所点的div，导致对应的onclick事件没有执行
         */
        //  setTimeout(() => {
        if (isPromise(defaultValue) === "Promise") {
            defaultValue.then(result => {
                let newRow = this._insertRow({
                    rowValues: result,
                    rowIndex: index,
                    shouldForceUpdate: false,
                    type: "add",
                    callback: null,
                });
                // 存旧值
                this.saveRowsOldValue({ rows: [newRow] });
                // 自动聚焦
                autoFocus && this.focusItem(newRow[rowKey], false);

                shouldForceUpdate && this.forceUpdate(() => {
                    // 控制增行后的行定位
                    this.setTableProps("focusIndex", -1, false);
                    callback && callback(newRow);
                });
                this.__addRowLock = false;
                return newRow;
            });
        } else {
            // 执行一下方法
            if (isFunction(defaultValue)) {
                defaultValue = defaultValue() || {};
            }
            let newRow = this._insertRow({
                rowValues: defaultValue,
                rowIndex: index,
                shouldForceUpdate: false,
                type: "add",
                callback: null,
            });

            // 存旧值
            this.saveRowsOldValue({ rows: [newRow] });

            // 自动聚焦
            autoFocus && this.focusItem(newRow[rowKey], false);

            // 控制表格单元格的渲染顺序 避免不能执行cell里的聚焦操作
            shouldForceUpdate && this.forceUpdate(() => {
                // 控制增行后的行定位
                this.setTableProps("focusIndex", -1, false);
                callback && callback(newRow);
            });
            this.__addRowLock = false;
            return newRow;
        }
        // }, 27);
    }

    /**
     * rowKeyValues 即可以是rowids 也可以是rowIndexs
     * @description: 删除表格行
     * @param {type}
     * @return:
     */
    deleteTableRows(
        { rowKeyValues, rowIndexs },
        thorough,
        shouldForceUpdate = true,
        callback,
        view = 'normal',
    ) {
        // 获取rowKeyValues
        rowKeyValues = this._getRowKeyValues({ rowKeyValues, rowIndexs, view });

        let delItems = [];
        let rowKey = this.getTableProps("rowKey");
        let data = this.getData();
        let currentInfo = this.getTableProps("currentInfo") || {};
        let currentIndex = this.getTableProps("currentIndex");
        let currentRowKeyValue = this.getViewData('filter')[currentIndex];
        let includeCurrentRow = false;

        rowKeyValues.forEach(item => {
            let trueIndex = this.getTrueRowIndex({ rowKeyValue: item });

            if (trueIndex === -1) {
                return;
            }

            // includeCurrentRow
            if (!includeCurrentRow) {
                includeCurrentRow =
                    data[trueIndex][rowKey] === currentInfo[rowKey] || data[trueIndex][rowKey] === currentRowKeyValue;
            }

            // 新加删除旧值
            this.deleteRowOldValue(data[trueIndex][rowKey], {});

            //若被删除行已选中,则重置复选框状态
            this.setRowsSelected(
                { rowKeyValues: [item], rowIndexs },
                false,
                false,
            );

            let delItem = this._deleteRow(trueIndex, item, thorough);
            delItems.push(delItem);
        });

        // 判断是否彻底删除，不缓存在前端
        thorough &&
            this.setCache(["afterDel"], { rows: this.getData() });

        // 清理行选中态  删除自动选中到下一个行的逻辑 , 与快捷键的的删除逻辑冲突
        if (includeCurrentRow) {
            this.setTableProps("currentInfo", null, false);
            this.setTableProps("focusIndex", -1, false);
            this.setTableProps("currentIndex", -1, false);
        }

        shouldForceUpdate && this.forceUpdate(callback);
        return delItems;
    }

    /**
     * @description: 设置某一行的值
     * @param {type}
     * @return:
     */
    setValByKeyAndIndex(
        rowIndex,
        key,
        cellValue = {},
        {
            isSetEdit = true,
            isUpdateBatch = false,
            shouldForceUpdate = true,
            view = 'normal',
        } = {},
    ) {
        let { value, display } = cellValue;
        // let rowIndex = this.getTrueRowIndex({ rowIndex: index });
        let row = this.getRows({ rowIndexs: [rowIndex], view })[0];
        let rowKey = this.getTableProps("rowKey");

        if (!row) {
            return console.warn("unknown row data");
        }

        row.status == ROW_STATUS.origin &&
            isSetEdit &&
            this.setRowProps({ rowIndex, view }, { status: ROW_STATUS.edit }, false); //新加了存旧值

        if (!isUndefined(value)) {
            //将批改数据重新缓存
            this.saveRowOldValue(row[rowKey], key, value);
        }

        if (isUpdateBatch && (!isUndefined(value) || !isUndefined(display))) {

            this.setCache("oldBatchData", {
                batchChangeIndex: Number(getTrueRowIndex({ rowIndex: rowIndex }, 'filter', 'normal')),
                batchChangeKey: key,
                batchChangeValue: !isUndefined(value) ? value : null,
                batchChangeDisplay: !isUndefined(display) ? display : null,
            });
        }

        this.setCellValue({
            rowIndex,
            attrcode: key,
            value: cellValue,
            shouldForceUpdate,
            view,
        });

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 设置某一行的值
     * @param {type}
     * @return:
     */
    setValByKeyAndRowId(
        rowKeyValue,
        key,
        cellValue = {},
        {
            isSetEdit = true,
            isUpdateBatch = false,
            shouldForceUpdate = true,
        } = {},
    ) {
        let { value, display } = cellValue;
        let row = this.getRows({ rowKeyValues: [rowKeyValue] })[0];
        let rowKey = this.getTableProps("rowKey");

        isSetEdit && row.status == ROW_STATUS.origin &&
            this.setRowProps(
                { rowKeyValue: rowKeyValue },
                { status: ROW_STATUS.edit },
                false,
            );

        //新加了存旧值
        if (!isUndefined(value)) {
            this.saveRowOldValue(row[rowKey], key, value);
        }

        // 将批改数据重新缓存
        if (isUpdateBatch && (!isUndefined(value) || !isUndefined(display))) {
            let viewData = this.getViewData("filter");
            let rowIndex = -1;
            viewData.forEach((row, index) => {
                if (row[rowKey] == rowKeyValue) {
                    rowIndex = index;
                }
            });

            if (rowIndex !== -1) {
                // 可以考虑放到 cache中
                this.setCache("oldBatchData", {
                    batchChangeIndex: Number(rowIndex),
                    batchChangeKey: key,
                    batchChangeValue: !isUndefined(value) ? value : null,
                    batchChangeDisplay: !isUndefined(display) ? display : null,
                });
            }
        }

        this.setCellValue({
            rowKeyValue: rowKeyValue,
            attrcode: key,
            value: cellValue,
            shouldForceUpdate,
        });
    }

    /**
     * @description: 获取某一行的值
     * @param {type}
     * @return:
     */
    getValByKeyAndIndex(index, attrcode, view) {
        // 获取真实的 index
        // let rowIndex = this.getTrueRowIndex({ rowIndex: index });
        let value = this.getCellValue({ rowIndex: index, attrcode, view });
        (!value || isUndefined(value.value)) &&
            console.warn("empty cell value ");
        return JSON.parse(JSON.stringify(value || {}));
    }

    /**
     * @description: 获取某一行的值
     * @param {type}
     * @return:
     */
    getValByKeyAndRowKey(rowKeyValue, attrcode) {
        // 获取真实的 index
        let value = this.getCellValue({ rowKeyValue, attrcode });
        (!value || isUndefined(value.value)) &&
            console.warn("empty cell value ");
        return JSON.parse(JSON.stringify(value || {}));
    }

    /**
     * @description: 根据KEYS批量设置行属性
     * @param {type}
     * @return:
     */
    setCellsProps({
        rowIndexs,
        rowKeyValues,
        attrcodes,
        value = {},
        shouldForceUpdate = true,
        view = 'normal',
    } = {}) {
        // 转换为rowKeyValues
        !rowKeyValues &&
            (rowKeyValues = this._getRowKeyValues({ rowKeyValues, rowIndexs, view }));

        rowKeyValues = isArray(rowKeyValues) ? rowKeyValues : [rowKeyValues];
        // 为啥要做转换呢！！！
        value.disabled = isBoolean(value.disabled)
            ? value.disabled
            : value.disabled === "on";

        // 如果没有传入codes 设置整行 TODO ?
        if (!attrcodes) {
            // return this.setRowsProps({
            //     rowIndexs,
            //     rowKeyValues,
            //     attrcodes,
            //     value,
            //     shouldForceUpdate,
            // });
            let data = this.getStore("data");
            rowKeyValues.forEach(rowKeyValue => {
                let row = data.query(rowKeyValue);
                if (isObject(row)) {
                    // Update
                    for (let attrcode in row.values) {
                        this.setCellProps(rowKeyValue, attrcode, value, false);
                    }
                }
            });
        } else {
            attrcodes = isArray(attrcodes) ? attrcodes : [attrcodes];
            rowKeyValues.forEach(rowKeyValue => {
                attrcodes.forEach(attrcode => {
                    this.setCellProps(rowKeyValue, attrcode, value, false);
                });
            });
        }

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * CardTable 与 EditTable 不太一样
     * 粘贴行 相当于增行
     * @description: pasteRow
     * @param {type}
     * @return:
     */
    pasteRow({
        copyRow = {},
        index,
        excludeKeys,
        shouldForceUpdate = true,
        view = 'normal',
    } = {}) {
        let newRow = JSON.parse(JSON.stringify(copyRow));
        let values = newRow.values || {};

        if (isArray(excludeKeys)) {
            excludeKeys.map(key => {
                values[key] && delete values[key];
            });
        }

        // 复制拷贝的值
        newRow.values = values;

        return this._insertRows({
            rows: [newRow],
            rowIndex: index,
            isGenerateRowKey: true,
            shouldForceUpdate,
            view,
        });
    }

    /**
     * 返回的数据格式比较特殊
     * @description: 获取选中的行
     * @param {type}
     * @return:
     */
    getSelectedRows(isDeepCopy, view = 'normal') {
        let data = this.getStore("data");
        let checkRows = [];
        const config = this.getConfig();
        const { crossPageSelect, pkname } = config;
        //跨页选择
        if (crossPageSelect && pkname) {
            let { crossPageSelectIndex } = this.getStore(["checkInfo"]);
            let allpks = this.getStore(["allpks"]);
            let alltss = this.getStore(["alltss"]);

            let pageInfo = this.getPageInfo();
            let { pageSize } = pageInfo;
            pageSize = Number(pageSize);
            // pageIndex = Number(pageIndex);
            // let currentPageStartIndex = pageSize * pageIndex - pageSize;
            // let currentPageEndIndex = pageSize * pageIndex - 1;

            // allpks.forEach((pk, index) => {
            //     let row;
            //     let currentIndex = index - (pageIndex - 1) * pageSize;
            //     if (crossPageSelectIndex.has(pk)) {
            //         if (
            //             index >= currentPageStartIndex &&
            //             index <= currentPageEndIndex
            //         ) {
            //             row = {
            //                 data: data[currentIndex],
            //                 index: currentIndex,
            //                 pageIndex: Math.floor(index / pageSize),
            //             };
            //         } else {
            //             row = {
            //                 data: { pk: allpks[index], ts: alltss[index] },
            //                 index: index % pageSize,
            //                 pageIndex: Math.floor(index / pageSize),
            //             };
            //         }
            //         checkRows.push(row);
            //     }
            // });

            let currentPagePkArr = []

            data.forEach(row => {
                let pk = row.values[pkname].value
                currentPagePkArr.push(pk)
            })

            crossPageSelectIndex.forEach(item => {
                let row
                let rowIndex = currentPagePkArr.indexOf(item)
                if (rowIndex !== -1) {
                    let pkIndex = allpks.indexOf(item)
                    row = {
                        data: data[rowIndex],
                        index: pkIndex % pageSize,
                        pageIndex: Math.floor(pkIndex / pageSize),
                    }
                } else {
                    let pkIndex = allpks.indexOf(item)
                    if (pkIndex !== -1) {
                        row = {
                            data: { pk: allpks[pkIndex], ts: alltss[pkIndex] },
                            index: pkIndex % pageSize,
                            pageIndex: Math.floor(pkIndex / pageSize),
                        }
                    }
                }
                if (row) { checkRows.push(row) }
            });

            return isDeepCopy
                ? JSON.parse(JSON.stringify(checkRows))
                : checkRows;
        } else {
            // 取当前视图的数据来进行删除 
            let viewData = this.getViewData(view);
            viewData.map((item, index) => {
                if (item.selected) {
                    checkRows.push({
                        data: item,
                        index,
                    });
                }
            });

            return isDeepCopy
                ? JSON.parse(JSON.stringify(checkRows))
                : checkRows;
        }
    }

    /**
     * @description: 获取所有删除了的项
     * @param {type}
     * @return:
     */
    getAllRowsRemoveKeys(keys) {
        let data = this.getStore("data");
        let [isStr, isArr] = [typeof keys === "string", Array.isArray(keys)];

        if (keys == undefined || isStr || isArr) {
            // 是字符串或者是数组 或者是undefined
            data.map(elem => {
                let values = elem.values;
                for (let key in values) {
                    if (
                        (isStr && key === keys) ||
                        (isArr && keys.includes(key))
                    ) {
                        delete values[key];
                    }
                }
            });
            return data.filter(e => e.status != ROW_STATUS.delete);
        }
    }

    /**
     * TODO 有些奇怪 共用一套数据 不应该再有模板区分
     * @description: 获取混合了所有页签的 表行的值
     * @param {type}
     * @return:
     */
    getMixinMetaOfData() {
        let _initValues = this._getColInitialValue();
        let rowKey = this.getTableProps("rowKey");

        // console.log(_initValues);

        let data = this.getStore("data");
        let res = data.map(item => {
            item.values = { ...item.values, ..._initValues[item[rowKey]] };
            return item;
        });
        return res;
    }

    /**
     * @description: 获取过滤的行
     * @param {type}
     * @return:
     */
    _getFilterRows(key, value, langCode) {
        // 不修改原始数据
        let data = JSON.parse(JSON.stringify(this.getStore("data") || []));
        let rows = [];
        if (isString(key)) {
            key = [key];
        }

        let filterData = this.getViewData("filter");

        // 先清理 后匹配
        data = data.map(row => {
            if (row.filter) {
                delete row.filter;
                delete row.filterIndex;
            }
            return row;
        });

        // 没有value
        if (isUndefined(value) || value === "") {
            data = data.map((row, index) => {
                row.filter = false;
                row.filterIndex = index;
                return row;
            });
            return {
                // 筛选后数据
                filterRows: data,
                // 更改状态的数据
                allRows: data,
            };
        }

        //多语适配start
        let metaItems = this.getArrayMeta();
        if (metaItems) {
            metaItems.forEach(item => {
                if (item.itemtype === "residtxt") {
                    key = key.map(k => {
                        if (k == item.attrcode) {
                            item.languageMeta.forEach(i => {
                                if (i.languageCode == langCode) {
                                    k = k + i.index;
                                }
                            });
                        }
                        return k;
                    });
                }
            });
        }

        data.filter((row, index) => {
            const { values, status } = row;
            if (status != ROW_STATUS.delete) {
                if (key.length) {
                    row.filter = true;
                    delete row.filterIndex;
                }
                //当key时数组时
                key.forEach(keyItem => {
                    if (values[keyItem]) {
                        if (
                            (values[keyItem].display &&
                                values[keyItem].display.includes(value)) ||
                            (values[keyItem].value &&
                                values[keyItem].value.includes(value))
                        ) {
                            row.filter = false;
                            row.filterIndex = index;
                        }
                    }
                    // 像是处理多语言的代码
                    let newKeyItem = keyItem.slice(0, keyItem.length - 1);
                    if (values[newKeyItem]) {
                        if (
                            (values[newKeyItem].display &&
                                values[newKeyItem].display.includes(value)) ||
                            (values[newKeyItem].value &&
                                values[newKeyItem].value.includes(value))
                        ) {
                            row.filter = false;
                            row.filterIndex = index;
                        }
                    }
                });
            }
        });
        return {
            // 筛选后数据
            filterRows: rows,
            // 更改状态的数据
            allRows: data,
        };
    }

    /**
     * @description: 获取过滤的行
     * @param {type}
     * @return:
     */
    getFilterRows(key, value, langCode) {
        let { filterRows, allRows } = this._getFilterRows(key, value, langCode);

        return {
            //筛选后数据
            areaType: "table",
            rows: filterRows,
            areacode: null,
        };
    }

    /**
     * 重置过滤器
     */
    resetFilter(tableId) {
        tableId = tableId || this.getStore("name");
        if (!tableId) {
            return console.warn('invalid resetFilter tableId note find');
        }
        let arrColumns = this.getArrayMeta(tableId, true);

        let columnStatus = {};
        // 设置表格筛选图标, 重置时将筛选图标全部清空
        arrColumns.forEach(column => {
            columnStatus[column.attrcode] = { filterStatus: '' };
        });

        // 设置行状态
        let data = this.getData() || [];
        data.forEach(row => {
            row.filter = false;
            delete row.filterIndex;
        });

        // 处理已选等逻辑
        let checkInfo = this.getCheckInfo();
        let viewData = this.getViewData();
        if (viewData && viewData.length) {
            checkInfo.indeterminate = viewData.some(item => {
                let values = item.values || item;
                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                return unSaga && item.selected;
            });
            checkInfo.checkedAll = viewData.every(item => {
                let values = item.values || item;
                let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                return !unSaga || item.selected;
            });
        }
        this.setStore(["checkInfo"], checkInfo, false);

        // 更新 所有的更新放到弹窗关闭(onHide)时
        this.setData({ data, shouldForceUpdate: false });

        // 列状态
        this.setColumn(tableId, columnStatus, false);
    }

    /**
     * @description: 设置搜索后表格数据的方法
     * @param {type}
     * @return:
     */
    setFiltrateTableData(key, value, isShowAll, langCode, tableId) {
        // 清空筛选标识, 清空filterAllData
        this.setStore('filterReset', true, false);
        this.resetFilter(tableId);
        let { filterRows, allRows } = this._getFilterRows(key, value, langCode);
        // TODO
        if (isShowAll && filterRows.length === 0) {
            allRows = allRows.map(row => {
                if (row.filter) {
                    delete row.filter;
                    delete row.filterIndex;
                }
                return row;
            });
            // 处理已选等逻辑
            let checkInfo = this.getCheckInfo();
            let viewData = this.getViewData();
            if (viewData && viewData.length) {
                checkInfo.indeterminate = viewData.some(item => {
                    let values = item.values || item;
                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                    let disabled = item.disabled;
                    return unSaga && !disabled && item.selected;
                });
                checkInfo.checkedAll = viewData.every(item => {
                    let values = item.values || item;
                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                    let disabled = item.disabled;
                    return !unSaga || disabled || item.selected;
                });
            }
            this.setStore(["checkInfo"], checkInfo, false);
        }
        // 更新表格数据
        this.setData({
            data: allRows,
            callback: () => {
                this.setStore('filterReset', false, false);
            },
        });
    }

    /**
     * @description: 批改
     * @param {type}
     * @return:
     */
    batchChangeTableData() {
        let batchData = this.getCache("oldBatchData");
        let data = this.getData();
        if (
            !isArray(data) ||
            data.length <= 0 ||
            !batchData ||
            !batchData.batchChangeKey
        ) {
            return null;
        }
        //将之前缓存的表格列的key，批量修改成，之前onBulr的那个输入框了的值
        data.forEach(row => {
            const { values, status } = row;
            if (status != ROW_STATUS.delete) {
                row.status == ROW_STATUS.origin &&
                    (row.status = ROW_STATUS.edit);
                //删除的数据不改变
                if (isObject(batchData.batchChangeValue)) {
                    // 对多语文本进进行特殊处理
                    let value = batchData.batchChangeValue;
                    Object.keys(value).forEach(key => {
                        if (values[key]) {
                            values[key] = { ...values[key], ...value[key] };
                        } else {
                            values[key] = value[key];
                        }
                    });
                } else {
                    // Object.keys(values).forEach(item => {
                    //     if (item === batchData.batchChangeKey) {
                    //         values[item].value = batchData.batchChangeValue;
                    //         values[item].display = batchData.batchChangeDisplay;
                    //     }
                    // });
                    // 部分列初始值是空的  没有对应的单元格数据
                    values[batchData.batchChangeKey] =
                        values[batchData.batchChangeKey] || {};
                    values[batchData.batchChangeKey].value =
                        batchData.batchChangeValue;
                    values[batchData.batchChangeKey].display =
                        batchData.batchChangeDisplay;
                }
            }
        });
        this.saveRowsOldValue({ rows: data });
        // this.refresh();
        this.setData({ data });
        return {
            code: batchData.batchChangeKey,
            value: batchData.batchChangeValue,
            display: batchData.batchChangeDisplay,
        };
    }

    /**
     * @description: 插入行
     * @param {type}
     * @return:
     */
    insertDataByIndexs(data, flag = true, shouldForceUpdate = true, view = 'normal') {
        if (!isArray(data)) {
            return console.warn("invalid data params");
        }

        // 变换为编辑态
        this.setTableProps("status", "edit", false);

        data.map(item => {
            let { index, data: row } = item;
            this._insertRow({
                rowValues: row.values,
                rowIndex: index,
                type: "add",
                shouldForceUpdate: false,
                isMergeInitValue: flag,
                view,
            });
        });

        // TODO
        // changeCheckboxSwitch

        let rows = this.getData();

        // 设置旧值
        this.saveRowsOldValue({ rows });

        shouldForceUpdate && this.forceUpdate();
    }

    /**
     * @description: 更新行的状态
     * @param {type}
     * @return:
     */
    setRowStatusByIndexs(data, shouldForceUpdate = true, view = 'normal') {
        let rows = this.getData();
        let rowKey = this.getTableProps("rowKey");

        data.map(item => {
            let { index, status } = item;
            let trueIndex = this.getTrueRowIndex({ rowIndex: index }, view);

            const STATUS = Object.values(ROW_STATUS);
            if (!STATUS.includes(String(status))) {
                return console.warn("status必须是0到3的数字或者字符串");
            }
            this.setRowProps({ rowIndex: index, view }, { status }, false);
            // 要先 处理状态  在重新设置id  目前不支持更新id
            rows[trueIndex][rowKey] = this.generateRowKey();
        });

        this.setData({ data: rows, shouldForceUpdate });
    }

    /**
     * @description: 行数据插入到index行后
     * @param {type}
     * @return:
     */
    insertRowsAfterIndex(data, index, shouldForceUpdate = true, view = 'normal') {
        index = isUndefined(index) ? 0 : ++index;
        this._insertRows({ rows: data, rowIndex: index, isGenerateRowKey: true, shouldForceUpdate, view });
    }

    /**
     * rowIndex 可视数据index
     * @description: 设置单元格数据
     * @param {type}
     * @return:
     */
    setRowsCellValue({
        rows,
        pattern = "replace",
        shouldForceUpdate = true,
    } = {}) {
        if (!rows) {
            return;
        }

        rows = isArray(rows) ? rows : [rows];

        let rowKey = this.getTableProps("rowKey");
        let data = this.getStore("data");

        rows.forEach(item => {
            let rowKeyValue = item[rowKey],
                row = data.query(rowKeyValue);

            if (isObject(row)) {
                // TODO Update
                for (let attrcode in item.values) {
                    this.setCellValue({
                        rowKeyValue,
                        attrcode,
                        value: item.values[attrcode],
                        shouldForceUpdate: false,
                    });
                }
                row.status == ROW_STATUS.origin &&
                    (row.status = ROW_STATUS.edit);
            } else {
                console.warn(`can't find row data`, rowKey);
            }
        });

        shouldForceUpdate && this.forceUpdate();

        return rows;
    }

    // 生成rowid
    generateRowKey = () => {
        return (
            String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12)
        );
    };

    // 刷新整个绑定对象
    refresh() {
        this.forceUpdate && this.forceUpdate();
    }

    //liuming 跨页全选的crossPage
    setCrossPage = (rows, pkname, isSelect) => {
        let { crossPageSelectIndex } = this.getCheckInfo();
        let allpks = this.getStore(["allpks"]) || []
        let rowsArr = isArray(rows) ? rows : [rows];
        rowsArr.forEach(row => {
            let pk = row.values[pkname].value;
            let values = row.values || row;
            let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
            let disabled = row.disabled;
            if (isSelect && unSaga && !disabled) {
                crossPageSelectIndex.add(pk);
            } else {
                crossPageSelectIndex.delete(pk);
            }
        });
        let checkedAllpage = allpks.length === crossPageSelectIndex.size
        this.setStore(["checkInfo", "checkedAllpage"], checkedAllpage, false);

    };
    //liuming 的勾选逻辑
    _setSelect = (rowIdArr, pattern = "set", shouldForceUpdate) => {
        let selectIndex = this.getStore(["selectedIndex"]);
        let indeterminate = false,
            checkedAll = false;
        if (pattern === "set") {
            selectIndex.clear();
            rowIdArr.forEach(rowId => {
                selectIndex.add(rowId);
            });
        }
        if (pattern === "clear") {
            selectIndex.clear();
        }

        if (pattern === "all" || pattern === "allpage") {
            this.setRowsSelected({}, true, false);
        } else if (pattern === "reverse") {
            this.reverseRowsSelected(false);
        } else if (pattern === "clear") {
            this.setRowsSelected({}, false, false);
        } else {
            this.setRowsSelected(
                { rowKeyValues: [...selectIndex] },
                true,
                false,
            );
        }

        let rows = this.getData();
        let rowsLength = rows.length;

        let selectSize = selectIndex.size;
        if (selectSize === 0) {
            indeterminate = false;
            checkedAll = false;
        } else if (selectSize > 0 && selectSize < rowsLength) {
            indeterminate = true;
            checkedAll = false;
        } else if (selectSize === rowsLength) {
            indeterminate = false;
            checkedAll = true;
        }
        let checkInfo = this.getStore("checkInfo");
        checkInfo.indeterminate = indeterminate;
        checkInfo.checkedAll = checkedAll;
        checkInfo.selectIndex = selectIndex;
        if (pattern === "allpage") {
            checkInfo.checkedAllpage = true;
        } else {
            checkInfo.checkedAllpage = false;
        }

        this.setStore(["checkInfo"], checkInfo, shouldForceUpdate);
    };

    getConfig = () => {
        return this.getStore(["config"]);
    };
    setConfig = config => {
        this.setStore(["config"], config, false);
    };
    //更新已有行,并且新增行
    _updateAndAddRows = ({ rows = [], shouldForceUpdate = true }) => {
        console.log('_updateAndAddRows', rows)
        let rowKey = this.getTableProps("rowKey");
        let data = this.getStore("data");
        let lastRowIndex = data.length
        let newRows = [];
        let insertRows = []
        rows.forEach(item => {
            let rowKeyValue = item[rowKey],
                row = data.query(item[rowKey]);

            if (isObject(row)) {
                data.update(rowKeyValue, oldRow => {
                    //  Update values status ==
                    const values = Object.assign(
                        {},
                        oldRow["values"],
                        item["values"] || {},
                    );
                    // 合并状态等其他属性
                    oldRow = Object.assign({}, oldRow, item || {});
                    // 赋值
                    oldRow.values = values;
                    let newRow = JSON.parse(JSON.stringify(oldRow));
                    newRows.push(newRow);
                    return newRow;
                });
            } else {
                insertRows.push(item)
            }
        });
        this._insertRows({ rows: insertRows, rowIndex: lastRowIndex, isGenerateRowKey: false, shouldForceUpdate: false });

        shouldForceUpdate && this.forceUpdate();

        return newRows;
    }

    getExcelReferAjaxData = (ajaxData, refer, con, queryCondition, TreeRefActionExt, GridRefActionExt, extraCondition, extraAjaxParam) => {
        let query = {};
        if (isFunction(queryCondition)) {
            query = Object.assign(
                {},
                queryCondition(refer.props),
                con,
            ); //先是组件的模板上的condition，被业务组注册进来的condition覆盖
        } else if (isObject(queryCondition)) {
            query = Object.assign({}, queryCondition, con);
        } else if (isUndefined(queryCondition)) {
            query = Object.assign({}, con);
        }
        if (isString(query.TreeRefActionExt)) {
            if (query.TreeRefActionExt) {
                TreeRefActionExt = query.TreeRefActionExt + "," + TreeRefActionExt;
            }
        }
        if (isString(query.GridRefActionExt)) {
            if (query.GridRefActionExt) {
                GridRefActionExt = query.GridRefActionExt + "," + GridRefActionExt;
            }
        }
        delete query.TreeRefActionExt;
        delete query.GridRefActionExt;
        return {
            pageInfo: {
                pageSize: 50,
                pageIndex: -1,
            },
            keyword: "",
            pid: "",
            defineItems: [],
            queryCondition: {
                batchQueryByName: JSON.stringify({ names: ajaxData }),
                TreeRefActionExt,
                GridRefActionExt,
                ...extraCondition,
                ...query,
            },
            ...extraAjaxParam,
        }
    }
    //多个querycondition的参照过滤
    sendExcelReferMergeAjax = (reqData, otherParams, forwardRule = '') => {
        return new Promise((resolve, reject) => {
            ajax({
                url: `/nccloud/${forwardRule ? (forwardRule + '/') : ''}platform/pub/mergerequest.do`,
                data: reqData,
                success: res => {
                    let param = { res, ...otherParams }
                    let data = this.excelReferMergeAjaxSuccess(param)
                    resolve(data)
                },
                error: error => {
                    console.error(error);
                    reject(error);
                },
            })
        })
    }
    //一个querycondition的参照过滤
    sendExcelReferNomalAjax = (ajaxdata, url, otherParams) => {
        return new Promise((resolve, reject) => {
            ajax({
                url,
                async: false,
                data: { ...ajaxdata },
                success: res => {
                    let param = { res, ...otherParams }
                    let data = this.excelReferNomalAjaxSuccess(param)
                    resolve(data)
                },
                error: error => {
                    console.error(error);
                    reject(error);
                },
            });
        });
    }

    excelReferMergeAjaxSuccess = param => {
        let { res, queryValue, attrcode, fieldValued, fieldDisplayed, indexs } = param
        let rowKey = this.getTableProps('rowKey');
        if (res.data) {
            let newChangedRows = [];
            let ajaxValues = [];
            let referDatas = [];
            Object.keys(res.data).forEach(index => {
                let resValue = res.data[index]
                let row = queryValue[index]
                if (resValue.rows.length) {
                    let cell = row.values[attrcode].value;
                    let finalVal = "";
                    let finalDis = "";
                    let referData;
                    let flag = false;
                    for (let i = 0; i < resValue.rows.length; i++) {
                        let item = resValue.rows[i]
                        //助记码
                        let rememberCode = item.values && item.values.materialmnecode && item.values.materialmnecode.value;
                        if (cell == item['refcode'] || cell == item['refname'] || cell == rememberCode) {
                            finalVal += item[fieldValued];
                            finalDis += item[fieldDisplayed];
                            referData = item;
                            flag = true;
                            break;
                        }
                    }
                    let initVal = this.getRowOldValue(
                        row[rowKey],
                        attrcode,
                    );
                    let _getValue = function (init) {
                        return isObject(init)
                            ? init.refpk
                            : init;
                    };
                    let isRefpk = _getValue(initVal);
                    if (flag) {
                        let theValue = {
                            display: finalDis,
                            value: finalVal,
                        };
                        newChangedRows.push({
                            rowid: row[rowKey],
                            newvalue: { value: theValue.value || "" },
                            oldvalue: { value: isRefpk || "" },
                        });
                        //把最终值赋给queryvalue
                        row.values[attrcode].value = finalVal;
                        row.values[attrcode].display = finalDis;
                        ajaxValues.push(row);
                        referData && referDatas.push(referData)
                    } else {
                        let initVal = this.getRowOldValue(
                            row[rowKey],
                            attrcode,
                        );
                        let _getValue = function (init) {
                            return isObject(init)
                                ? init.refpk
                                : init;
                        };
                        let isRefpk = _getValue(initVal);
                        newChangedRows.push({
                            rowid: row[rowKey],
                            newvalue: { value: "" },
                            oldvalue: { value: isRefpk || "" },
                        });
                        row.values[attrcode].value = "";
                        row.values[attrcode].display = "";
                        ajaxValues.push(row);
                    }
                } else {
                    let initVal = this.getRowOldValue(
                        row[rowKey],
                        attrcode,
                    );
                    let _getValue = function (init) {
                        return isObject(init)
                            ? init.refpk
                            : init;
                    };
                    let isRefpk = _getValue(initVal);
                    newChangedRows.push({
                        rowid: row[rowKey],
                        newvalue: { value: "" },
                        oldvalue: { value: isRefpk || "" },
                    });
                    row.values[attrcode].value = "";
                    row.values[attrcode].display = "";
                    ajaxValues.push(row);
                }
            })
            console.log(123, 'excelReferMergeAjaxSuccess', res, ajaxValues)
            return {
                indexs: indexs,
                finalValues: ajaxValues,
                changedrows: newChangedRows,
                referDatas: referDatas,
            };
        }
    }

    excelReferNomalAjaxSuccess = param => {
        let { res, queryValue, attrcode, fieldValued, fieldDisplayed, indexs } = param
        let rowKey = this.getTableProps('rowKey');
        if (res.data.rows.length) {

            let newChangedRows = [];
            let ajaxValues = [];
            let referDatas = [];

            queryValue.map(row => {
                let cell = row.values[attrcode].value;
                let finalVal = "";
                let finalDis = "";
                let referData;
                let flag = false;
                for (let i = 0; i < res.data.rows.length; i++) {
                    let item = res.data.rows[i]
                    //助记码
                    let rememberCode = item.values && item.values.materialmnecode && item.values.materialmnecode.value;
                    if (cell == item['refcode'] || cell == item['refname'] || cell == rememberCode) {
                        finalVal += item[fieldValued];
                        finalDis += item[fieldDisplayed];
                        referData = item;
                        flag = true;
                        break;
                    }
                }
                let initVal = this.getRowOldValue(
                    row[rowKey],
                    attrcode,
                );
                let _getValue = function (init) {
                    return isObject(init)
                        ? init.refpk
                        : init;
                };
                let isRefpk = _getValue(initVal);
                if (flag) {
                    let theValue = {
                        display: finalDis,
                        value: finalVal,
                    };
                    newChangedRows.push({
                        rowid: row[rowKey],
                        newvalue: { value: theValue.value || "" },
                        oldvalue: { value: isRefpk || "" },
                    });
                    //把最终值赋给queryvalue
                    row.values[attrcode].value = finalVal;
                    row.values[attrcode].display = finalDis;
                    ajaxValues.push(row);
                    referData && referDatas.push(referData)
                } else {
                    newChangedRows.push({
                        rowid: row[rowKey],
                        newvalue: { value: "" },
                        oldvalue: { value: isRefpk || "" },
                    });
                    row.values[attrcode].value = "";
                    row.values[attrcode].display = "";
                    ajaxValues.push(row);
                    console.error(
                        cell,
                        "参照过滤没有匹配数据",
                    );
                }
            });
            console.log(4561, 'excelReferNomalAjaxSuccess', res, ajaxValues, newChangedRows)
            return {
                indexs,
                finalValues: ajaxValues,
                changedrows: newChangedRows,
                referDatas: referDatas,
            };
        } else {
            let newChangedRows = [];
            let ajaxValues = [];
            let referDatas = [];

            queryValue.map(row => {
                let initVal = this.getRowOldValue(
                    row[rowKey],
                    attrcode,
                );
                let _getValue = function (init) {
                    return isObject(init)
                        ? init.refpk
                        : init;
                };
                let isRefpk = _getValue(initVal);
                newChangedRows.push({
                    rowid: row[rowKey],
                    newvalue: { value: "" },
                    oldvalue: { value: isRefpk || "" },
                });
                row.values[attrcode].value = "";
                row.values[attrcode].display = "";
                ajaxValues.push(row);
            });
            console.error(
                "参照过滤后返回的数组是空数组，没有查询到",
            );
            console.log(4562, 'excelReferNomalAjaxSuccess', res, ajaxValues, newChangedRows)
            return {
                indexs,
                finalValues: ajaxValues,
                changedrows: newChangedRows,
                referDatas: referDatas,
            };
        }
    }

    // excel
    updateAfterBatchChange = (pageScope, param) => {
        let {
            areaCode,
            column,
            indexs,
            queryValue,
            changedrows,
            pasteData,
            queryCondition: customQueryCondition,
            isManyCondition,
            extraAjaxParam = {},
        } = param;
        let rowKey = this.getTableProps('rowKey');
        console.log('updateafterbatchchange param', param)
        // 处理一遍indexs
        if (queryValue && queryValue.length > 0) {
            let allData = this.getViewData('filter');
            let normalData = this.getViewData('normal');
            let isFilter = false;
            allData.forEach(item => {
                if (item.filter) {
                    isFilter = true;
                    return;
                }
            })
            console.log(isFilter, 'isFilter');
            let newIndexs = [];
            let preIndex = normalData.length - 1;
            let overflowIndex = 0;
            queryValue.forEach((item, i) => {
                if (item) {
                    let index = this.getTrueRowIndex({ rowKeyValue: item[rowKey] }, 'normal', 'filter');
                    let newIndex;
                    // 过滤情况下的超出
                    if (isFilter && index === -1) {
                        // 方案一 会导致 行号字段计算错误
                        newIndex = allData.length + overflowIndex;
                        overflowIndex++;
                        // 方案二
                        // newIndex = preIndex + 1;

                    } else {
                        newIndex = index < 0 ? indexs[i] : index;
                    }
                    preIndex = newIndex;
                    newIndexs.push(newIndex);
                }
            });
            console.log('indexs', newIndexs);
            indexs = newIndexs;
        }

        let {
            attrcode,
            itemtype,
            editAfterFlag,
            refcode,
            fieldDisplayed = "refname",
            fieldValued = "refpk",
            queryCondition,
            pk_defdoclist, dataPowerOperationCode, isDataPowerEnable,
            loadSelectedData, //参照需要的属性
        } = column;

        if (itemtype === "refer") {
            if (/.js/.test(refcode)) {
                refcode = refcode.replace(".js", "");
            }
            if (!refcode || !window[refcode]) {
                console.error(`window中没有refcode:${refcode}`);
            }

            let refer = window[refcode].default();
            let url = refer.props.queryGridUrl || refer.props.queryTreeUrl;
            let referProps = refer.props
            let { checkStrictly } = referProps
            if (checkStrictly === false) {
                //摘要走这里
                return new Promise(resolve => {
                    console.error('非严格参照，比如摘要')
                    let newChangedRows = [];
                    queryValue.forEach(row => {
                        let initVal = this.getRowOldValue(
                            row[rowKey],
                            attrcode,
                        );
                        let _getValue = function (init) {
                            return isObject(init)
                                ? init.refpk
                                : init;
                        };
                        //摘要这样的参照 value和display都一样
                        let isRefpk = _getValue(initVal);
                        let theValue = {
                            display: row.values[attrcode].value,
                            value: row.values[attrcode].value,
                        };
                        row.values[attrcode].display = row.values[attrcode].value
                        newChangedRows.push({
                            rowid: row[rowKey],
                            newvalue: { value: theValue.value || "" },
                            oldvalue: { value: isRefpk || "" },
                        });
                    })
                    this._updateAndAddRows({ rows: queryValue }); //给表格赋值
                    resolve({
                        props: pageScope,
                        indexs,
                        key: attrcode,
                        changedrows: newChangedRows,
                    });
                })
            }
            let TreeRefActionExt =
                "nccloud.web.uap.refer.BatchQueryByNameSqlBuilder";
            let GridRefActionExt =
                "nccloud.web.uap.refer.BatchQueryByNameSqlBuilder";

            // 模板上会返回下面几个属性
            let extraCondition = {}
            pk_defdoclist !== undefined && (extraCondition.pk_defdoclist = pk_defdoclist);
            dataPowerOperationCode !== undefined && (extraCondition.DataPowerOperationCode = dataPowerOperationCode);
            isDataPowerEnable !== undefined && (extraCondition.isDataPowerEnable = isDataPowerEnable);
            loadSelectedData && (extraCondition.loadSelectedData = true);

            let referFilter
            let otherParams = { queryValue, attrcode, fieldValued, fieldDisplayed, indexs }
            if (isManyCondition) {
                console.warn('manycondition')
                let mergeAjaxData = []
                queryValue.forEach((row, index) => {
                    let ajaxData = [row.values[attrcode].value]
                    let con = row.queryCondition
                    let datajson = this.getExcelReferAjaxData(ajaxData, refer, con, queryCondition, TreeRefActionExt, GridRefActionExt, extraCondition, extraAjaxParam)
                    let data = {
                        rqUrl: url,
                        rqJson: JSON.stringify(datajson),
                        rqCode: String(index),
                    }
                    mergeAjaxData.push(data)
                })
                let urlPaths = (String(url)).split('/');
                let forwardRule = '';
                urlPaths.forEach((item, i) => {
                    if (item === 'nccloud') {
                        forwardRule = urlPaths[i + 1];
                    }
                });
                referFilter = this.sendExcelReferMergeAjax(mergeAjaxData, otherParams, forwardRule)
            } else {
                let ajaxData = pasteData
                let con = customQueryCondition
                let data = this.getExcelReferAjaxData(ajaxData, refer, con, queryCondition, TreeRefActionExt, GridRefActionExt, extraCondition, extraAjaxParam)
                referFilter = this.sendExcelReferNomalAjax(data, url, otherParams)
            }

            return referFilter.then(res => {
                let changedrows = res.changedrows
                let finalValues = res.finalValues
                let referDatas = res.referDatas
                let indexs = res.indexs
                if (editAfterFlag) {
                    console.log('参照关联项')
                    pageScope.handleRelationItems({
                        type: "table",
                        areaCode: areaCode,
                        key: attrcode,
                        changedrows,
                        rows: finalValues,
                    })
                } else {
                    console.log('无关联项')
                    this._updateAndAddRows({ rows: finalValues }); //给表格赋值
                }
                return {
                    props: pageScope,
                    key: attrcode,
                    changedrows,
                    referDatas,
                    indexs,
                };
            });
        } else {
            //除了參照以外类型的编辑关联项的处理
            if (editAfterFlag) {
                return new Promise(resolve => {
                    pageScope.handleRelationItems({
                        type: "table",
                        areaCode: areaCode,
                        key: attrcode,
                        changedrows,
                        isMul: true,
                        rows: queryValue,
                    });
                    resolve({
                        props: pageScope,
                        changedrows,
                        key: attrcode,
                        indexs,
                    });
                });
            } else {
                return new Promise(resolve => {
                    console.log(123);
                    console.log(queryValue);
                    this._updateAndAddRows({ rows: queryValue }); //给表格赋值
                    resolve({
                        props: pageScope,
                        changedrows,
                        key: attrcode,
                        indexs,
                    });
                });
            }
        }
    };

    anoterTable = () => {
        let key = this.getStore(["key"]);
        this.setStore('key', key + 1)
    }
    /**
     * 加上bind的意思是可以独立调用
     * @description: 钩子
     * @param {type}
     * @return:
     */
    getTableHooks = () => {
        // console.log('当前的对象', this);

        // 这样处理的一下 才能报错  方法内部的this 永远指向 实例  否则会指向下面的 {} 对象
        this.getMeta = this.getMeta.bind(this);
        this.setMeta = this.setMeta.bind(this);

        // return this;
        return {
            // 这个是给公共层提供 组件在使用baseComponent 是可以正常运行
            setRef: this.setRef,
            getRef: this.getRef,
            removeRef: this.removeRef,

            // 刷新 更新
            refresh: this.refresh,
            //更新table的key（给业务组刷新table使用）
            anoterTable: this.anoterTable,
            // 按路径索引 给内部使用
            getStore: this.getStore.bind(this),
            setStore: this.setStore.bind(this),

            /*-------------------上面的部分------------------*/

            //模板
            getMeta: this.getMeta.bind(this),
            setMeta: this.setMeta.bind(this),
            getViewMeta: this.getViewMeta.bind(this),
            getArrayMeta: this.getArrayMeta.bind(this),
            getColumn: this.getColumn.bind(this),
            setColumn: this.setColumn.bind(this),
            // 也可以考虑加两个批操作

            // 缓存
            getCache: this.getCache.bind(this),
            setCache: this.setCache.bind(this),

            // 行
            getTrueRowIndex: this.getTrueRowIndex.bind(this),
            getRows: this.getRows.bind(this),
            setRows: this.setRows.bind(this),
            setRowProps: this.setRowProps.bind(this),
            getRowProps: this.getRowProps.bind(this),
            setRowsProps: this.setRowsProps.bind(this),
            setRowsCellPropsByKey: this.setRowsCellPropsByKey.bind(this),
            setRowsCellPropsByIndex: this.setRowsCellPropsByIndex.bind(this),
            insertRowsAfterIndex: this.insertRowsAfterIndex.bind(this),
            moveRow: this.moveRow.bind(this),

            // 这些方法  可以考虑去除了 用getRows + filter 或者 getViewData + filter 实现

            removeEmptyRows: this.removeEmptyRows.bind(this),
            hasSelected: this.hasSelected.bind(this),
            setSelectedIndex: this.setSelectedIndex.bind(this),

            // 单元格
            getCellProps: this.getCellProps.bind(this),
            setCellProps: this.setCellProps.bind(this),
            setCellValue: this.setCellValue.bind(this),
            getCellValue: this.getCellValue.bind(this),
            setCellsProps: this.setCellsProps.bind(this),

            // 不可取两个批操作 getCells  setCells  参数为二维数组

            // 表格
            formatData: this.formatData.bind(this),
            getTableProps: this.getTableProps.bind(this),
            setTableProps: this.setTableProps.bind(this),
            setData: this.setData.bind(this),
            getData: this.getData.bind(this),
            getViewData: this.getViewData.bind(this),

            // 业务
            setRowPosition: this.setRowPosition.bind(this),
            saveRowOldValue: this.saveRowOldValue.bind(this),
            getRowOldValue: this.getRowOldValue.bind(this),
            deleteRowOldValue: this.deleteRowOldValue.bind(this),
            saveRowsOldValue: this.saveRowsOldValue.bind(this),
            clearRowsOldValue: this._clearRowsOldValue.bind(this),
            getAllRows: this.getAllRows.bind(this),
            getVisibleRows: this.getVisibleRows.bind(this),
            getChangedRows: this.getChangedRows.bind(this),
            addTableRow: this.addTableRow.bind(this),
            deleteTableRows: this.deleteTableRows.bind(this),
            setValByKeyAndIndex: this.setValByKeyAndIndex.bind(this),
            setValByKeyAndRowId: this.setValByKeyAndRowId.bind(this),
            updateTableData: this.updateTableData.bind(this),
            updateTableDataByIndexs: this.updateTableDataByIndexs.bind(this),
            updateTableDataByRowId: this.updateTableDataByRowId.bind(this),
            setTableData: this.setTableData.bind(this),
            setTableStatus: this.setTableStatus.bind(this),
            getValByKeyAndIndex: this.getValByKeyAndIndex.bind(this),
            getValByKeyAndRowKey: this.getValByKeyAndRowKey.bind(this),
            pasteRow: this.pasteRow.bind(this),

            getSelectedRows: this.getSelectedRows.bind(this),
            setRowsSelected: this.setRowsSelected.bind(this),
            setRowsSelectedByIndexs: this.setRowsSelectedByIndexs.bind(this),
            setRowsSelectedByRowKey: this.setRowsSelectedByRowKey.bind(this),
            setAllRowsSelected: this.setAllRowsSelected.bind(this),
            reverseRowsSelected: this.reverseRowsSelected.bind(this),

            getAllRowsRemoveKeys: this.getAllRowsRemoveKeys.bind(this),
            getFilterRows: this.getFilterRows.bind(this),
            setFiltrateTableData: this.setFiltrateTableData.bind(this),
            batchChangeTableData: this.batchChangeTableData.bind(this),
            insertDataByIndexs: this.insertDataByIndexs.bind(this),
            setRowStatusByIndexs: this.setRowStatusByIndexs.bind(this),
            setRowsCellValue: this.setRowsCellValue.bind(this),
            updateRows: this.updateRows.bind(this),
            updateRowsProps: this.updateRowsProps.bind(this),
            resetTableCaches: this.resetTableCaches.bind(this),

            // 高阶组件
            getCheckInfo: this.getCheckInfo.bind(this),
            setCheckInfo: this.setCheckInfo.bind(this),
            // 聚焦
            focusItem: this.focusItem.bind(this),

            // 工具
            generateRowKey: this.generateRowKey,
            //liuming 跨页选择
            setCrossPage: this.setCrossPage,
            getConfig: this.getConfig,
            setConfig: this.setConfig,
            _setSelect: this._setSelect,
            _updateAndAddRows: this._updateAndAddRows,
            //liuming excel
            updateAfterBatchChange: this.updateAfterBatchChange,
        };
    };
}
